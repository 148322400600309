import React from "react";

export default function SunAzimuthIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99984 12.3334C10.3931 12.3334 12.3332 10.3933 12.3332 8.00008C12.3332 5.60685 10.3931 3.66675 7.99984 3.66675C5.6066 3.66675 3.6665 5.60685 3.6665 8.00008C3.6665 10.3933 5.6066 12.3334 7.99984 12.3334Z"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.7602 12.7599L12.6735 12.6733M12.6735 3.32659L12.7602 3.23992L12.6735 3.32659ZM3.24016 12.7599L3.32683 12.6733L3.24016 12.7599ZM8.00016 1.38659V1.33325V1.38659ZM8.00016 14.6666V14.6133V14.6666ZM1.38683 7.99992H1.3335H1.38683ZM14.6668 7.99992H14.6135H14.6668ZM3.32683 3.32659L3.24016 3.23992L3.32683 3.32659Z"
        stroke="#959595"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
