import React from "react";

export default function CSVIcon() {
  return (
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36719 30.5588V3.44116C5.36719 2.61273 6.03876 1.94116 6.86719 1.94116H19.6828C20.116 1.94116 20.528 2.12842 20.8129 2.45476L26.3254 8.77016C26.564 9.04342 26.6954 9.39384 26.6954 9.75656V30.5588C26.6954 31.3872 26.0238 32.0588 25.1954 32.0588H6.86719C6.03876 32.0588 5.36719 31.3872 5.36719 30.5588Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M19.8691 7.97058V1.94116L26.4574 9.47057H21.3691C20.5407 9.47057 19.8691 8.799 19.8691 7.97058Z" fill="white" />
      <rect x="1.98633" y="12.4516" width="28.0279" height="12.9076" rx="1.5" fill="white" />
      <path d="M21.6592 15.0443L23.3862 20.7002H23.4503L25.1772 15.0443H27.2661L24.6606 22.7665H22.1758L19.5703 15.0443H21.6592Z" fill="black" />
      <path
        d="M17.0703 17.3594C17.0452 17.0829 16.9333 16.868 16.7348 16.7147C16.5387 16.5588 16.2584 16.4809 15.8939 16.4809C15.6526 16.4809 15.4515 16.5123 15.2906 16.5752C15.1297 16.638 15.0091 16.7247 14.9286 16.8353C14.8482 16.9434 14.8067 17.0679 14.8042 17.2086C14.7992 17.3243 14.8218 17.4261 14.8721 17.514C14.9249 17.602 15.0003 17.6799 15.0983 17.7478C15.1989 17.8132 15.3195 17.871 15.4603 17.9213C15.6011 17.9715 15.7594 18.0155 15.9354 18.0532L16.599 18.2041C16.9811 18.287 17.3179 18.3976 17.6095 18.5359C17.9036 18.6741 18.15 18.8388 18.3486 19.0298C18.5497 19.2209 18.7018 19.4408 18.8048 19.6897C18.9079 19.9385 18.9607 20.2176 18.9632 20.5267C18.9607 21.0144 18.8375 21.4329 18.5937 21.7824C18.3498 22.1318 17.9992 22.3995 17.5417 22.5855C17.0867 22.7715 16.5374 22.8645 15.8939 22.8645C15.2479 22.8645 14.6848 22.7677 14.2047 22.5742C13.7246 22.3806 13.3513 22.0865 13.0848 21.6919C12.8184 21.2972 12.6814 20.7982 12.6738 20.1949H14.4611C14.4762 20.4438 14.5428 20.6512 14.6609 20.8171C14.7791 20.983 14.9412 21.1087 15.1473 21.1941C15.356 21.2796 15.5973 21.3223 15.8713 21.3223C16.1227 21.3223 16.3363 21.2884 16.5123 21.2205C16.6908 21.1527 16.8278 21.0584 16.9233 20.9377C17.0188 20.8171 17.0678 20.6788 17.0703 20.523C17.0678 20.3772 17.0226 20.2528 16.9346 20.1497C16.8466 20.0441 16.7109 19.9536 16.5274 19.8782C16.3464 19.8003 16.1151 19.7286 15.8336 19.6633L15.0267 19.4748C14.358 19.3214 13.8314 19.0738 13.4468 18.7319C13.0622 18.3876 12.8712 17.9225 12.8737 17.3368C12.8712 16.8592 12.9994 16.4407 13.2583 16.0812C13.5172 15.7217 13.8754 15.4415 14.3329 15.2404C14.7904 15.0393 15.312 14.9387 15.8977 14.9387C16.496 14.9387 17.015 15.0405 17.4549 15.2441C17.8974 15.4452 18.2405 15.728 18.4843 16.0925C18.7282 16.457 18.8526 16.8793 18.8576 17.3594H17.0703Z"
        fill="black"
      />
      <path
        d="M11.8278 17.8421H9.94255C9.91741 17.6485 9.86588 17.4738 9.78795 17.318C9.71003 17.1621 9.60697 17.0289 9.47877 16.9183C9.35056 16.8077 9.19848 16.7235 9.02252 16.6657C8.84908 16.6053 8.65677 16.5752 8.44562 16.5752C8.07107 16.5752 7.74806 16.6669 7.47658 16.8504C7.20761 17.0339 7.00023 17.2991 6.85443 17.646C6.71115 17.9929 6.6395 18.4127 6.6395 18.9054C6.6395 19.4182 6.7124 19.848 6.8582 20.1949C7.00651 20.5393 7.21389 20.7995 7.48035 20.9754C7.74932 21.1489 8.0673 21.2356 8.43431 21.2356C8.64044 21.2356 8.82771 21.2092 8.99613 21.1564C9.16706 21.1037 9.31663 21.027 9.44483 20.9264C9.57554 20.8234 9.68238 20.6989 9.76533 20.5531C9.8508 20.4048 9.90987 20.2377 9.94255 20.0517L11.8278 20.063C11.7952 20.4048 11.6959 20.7417 11.53 21.0735C11.3666 21.4053 11.1416 21.7082 10.855 21.9822C10.5685 22.2537 10.2191 22.4699 9.80681 22.6307C9.39707 22.7916 8.927 22.8721 8.3966 22.8721C7.69779 22.8721 7.07187 22.7187 6.51885 22.412C5.96834 22.1029 5.53346 21.6529 5.21422 21.0622C4.89497 20.4714 4.73535 19.7525 4.73535 18.9054C4.73535 18.0557 4.89749 17.3356 5.22176 16.7448C5.54603 16.1541 5.98468 15.7054 6.5377 15.3987C7.09072 15.0921 7.71035 14.9387 8.3966 14.9387C8.86416 14.9387 9.29652 15.0041 9.69369 15.1348C10.0909 15.263 10.4403 15.4515 10.7419 15.7004C11.0436 15.9467 11.2887 16.2496 11.4772 16.6091C11.6657 16.9686 11.7826 17.3796 11.8278 17.8421Z"
        fill="black"
      />
    </svg>
  );
}
