import React from "react";

export default function CartMenuIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6088 3.79401H4.60352L6.04301 9.86554H14.1161L15.5657 5.08414C15.7605 4.44193 15.2798 3.79401 14.6088 3.79401Z"
        fill="white"
      />
      <path
        d="M0.556152 2H3.29572C3.75522 2 4.15559 2.31314 4.26628 2.75911L6.01606 9.80906"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M4.98682 3.72601H14.4366C15.1005 3.72601 15.5801 4.36106 15.3984 4.99962L14.1907 9.24534C14.0684 9.67519 13.6757 9.97173 13.2288 9.97173H6.10625C5.57605 9.97173 5.14624 10.4015 5.14624 10.9317V10.9317C5.14624 11.4619 5.57605 11.8917 6.10625 11.8917H13.984"
        stroke="white"
        strokeLinecap="round"
      />
      <mask id="path-4-inside-1_15_12450" fill="currentColor">
        <ellipse cx="6.88782" cy="13.5998" rx="1.4093" ry="1.40016" />
      </mask>
      <path
        d="M6.79712 13.5998C6.79712 13.5355 6.84694 13.5 6.88782 13.5V16.5C8.48536 16.5 9.79712 15.2107 9.79712 13.5998H6.79712ZM6.88782 13.5C6.92869 13.5 6.97852 13.5355 6.97852 13.5998H3.97852C3.97852 15.2107 5.29027 16.5 6.88782 16.5V13.5ZM6.97852 13.5998C6.97852 13.6642 6.92869 13.6997 6.88782 13.6997V10.6997C5.29027 10.6997 3.97852 11.9889 3.97852 13.5998H6.97852ZM6.88782 13.6997C6.84694 13.6997 6.79712 13.6642 6.79712 13.5998H9.79712C9.79712 11.9889 8.48536 10.6997 6.88782 10.6997V13.6997Z"
        fill="currentColor"
        mask="url(#path-4-inside-1_15_12450)"
      />
      <mask id="path-6-inside-2_15_12450" fill="currentColor">
        <ellipse cx="13.2941" cy="13.5998" rx="1.4093" ry="1.40016" />
      </mask>
      <path
        d="M13.2034 13.5998C13.2034 13.5355 13.2532 13.5 13.2941 13.5V16.5C14.8916 16.5 16.2034 15.2107 16.2034 13.5998H13.2034ZM13.2941 13.5C13.3349 13.5 13.3848 13.5355 13.3848 13.5998H10.3848C10.3848 15.2107 11.6965 16.5 13.2941 16.5V13.5ZM13.3848 13.5998C13.3848 13.6642 13.3349 13.6997 13.2941 13.6997V10.6997C11.6965 10.6997 10.3848 11.9889 10.3848 13.5998H13.3848ZM13.2941 13.6997C13.2532 13.6997 13.2034 13.6642 13.2034 13.5998H16.2034C16.2034 11.9889 14.8916 10.6997 13.2941 10.6997V13.6997Z"
        fill="currentColor"
        mask="url(#path-6-inside-2_15_12450)"
      />
    </svg>
  );
}
