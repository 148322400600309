import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getMyProjects } from "../../services/project/projectServices";

interface IProjectState {}

const initialState: IProjectState = {};

export const getMyProjectsAsync = createAsyncThunk(
  "project/getMyProjects",
  async (payload) => {
    try {
      const response = await getMyProjects(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
});

export const {} = projectSlice.actions;

export default projectSlice.reducer;
