import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { IResponse } from "../../../types/common";
import { IBasemap } from "../interfaces/IBasemap.interface";
import basemapService from "../basemapService";

const useGetBasemap = (enabled = true) => {
  const [data, setData] = useState<IResponse<IBasemap>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return basemapService.GetbaseMap();
  }, []);

  const transformResponse = useCallback(
    (response: AxiosResponse<IResponse<IBasemap>>) => {
      if (response) {
        setData(response.data);
      }
    },
    []
  );

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetBasemap;
