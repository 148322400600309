import { Collapse, CollapseProps, Divider, Form } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CommonIcons from "../../assets/icons";
import MapConfirmOrder from "../../components/BookingImage/MapConfirmOrder";
import Step2 from "../../components/BookingImage/Step2";
import Step3 from "../../components/BookingImage/Step3";
import Step4 from "../../components/BookingImage/Step4";
import CommonComponents from "../../components/Commons";
import { EProviderName } from "../../enums";
import { capitalizeFirstLetter } from "../../helpers/common";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { resetAllDrawer } from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import { displayNotification } from "../../store/reducers/notificationSlice";
import {
  approveArchiveOrderAsync,
  getOrderByIdAdminAsync,
} from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import { BuyerInfomationTaskingOrderAdmin } from "./ManageTaskingOrder/[UpdateTaskingOrderAdmin]";
import { RenderHeader } from "./ManageTaskingOrder/ultis";

interface IProps {
  hidden?: boolean;
  onClose?: () => void;
}
const UpdateArchiveOrderPage = (props: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const dataItem = location?.state?.item;

  const [data, setData] = useState<any>({});
  const [itemsImages, setItemsImages] = useState<any>([]);
  const [items, setItems] = useState<any>([]);

  let publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const getData = async () => {
    // @ts-ignore
    let res = await dispatch(getOrderByIdAdminAsync(id)).unwrap();

    axios
      .get(
        `${publicConfig?.REACT_APP_API_KARATOS}/oa/admin/identities/${res?.data?.data?.userId}`,
        {
          withCredentials: !publicConfig?.REACT_APP_SKIP_CREDENTIALS ?? true,
        }
      )
      .then((data) => {
        setItems([
          {
            key: "1",
            label: "Buyer's information",
            children: <BuyerInfomationTaskingOrderAdmin whoami={data.data} />,
          },
        ]);
      });

    setData(res?.data?.data);
  };

  //! effect
  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  useEffect(() => {
    dispatch(changeLayoutName("/update-order-tasking"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-order")}
            >
              Existing Image Orders
            </span>
          ),
        },
        { title: `Approve ${dataItem?.title}` },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  useEffect(() => {
    let a: any = [];

    if (data?.orderDetails?.length > 0) {
      data?.orderDetails?.map((item: any, index: any) => {
        a = a.concat({
          key: index,
          label: (
            <span>
              {capitalizeFirstLetter(item?.provider?.name)} (
              {item?.images?.length})
            </span>
          ),
          children: (
            <div className="w-full flex gap-3">
              <div className="w-full">
                {item?.provider?.name === "planet" ? (
                  <Step3
                    form={form}
                    dataImages={item?.images}
                    initialValues={item?.productOptions}
                  />
                ) : item?.provider?.name === "oneatlas" ? (
                  <Step4
                    form={form}
                    dataImages={item?.images}
                    total={item?.images?.length}
                    dataProvider={
                      providerConfigStore?.content_page?.filter(
                        (item: any) =>
                          item?.name.toLowerCase() === EProviderName.oneatlas
                      )?.[0]?.configs
                    }
                    initialValues={item?.productOptions?.[0]?.options}
                  />
                ) : (
                  <Step2
                    form={form}
                    items={item?.images}
                    dataProvider={
                      providerConfigStore?.content_page?.filter(
                        (item: any) =>
                          item?.name.toLowerCase() === EProviderName.capella
                      )?.[0]?.configs
                    }
                    initialValues={item?.productOptions}
                  />
                )}
              </div>
            </div>
          ),
        });
      });
    }

    setItemsImages(a);
  }, [data]);

  const renderInfoOrder = () => {
    return (
      <>
        <p style={{ fontSize: "14px", color: "white", marginTop: "8px" }}>
          <span className="text-des">Order name</span>: {data?.name}
        </p>

        <p style={{ fontSize: "14px", color: "white" }}>
          <span className="text-des">Priority</span>: {data?.priority?.name}
        </p>

        {data?.description && (
          <p style={{ fontSize: "14px", color: "white" }}>
            <span className="text-des">Description</span>: {data?.description}
          </p>
        )}

        {data?.project && (
          <p style={{ fontSize: "14px", color: "white" }}>
            <span className="text-des">Project</span>:{" "}
            {data?.project?.name ?? data?.project?.id}
          </p>
        )}
      </>
    );
  };

  const itemsOrderInfo: CollapseProps["items"] = [
    {
      key: "1",
      label: "Order's information",
      children: renderInfoOrder(),
    },
  ];

  //! render
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={async (values: any) => {
        const { dataPlanet, ...restOfFormData } = values;

        let pay = {
          ...restOfFormData,
          capella:
            values?.capella && Object.keys(values?.capella)?.length > 0
              ? Object.entries(values?.capella).map(
                  ([image_id, details]: any) => ({
                    collection_id: details?.collection_id,
                    image_id: image_id,
                  })
                )
              : [],

          planet: {
            products:
              values?.dataPlanet?.products &&
              Object.keys(values?.dataPlanet?.products)?.length > 0
                ? Object.entries(values?.dataPlanet?.products).map(
                    ([key, details]: any) => ({
                      product_bundle: details?.productBundle,
                      item_type: key,
                      image_ids: details?.imageId ?? [],
                    })
                  )
                : [],
          },
          oneatlas: {
            options: values?.oneatlas
              ? Object?.keys(values?.oneatlas)
                  ?.filter((key) => key !== "product_type_id")
                  ?.map((key) => ({
                    key: key,
                    value: values?.oneatlas[key],
                    label: key,
                  }))
              : {},
            product_type_id: values?.oneatlas?.product_type_id,
            image_ids:
              data?.orderDetails
                .filter((item: any) => item.provider.name === "oneatlas")
                .flatMap((item: any) =>
                  item.images.map((item: any) => item.image_id)
                ) || [],
          },
        };

        // @ts-ignore
        let res = await dispatch(
          // @ts-ignore
          approveArchiveOrderAsync({ ...pay, id: id })
        ).unwrap();

        if (res?.status === 200 || res?.status === 201) {
          dispatch(
            displayNotification({
              type: "success",
              content: "Approve successfully",
            })
          );
          navigate("/order/management-order");
        } else {
          dispatch(
            displayNotification({
              type: "error",
              content: "Approve fail",
            })
          );
        }
      }}
    >
      <ContentLayoutManagement
        header={
          <div className="flex gap-2">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7"
              onClick={() => navigate("/order/management-order")}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              className="!text-xs !h-[27px]"
              htmlType="submit"
            >
              Approve
            </CommonComponents.ButtonUI>
          </div>
        }
      >
        <div className="text-white overflow-hidden div-page-default">
          <div className="flex !flex-row gap-3 h-full">
            <div className="w-1/2 div-info-confirm-order h-full overflow-auto text-white">
              {RenderHeader({ data: data })}
              <div className="div-collapse-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={items}
                  onChange={() => {}}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
                <Divider style={{ background: "#353B4F", margin: 0 }} />
              </div>

              <div className="div-collapse-cus">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsOrderInfo}
                  onChange={() => {}}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
                <Divider style={{ background: "#353B4F", margin: 0 }} />
              </div>

              <p className="my-3" style={{ fontSize: "16px", fontWeight: 600 }}>
                Images in order
              </p>

              <div className="card-archive-order">
                <Collapse
                  bordered={false}
                  expandIconPosition="end"
                  items={itemsImages}
                  onChange={() => {}}
                  defaultActiveKey={["0", "1", "2", "3"]}
                  expandIcon={({ isActive }) => (
                    <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                      <CommonIcons.ChevronUpIcon />
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="w-1/2 ">
              <MapConfirmOrder />
            </div>
          </div>
        </div>
      </ContentLayoutManagement>
    </Form>
  );
};

export default UpdateArchiveOrderPage;
