import ContentLayout from "../../layouts/ContentLayout";
import CardManagementComp from "../Commons/CardManagement";
import FilePdfSvg from "../../assets/svg/ManageData/file-pdf.svg";
import InfoSvg from "../../assets/svg/ManageData/info.svg";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";

const HistoryUploadDataComp = () => {
  const arrData = [
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: <span>1.2 MB | admin | 24/12/2024 17:24 </span>,
      status: "Error",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
      status: "Error",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
      status: "Success",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
      status: "Error",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",

      status: "Success",
    },
  ];
  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        <p style={{ color: "white", marginBottom: "16px" }}>
          12/08/2021 (Tổng số tập tin: 3)
        </p>
        {arrData?.map((item: any, ind: number) => (
          <CardManagementComp
            key={ind}
            data={item}
            actions={[<img alt="example" src={InfoSvg} />]}
            additionalInfo={
              <span
                style={{
                  color: item?.status === "Error" ? "red" : "green",
                }}
              >
                {item?.status}
              </span>
            }
          />
        ))}

        <p style={{ color: "white", margin: "16px 0" }}>
          12/08/2021 (Tổng số tập tin: 2)
        </p>

        {arrData?.map((item: any, ind: number) => (
          <CardManagementComp
            key={ind}
            data={item}
            actions={[<img alt="example" src={InfoSvg} />]}
          />
        ))}
      </div>
    </ContentLayoutManagement>
  );
};

export default HistoryUploadDataComp;
