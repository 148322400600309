import area from "@turf/area";
import bbox from "@turf/bbox";
import length from "@turf/length";
import { Select } from "antd";
import { useState } from "react";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeActiveFeature,
  clearFeatures,
  removeFeature,
  setSelected,
  setSourceData,
} from "../../store/reducers/mapTaskingSlice";
import themeColor from "../../themes/colorTheme";
import CommonComponents from "../Commons";

export const RenderInfoPolygon = (selectedPolygon: any) => {
  const [format, setFormat] = useState("lat, lng degrees");

  if (selectedPolygon?.id) {
    const extent = bbox(selectedPolygon);
    const outArea = area(selectedPolygon);

    const perimeter = length(selectedPolygon, { units: "kilometers" });

    let output;
    if (outArea > 10000) {
      output = Math.round((outArea / 1000000) * 100) / 100 + " " + "km2";
    } else {
      output = Math.round(outArea * 100) / 100 + " " + "m2";
    }

    const handleFormatChange = (value: string) => {
      setFormat(value);
    };

    const convertCoordinates = (item: any) => {
      let val;
      if (format === "lat, lng degrees") {
        val = `${item?.[0]}, ${item?.[1]}`; // Định dạng thập phân
      } else {
        const latitudeDMS = decimalToDMS(item?.[1], "N", "S");
        const longitudeDMS = decimalToDMS(item?.[0], "E", "W");
        val = `${longitudeDMS}, ${latitudeDMS}`; // Định dạng độ phút giây
      }
      return val;
    };

    return (
      <div className="text-white">
        <div className="flex gap-2">
          <p>Format</p>
          <Select
            className="mb-2 [&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white"
            defaultValue="lat, lng degrees"
            onChange={handleFormatChange}
            style={{ width: "100%" }}
            options={[
              { value: "lat, lng degrees", label: "lat, lng degrees" },
              { value: "lat, lng minutes", label: "lat, lng minutes" },
            ]}
          />
        </div>
        {selectedPolygon?.geometry?.coordinates?.[0]?.map(
          (item: any, index: any) => {
            if (
              index <
              selectedPolygon?.geometry?.coordinates?.[0]?.length - 1
            ) {
              const val = convertCoordinates(item);
              return (
                <div key={index}>
                  <b className="mb-1 text-white">Coordinate {index + 1}</b>
                  <p className="mb-2">{val}</p>
                  {/* <CommonComponents.InputUI
                    className="mb-2"
                    value={val}
                  ></CommonComponents.InputUI> */}
                </div>
              );
            }
          }
        )}
        <b>Area</b>
        <p>{output}</p>
        <b className="mt-2">Perimeter</b>
        <p>{Math.round(perimeter * 100) / 100} km</p> {/* Hiển thị chu vi */}
      </div>
    );
  }
};

// Hàm chuyển từ thập phân sang DMS
export function decimalToDMS(
  decimal?: any,
  directionPositive?: any,
  directionNegative?: any
) {
  const degrees = Math.floor(Math.abs(decimal));
  const minutesDecimal = (Math.abs(decimal) - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);

  const direction = decimal >= 0 ? directionPositive : directionNegative;
  return `${degrees}° ${minutes}′ ${seconds}″ ${direction}`;
}

export const deleteSeclectedPolygon = (props: any) => {
  props?.dispatch(setLoadingGlobal(true));
  props?.draw?.delete(props?.activeFeature?.id);

  props?.dispatch(removeFeature(props?.activeFeature));
  props?.dispatch(changeActiveFeature({}));

  props?.dispatch(changeLeftDrawerType(""));

  props?.maplibreGL
    .getSource(props?.sourceId + "_hover")
    .setData({ type: "FeatureCollection", features: [] });

  props?.dispatch(
    setSourceData({
      type: "FeatureCollection",
      features: [],
    })
  );

  props?.dispatch(setSelected([]));
  props?.dispatch(setLoadingGlobal(false));
};
export const removeAllFeatures = (props: any) => {
  if (props?.draw) {
    props?.dispatch(setLoadingGlobal(true));
    props?.draw.deleteAll();

    // @ts-ignore
    props?.maplibreGL.getSource(props?.sourceId + "_hover").setData({
      type: "FeatureCollection",
      features: [],
    });

    // @ts-ignore
    props?.maplibreGL.removeLayer(props?.sourceId + "_hover_fill");
    // @ts-ignore
    props?.maplibreGL.removeLayer(props?.sourceId + "_hover_outline");

    props?.dispatch(clearFeatures());

    props?.dispatch(changeLeftDrawerType(""));
    props?.dispatch(changeShowBottomDrawer(false));
    props?.dispatch(changeBottomDrawerType(""));
  }

  props?.dispatch(setLoadingGlobal(false));
};

export const PolygonItem = ({
  img,
  selected = false,
  label,
  icon,
  onClick,
}: any) => {
  return (
    <div
      className="!w-[152px] !h-[126px] flex flex-col gap-1 p-1 cursor-pointer hover:outline hover:outline-2 hover:outline-common-primary100 hover:-outline-offset-2 !rounded-[3px]"
      style={{
        backgroundColor: selected ? themeColor.primary100 : "transparent",
      }}
      onClick={() => onClick()}
    >
      <div
        className="!w-[144px] !h-[94px] div-one-center"
        style={{
          background: `url(${require("../../assets/test/Polygon1.png")})`,
          backgroundSize: "cover",
        }}
      >
        <img src={img} alt="" />
      </div>

      <div className="flex items-center gap-1 text-sm text-common-white font-normal">
        <span>{icon}</span>
        <span className="truncate">{label}</span>
      </div>
    </div>
  );
};

export function centroid(poly: { coordinates: Array<any> }) {
  var c = [0, 0];
  var ring = poly?.coordinates?.[0];
  for (let i = 0; i < ring?.length - 1; i++) {
    c[0] +=
      (ring[i][0] + ring[i + 1][0]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
    c[1] +=
      (ring[i][1] + ring[i + 1][1]) *
      (ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1]);
  }
  var a = areaFunc(poly);
  c[0] /= a * 6;
  c[1] /= a * 6;
  return c;
}

export function areaFunc(poly: { coordinates: Array<any> }) {
  var s = 0.0;
  var ring = poly?.coordinates?.[0];
  for (let i = 0; i < ring?.length - 1; i++) {
    s += ring[i][0] * ring[i + 1][1] - ring[i + 1][0] * ring[i][1];
  }
  return 0.5 * s;
}

// init layer of items collection
export function initItemsCollectionLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId, {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_fill_hover",
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "#fed766", // if selected true,
        "#A300EF33",
      ],
      "fill-opacity": 0.4,
    },
  });

  maplibre.addLayer({
    id: sourceId + "_fill_click",
    type: "fill",
    source: sourceId,
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        "#00E676",
        "#A300EF33", // if selected true,
      ],
      "fill-opacity": 0.9,
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_outline",
    type: "line",
    source: sourceId,
    layout: {},
    paint: {
      "line-color": "#CC00FF",
      "line-width": 2,
    },
  });
}

// init hover layer of items collection
export function initHoverLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId + "_hover", {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_hover_fill",
    type: "fill",
    source: sourceId + "_hover",
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        "#fed766", // if selected true,
        "#00E676",
      ],
      "fill-opacity": 0.4,
    },
  });

  maplibre.addLayer({
    id: sourceId + "_hover_fill",
    type: "fill",
    source: sourceId + "_click",
    layout: {},
    paint: {
      "fill-color": [
        "case",
        ["boolean", ["feature-state", "clicked"], false],
        "#fed766", // if selected true,
        "#00E676",
      ],
      "fill-opacity": 0.4,
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_hover_outline",
    type: "line",
    source: sourceId + "_hover",
    layout: {},
    paint: {
      "line-color": "#fed766",
      "line-width": 2,
    },
  });
}

// init cart layer of items collection
export function initCartLayer(
  maplibre: maplibregl.Map,
  sourceId: any,
  sourceData: any
) {
  maplibre.addSource(sourceId + "_cart", {
    type: "geojson",
    // @ts-ignore
    data: sourceData,
  });
  maplibre.addLayer({
    id: sourceId + "_cart_fill",
    type: "fill",
    source: sourceId + "_cart",
    layout: {},
    paint: {
      "fill-color": "#00E676 ",
      "fill-opacity": [
        "case",
        ["boolean", ["feature-state", "hover"], false],
        0.5,
        0.25,
      ],
    },
  });
  // Add a black outline around the polygon.
  maplibre.addLayer({
    id: sourceId + "_cart_outline",
    type: "line",
    source: sourceId + "_cart",
    layout: {},
    paint: {
      "line-color": "#00E676 ",
      "line-width": 2,
    },
  });
}

export function initTextlayer(maplibre: maplibregl.Map, sourceId: any) {
  maplibre.addSource("mapbox-gl-draw-text-source", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [],
    },
  });
  // Add a symbol layer
  maplibre.addLayer({
    id: "mapbox-gl-draw-text",
    type: "symbol",
    source: "mapbox-gl-draw-text-source",
    layout: {
      // get the title name from the source's "title" property
      "text-field": ["get", "displayText"],
      "text-offset": [0, -2.5],
      "text-anchor": "top",
      "text-size": 12,
      "text-justify": "center",
    },
    paint: {
      "text-color": "#f03b20",
      "text-halo-color": "white",
      "text-halo-width": 1.5,
      "text-halo-blur": 1,
    },
  });
}

export function updateFeaturesText(map: any, features: Array<any>) {
  if (
    map &&
    Object.keys(map).length &&
    map.getSource("mapbox-gl-draw-text-source")
  ) {
    let geojson = {
      type: "FeatureCollection",
      features: [],
    };
    if (features && features?.length) {
      let index = 0;
      features.forEach((f) => {
        if (f) {
          index++;

          const extent = bbox(f);
          const outArea = area(f);
          let output;
          if (outArea > 10000) {
            output = Math.round((outArea / 1000000) * 100) / 100 + " " + "km2";
          } else {
            output = Math.round(outArea * 100) / 100 + " " + "m2";
          }

          if (extent && extent.length == 4) {
            //@ts-ignore
            geojson["features"].push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [extent[0], extent[3]],
              },
              properties: {
                displayText: f?.name
                  ? f?.name + " ~" + output
                  : "Polygon " + index + " ~" + output,
              },
            });
          }
        }
      });
    }

    map.getSource("mapbox-gl-draw-text-source").setData(geojson);
  }
}

export function resetDrawMode(map: any, draw: any) {
  map.getCanvas().style.cursor = "";
  if (draw) draw?.changeMode("simple_select");
}

export function changeColor(
  features: Array<any>,
  color: string,
  colorOutLine: string,
  draw: any
) {
  if (features && features?.length > 0) {
    features?.forEach((f) => {
      if (draw && f) {
        // return
        draw?.setFeatureProperty(f?.id, "portColor", color);

        draw?.setFeatureProperty(f?.id, "lineColor", colorOutLine);

        let feat: any = draw.get(f?.id);
        draw?.add(feat);
      }
    });
  }
}

export function changeSourceDataHover(data: any, map: any, sourceId: any) {
  if (map && Object.keys(map).length && map.getSource(sourceId + "_hover")) {
    // @ts-ignore
    map.getSource(sourceId + "_hover").setData(data);
  }
}

export function onMouseEnterItem(item: any, map: any, sourceId: any) {
  let val: any = { type: "FeatureCollection" };
  let properties = item?.properties
    ? { ...item?.properties }
    : item?.metadata
    ? { ...item?.metadata }
    : {};
  properties.id = properties?.id
    ? properties?.id
    : item?.id
    ? item?.id
    : item?.image_id;

  properties.collection = item?.collection ?? "";
  val.features = [
    {
      id: 1,
      type: item?.type ? item?.type : "Feature",
      geometry: item?.geometry ?? item?.geom,
      properties,
    },
  ];

  console.log(val, map, sourceId, "val, map, sourceId");

  //@ts-ignore
  changeSourceDataHover(val, map, sourceId);
}
export function onMouseLeaveItem(item: any, map: any, sourceId: any) {
  //@ts-ignore
  changeSourceDataHover(
    {
      type: "FeatureCollection",
      features: [],
    },
    map,
    sourceId
  );
}

export function panToAndExtend(e: any, item: any, map: any) {
  e.stopPropagation();
  let centerPoint = centroid(item?.geometry);

  map.panTo(centerPoint);
}
