import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { IResponse, IResponsePage } from "../../../types/common";
import { IProviderConfig } from "../providerConfigInterface";
import providerConfigService from "../providerConfigService";
import { IFilterProvider } from "../../../pages/providerBranch/ListProviderBranch";

const useGetProviderConfigs = (filter?: IFilterProvider, enabled = true) => {
  const [data, setData] =
    useState<IResponse<IResponsePage<Partial<IProviderConfig>>>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return providerConfigService.getAll(filter);
  }, []);

  const transformResponse = useCallback(
    (
      response: AxiosResponse<
        IResponse<IResponsePage<Partial<IProviderConfig>>>
      >
    ) => {
      if (response) {
        setData(response.data);
      }
    },
    []
  );

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetProviderConfigs;
