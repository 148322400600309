import React from "react";

export default function MapMenuIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_124_953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 15.2908C12.0266 15.2908 15.2908 12.0266 15.2908 8C15.2908 3.9734 12.0266 0.709194 8 0.709194C3.9734 0.709194 0.709194 3.9734 0.709194 8C0.709194 12.0266 3.9734 15.2908 8 15.2908ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.69229 6.65768L12.0344 3.98334L9.36558 9.33098L4.00977 12.0024L6.69229 6.65768ZM7.99985 9.01801C8.55801 9.01801 9.01048 8.56553 9.01048 8.00737C9.01048 7.44921 8.55801 6.99673 7.99985 6.99673C7.44169 6.99673 6.98921 7.44921 6.98921 8.00737C6.98921 8.56553 7.44169 9.01801 7.99985 9.01801Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_124_953">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
