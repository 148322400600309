import React from "react";

export default function DatetimeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.3335 1.33325V3.33325" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6665 1.33325V3.33325" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M2.3335 6.06006H13.6668" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14 5.66659V11.3333C14 13.3333 13 14.6666 10.6667 14.6666H5.33333C3 14.6666 2 13.3333 2 11.3333V5.66659C2 3.66659 3 2.33325 5.33333 2.33325H10.6667C13 2.33325 14 3.66659 14 5.66659Z"
        stroke="#959595"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10.463 9.13338H10.469" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.463 11.1334H10.469" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99715 9.13338H8.00314" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M7.99715 11.1334H8.00314" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.52938 9.13338H5.53537" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.52938 11.1334H5.53537" stroke="#959595" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
