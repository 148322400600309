export enum DrawType {
  RECTANGLE = "RECTANGLE",
  POLYGON = "POLYGON",
  UPLOAD = "UPLOAD",
  NONE = "NONE",
}

export enum SearchType {
  PLANET = "Planet",
  ONEATLAS = "OneAtlas",
  CAPELLA = "Capella",
  // GEOHUB = "",
}

export enum UploadType {
  SHAPE = "Shape (zip archived)",
  KML = "KML",
  GEOJSON = "Geojson",
  CSV = "CSV",
  QUERY = "Query",
  GEODATABASE = "Geodatabase (zip archived)",
}

export enum CoordinateDisplayType {
  DD = "DD",
  DMS = "DMS",
}

export enum ExportSearchResultType {
  CSV = "Csv (Image name)",
  SHAPE_FILE = "Shape File (shp files archived)",
}

export enum SortSearchResultType {
  DEFAULT = "Default",
  TIME_ASCENDING = "Time (Ascending)",
  TIME_DESCENDING = "Time (Descending)",
  RESOLUTION_ASCENDING = "Resolution (Ascending)",
  RESOLUTION_DESCENDING = "Resolution (Descending)",
  CLOUD_COVER_ASCENDING = "Cloud cover (Ascending)",
  CLOUD_COVER_DESCENDING = "Cloud cover (Descending)",
  INC_ANGLE_ASCENDING = "Inc angle (Ascending)",
  INC_ANGLE_DESCENDING = "Inc angle (Descending)",
}

export enum SortConfigType {
  SORT = "sort",
}

export enum SortDirectionType {
  ASC = "Ascending",
  DSC = "Descending",
}

export enum DefaultFormatDateTime {
  DATE_FORMAT = "DD/MM/YYYY",
  TIME_FORMAT = "HH:mm:ss",
}

export enum MouseCoordinateElementIds {
  LNG_ID = "mouseCoordinate-lng",
  LAT_ID = "mouseCoordinate-lat",
  TYPE_ID = "mouseCoordinate-display-type",
}

export enum EProviderName {
  planet = "planet",
  capella = "capella",
  oneatlas = "oneatlas",
}

export enum ERoleAccessControl {
  admin = "admin",
  user = "user",
}

export enum EOrderStatus {
  ORDER_CREATED = "ORDER_CREATED", // tạo mới
  ORDER_COMPLETED = "ORDER_COMPLETED", // hoàn thành
  ORDER_CANCELED = "ORDER_CANCELED", // huỷ
  ORDER_PROCESSING = "ORDER_PROCESSING", // đang xử lý
  ORDER_REJECTED = "ORDER_REJECTED", // từ chối
  ORDER_CONFIRMED = "ORDER_CONFIRMED", // xác nhận
}

export enum ESttausCode {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}

export enum ETypeImage {
  SAR = "SAR",
  OPTICAL = "OPTICAL",
}
