import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TActionActiveFooter = "Setting" | "Polygon" | "";
export type TActionActiveHeader = "Filter" | "";

interface IStatusActionState {
  actionActiveFooter: TActionActiveFooter;
  actionActiveHeader: TActionActiveHeader;
}

const initialState: IStatusActionState = {
  actionActiveFooter: "",
  actionActiveHeader: "",
};

export const projectSlice = createSlice({
  name: "statusAction",
  initialState,
  reducers: {
    ChangeActionActiveFooter: (
      state,
      action: PayloadAction<TActionActiveFooter>
    ) => {
      if (state.actionActiveFooter === action.payload) {
        state.actionActiveFooter = "";
        return;
      }
      state.actionActiveFooter = action?.payload;
    },

    ChangeActionActiveHeader: (
      state,
      action: PayloadAction<TActionActiveHeader>
    ) => {
      if (state.actionActiveHeader === action.payload) {
        state.actionActiveHeader = "";
        return;
      }
      state.actionActiveHeader = action?.payload;
    },
  },
});

export const { ChangeActionActiveFooter, ChangeActionActiveHeader } =
  projectSlice.actions;

export default projectSlice.reducer;
