import { Button, Dropdown, MenuProps } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CommonIcons from "../../assets/icons";
import LayerSvg from "../../assets/svg/layer.svg";
import { DrawType } from "../../enums";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import { setClickFilter } from "../../store/reducers/mapSlice";
import {
  changeTypeImage,
  setArrProviders,
  setFlagSearch,
  setProviderId,
  setSelected,
} from "../../store/reducers/mapTaskingSlice";
import {
  activeLoadingProvider,
  resetProviderData,
  resetState,
  setIsSearching,
} from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";
import { buttonTheme } from "../../themes/commonTheme";

import CommonComponents from "../Commons";
import ButtonIconComp from "./components/ButtonIcon";

import ApplySvg from "../../assets/svg/apply.svg";
import aaa from "../../assets/svg/search-info.svg";
import { TActionActiveHeader } from "../../store/reducers/statusAction";

interface IProps {
  drawType?: DrawType;
  onChangeDrawType: (type: DrawType) => void;
  onShowFilter: () => void;
  // setClickFilter: any;
  clickFilter: boolean;
  type?: any;
}

export default function Header(props: IProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const idSearch = useSelector((state: RootState) => state.mapTasking.idSearch);
  const actionActiveHeader = useSelector(
    (state: RootState) => state.statusAction.actionActiveHeader
  );

  let activeFeature = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );
  let arrImageTypes = useSelector((state: RootState) =>
    state.appCommonSlice.metaKey?.filter(
      (itemF: any) => itemF?.key === "IMAGE_TYPE_SEARCH"
    )
  );

  let allProviders = useSelector(
    (state: RootState) => state.mapTasking.arrAllProviders
  );

  const typeImage = useSelector(
    (state: RootState) => state.mapTasking.typeImage
  );

  let leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );

  const [valueSearch, setValueSearch] = useState(idSearch);

  const onChangeDrawType = (type: DrawType) => {
    props.onChangeDrawType(type);
  };

  const onShowFilter = () => {
    dispatch(setClickFilter(!props.clickFilter));
    // dispatch(ChangeActionActiveHeader("Filter"));
    dispatch(changeLeftDrawerType(leftDrawerType === "Filter" ? "" : "Filter"));
    dispatch(changeShowDrawer(true));
  };
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(event.target.value);
  };
  const handleSearch = async () => {
    dispatch(setFlagSearch(true));

    dispatch(resetProviderData());
    dispatch(resetState());

    dispatch(changeShowDrawer(true));
    dispatch(changeLeftDrawerType("Search results"));
    dispatch(setIsSearching(true));
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.RectangleDrawIcon />
          <span>Rectangle</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.RECTANGLE),
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.PolygonDrawIcon />
          <span>Polygon</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.POLYGON),
    },

    {
      key: "4",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <CommonIcons.SimpleSelectIcon />
          <span>None</span>
        </div>
      ),
      onClick: () => onChangeDrawType(DrawType.NONE),
    },
    {
      type: "divider",
    },
    {
      key: "5",
      label: (
        <div className="flex items-center gap-1 text-sm">
          <img src={LayerSvg} />
          <span>Histories</span>
        </div>
      ),
      onClick: () => {
        dispatch(changeBottomDrawerType("History list"));
        dispatch(changeShowBottomDrawer(true));
      },
    },
  ];

  const renderSelectTypeImage = () => {
    return arrImageTypes?.map((item: any, ind: any) => {
      return (
        <ButtonIconComp
          item={{
            name: item?.name,
            icon: item?.logo,
            description: item?.name,
          }}
          onClick={() => {
            dispatch(changeTypeImage(item?.value));
            dispatch(activeLoadingProvider());
            dispatch(setFlagSearch(true));

            let a = allProviders?.filter((itemF: any) =>
              itemF?.metadatas?.some(
                (itemMF: any) =>
                  itemMF?.name === "IMAGE_TYPE_SEARCH" &&
                  itemMF?.value === item?.value
              )
            );

            dispatch(setSelected([]));

            dispatch(setArrProviders(a));
            dispatch(setProviderId(a?.[0]?.id));
          }}
          className={`type-image` + (item?.name === typeImage ? " active" : "")}
          classNameImage="!min-w-[20px] !w-[20px] !h-[20px]"
        />
      );
    });
  };

  const renderCursor = () => {
    let a = "pointer";
    if (props?.type === "map-tasking" && !activeFeature && !activeFeature?.id) {
      a = "not-allowed";
    }
    return a;
  };

  const renderHandle = () => {
    if (props?.type !== "map-tasking") {
      // onShowFilter();
      // return;
      handleSearch();
    } else {
      if (activeFeature && activeFeature?.id) {
        navigate("/abc");
      }
    }
  };

  //! effect
  useEffect(() => {
    setValueSearch(idSearch);
  }, [idSearch]);

  useEffect(() => {
    dispatch(setClickFilter(false));
  }, [location]);

  //! render
  return (
    <div className="flex justify-between">
      <div className="flex gap-2 items-center justify-between">
        {props?.type !== "map-tasking" && renderSelectTypeImage()}
      </div>
      <div
        className="flex items-center gap-1 !rounded-[3px] w-[450px] mr-[10px]"
        style={{ backgroundColor: "#23293A" }}
      >
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Button
            icon={<CommonIcons.ArrowDownIcon />}
            iconPosition="end"
            className={`${buttonTheme.className.standard} !text-xs !h-7 !bg-transparent`}
          >
            {props.drawType === DrawType.RECTANGLE ? (
              <CommonIcons.RectangleDrawIcon />
            ) : null}
            {props.drawType === DrawType.POLYGON ? (
              <CommonIcons.PolygonDrawIcon />
            ) : null}

            {!props.drawType && <CommonIcons.RectangleDrawIcon />}
          </Button>
        </Dropdown>

        <CommonComponents.InputUI
          className="w-[343px] h-9 !px-0 border-b-0"
          placeholder="Search"
          value={valueSearch}
          onChange={handleChangeSearch}
          suffix={
            props?.type !== "map-tasking" && (
              <div
                className="!bg-[#353A4B] px-[10px] rounded-[3px] h-7 w-fit div-one-center shadow-hover"
                onClick={renderHandle}
                style={{ fontSize: "12px", cursor: renderCursor() }}
              >
                <div className="flex items-center gap-1">
                  <img
                    src={props?.type === "map-tasking" ? ApplySvg : aaa}
                    className="size-3"
                  />
                  <span>
                    {props?.type === "map-tasking"
                      ? "Order Tasking"
                      : "Existing Image"}
                  </span>
                </div>
              </div>
            )
          }
        />
        {props?.type !== "map-tasking" && (
          <CommonComponents.ButtonUI
            icon={<CommonIcons.FilterIcon />}
            commonType="standard"
            className={
              `!text-xs !h-7 ` +
              (leftDrawerType === "Filter"
                ? "!bg-common-activeAction hover:!bg-common-activeAction !text-black"
                : "")
            }
            style={{ background: "none" }}
            onClick={onShowFilter}
          />
        )}
      </div>
    </div>
  );
}
