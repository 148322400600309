import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MySharedDataComp from "../../components/ManageData/MySharedData";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";

const MySharedDataPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayoutName("manage-data/my-shared-images"));
    dispatch(
      changeDataBreadcrumb([
        { title: "Quản lý dữ liệu" },
        {
          title: "Ảnh được chia sẻ",
        },
      ])
    );

    dispatch(changeLeftDrawerType(""));
    dispatch(changeShowDrawer(false));

    dispatch(changeShowBottomDrawer(false));
    dispatch(changeBottomDrawerType(""));
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));

      dispatch(changeLeftDrawerType(""));
      dispatch(changeShowDrawer(false));

      dispatch(changeShowBottomDrawer(false));
      dispatch(changeBottomDrawerType(""));
    };
  }, []);
  return <MySharedDataComp />;
};

export default MySharedDataPage;
