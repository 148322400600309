import React from "react";
import { Collapse, CollapseProps } from "antd";

// TODO
const dataTest = {
  "METADATA.DIM": {
    href: "",
    title: "METADATA.DIM",
    description: "",
    type: "",
    roles: [],
  },
  "PREVIEW.JPG": {
    href: "",
    title: "PREVIEW.JPG",
    description: "",
    type: "image/jpeg",
    roles: [],
  },
  "VOL_LIST.DIM": {
    href: "",
    title: "VOL_LIST.DIM",
    description: "",
    type: "",
    roles: [],
  },
  "IMAGERY.TIF": {
    href: "",
    title: "IMAGERY.TIF",
    description: "",
    type: "image/tiff; application=geotiff",
    roles: [],
  },
  "INFO.XML": {
    href: "",
    title: "INFO.XML",
    description: "",
    type: "text/xml",
    roles: [],
  },
};

interface IProps {
  assets?: any;
}

export default function Assets({ assets = dataTest }: IProps) {
  const generateAssets = () => {
    if (!assets) {
      return null;
    }

    const keys = Object.keys(assets);

    return keys.map((key, index) => {
      const items: CollapseProps["items"] = [
        {
          key: index,
          label: key,
          children: (
            <div className="border-t border-common-line pt-2 flex flex-col gap-2">
              <div>
                <span className="text-[#959595] text-xs">href:</span> <span className="text-white text-sm">{assets[key]["href"]}</span>
              </div>
              <div>
                <span className="text-[#959595] text-xs">description:</span> <span className="text-white text-sm">{assets[key]["description"]}</span>
              </div>
              <div>
                <span className="text-[#959595] text-xs">type:</span> <span className="text-white text-sm">{assets[key]["type"]}</span>
              </div>
            </div>
          ),
        },
      ];

      return <Collapse bordered={false} expandIconPosition="end" size="small" items={items} />;
    });
  };

  return (
    <div className="flex flex-col my-5 gap-4">
      {/* TODO */}
      {generateAssets()}
    </div>
  );
}
