import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonComponents from "../../components/Commons";
import InfoOrder from "../../components/ManageOrder/InfoOrder";
import ManagementOrderComp from "../../components/ManageOrder/ManagementOrder";
import {
  changeLeftDrawerType,
  resetAllDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import { RootState } from "../../store/store";
import { LeftDrawerType } from "../../types/common";
import {
  resetStateFooter,
  setShowPaging,
} from "../../store/reducers/appCommonSlice";

const ManagementOrderPage = () => {
  //! state
  const dispatch = useDispatch();
  const { showDrawer, leftDrawerType } = useSelector(
    (state: RootState) => state.drawerSlice
  );
  //! function
  const onChangeLeftDrawerType = (type: LeftDrawerType) => {
    if (leftDrawerType === type) {
      dispatch(changeLeftDrawerType(""));
      return;
    }
    dispatch(changeLeftDrawerType(type));
  };

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-order"));
    dispatch(changeDataBreadcrumb([{ title: "Existing Image Orders" }]));

    dispatch(resetAllDrawer());

    dispatch(setShowPaging(true));

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(resetAllDrawer());
      dispatch(resetStateFooter({}));
    };
  }, []);

  return (
    <div>
      <ManagementOrderComp />

      <div>
        <CommonComponents.DrawerUI
          open={showDrawer}
          placement="right"
          width={460}
        >
          <InfoOrder
            hidden={leftDrawerType !== "InforOrder"}
            onClose={() => {
              onChangeLeftDrawerType("InforOrder");
            }}
          />
        </CommonComponents.DrawerUI>
      </div>
    </div>
  );
};

export default ManagementOrderPage;
