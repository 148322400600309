import AccountMenuIcon from "./AccountMenuIcon";
import ArrowDownIcon from "./ArrowDownIcon";
import Camera0Icon from "./Camera0Icon";
import CameraIcon from "./CameraIcon";
import CartMenuIcon from "./CartMenuIcon";
import CartOutlinedIcon from "./CartOutlinedIcon";
import CheckIcon from "./CheckIcon";
import ChevronLeftIcon from "./ChevronLeftIcon";
import ChevronUpIcon from "./ChevronUpIcon";
import CloudIcon from "./CloudIcon";
import CSVIcon from "./CSVIcon";
import CubeFilledIcon from "./CubeFilledIcon";
import DatasetIcon from "./DatasetIcon";
import DismissIcon from "./DismissIcon";
import DownloadIcon from "./DownloadIcon";
import FilterIcon from "./FilterIcon";
import FolderIcon from "./FolderIcon";
import FootIcon from "./FootIcon";
import GeodatabaseIcon from "./GeodatabaseIcon";
import GeojsonIcon from "./GeojsonIcon";
import InfoCircleIcon from "./InfoCircleIcon";
import KMLIcon from "./KMLIcon";
import BasemapIcon from "./BasemapIcon";
import MapIcon from "./MapIcon";
import MapMenuIcon from "./MapMenuIcon";
import MenuIcon from "./MenuIcon";
import MinusIcon from "./MinusIcon";
import NotificationMenuIcon from "./NotificationMenuIcon";
import PlusIcon from "./PlusIcon";
import PolygonDrawIcon from "./PolygonDrawIcon";
import QueryIcon from "./QueryIcon";
import RadaIcon from "./RadaIcon";
import RectangleDrawIcon from "./RectangleDrawIcon";
import SearchIcon from "./SearchIcon";
import ShapeIcon from "./ShapeIcon";
import SimpleSelectIcon from "./SimpleSelectIcon";
import StorageIcon from "./StorageIcon";
import TrashIcon from "./TrashIcon";
import UploadPolygonIcon from "./UploadPolygonIcon";
import ZoomIcon from "./ZoomIcon";
import ImageInfoCloudIcon from "./imageInformationIcons/CloudIcon";
import ImageInfoCreatedIcon from "./imageInformationIcons/CreatedIcon";
import ImageInfoDatetimeIcon from "./imageInformationIcons/DatetimeIcon";
import ImageInfoInstrumentsIcon from "./imageInformationIcons/InstrumentsIcon";
import ImageInfoPlatformIcon from "./imageInformationIcons/PlatformIcon";
import ImageInfoShapeIcon from "./imageInformationIcons/ShapeIcon";
import ImageInfoSunAzimuthIcon from "./imageInformationIcons/SunAzimuthIcon";
import ImageInfoSunElevationIcon from "./imageInformationIcons/SunElevationIcon";
import SettingIcon from "./SettingIcon";

const CommonIcons = {
  AccountMenuIcon,
  CartMenuIcon,
  MapMenuIcon,
  MenuIcon,
  NotificationMenuIcon,
  PolygonDrawIcon,
  RectangleDrawIcon,
  UploadPolygonIcon,
  BasemapIcon,
  MinusIcon,
  PlusIcon,
  RadaIcon,
  ZoomIcon,
  FilterIcon,
  SearchIcon,
  ArrowDownIcon,
  ChevronUpIcon,
  CubeFilledIcon,
  DatasetIcon,
  StorageIcon,
  DismissIcon,
  DownloadIcon,
  FolderIcon,
  InfoCircleIcon,
  TrashIcon,
  CameraIcon,
  Camera0Icon,
  CartOutlinedIcon,
  CloudIcon,
  FootIcon,
  MapIcon,
  CSVIcon,
  GeodatabaseIcon,
  GeojsonIcon,
  KMLIcon,
  QueryIcon,
  ShapeIcon,
  CheckIcon,
  ChevronLeftIcon,
  SimpleSelectIcon,
  ImageInfoCloudIcon,
  ImageInfoCreatedIcon,
  ImageInfoDatetimeIcon,
  ImageInfoInstrumentsIcon,
  ImageInfoPlatformIcon,
  ImageInfoShapeIcon,
  ImageInfoSunAzimuthIcon,
  ImageInfoSunElevationIcon,
  SettingIcon,
};

export default CommonIcons;
