import React from "react";

export default function GeodatabaseIcon() {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.03711 30.5588V3.44118C6.03711 2.61275 6.70868 1.94118 7.53711 1.94118H20.3527C20.7859 1.94118 21.1979 2.12844 21.4828 2.45478L26.9954 8.77017C27.2339 9.04343 27.3653 9.39386 27.3653 9.75657V30.5588C27.3653 31.3873 26.6937 32.0588 25.8653 32.0588H7.53711C6.70868 32.0588 6.03711 31.3873 6.03711 30.5588Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M20.5391 7.97059V1.94118L27.1273 9.47059H22.0391C21.2106 9.47059 20.5391 8.79902 20.5391 7.97059Z" fill="white" />
      <path
        d="M13.5457 18.959H9.66992V23.4047C9.66992 24.4749 10.5375 25.3426 11.6078 25.3426C12.6781 25.3426 13.5457 24.4749 13.5457 23.4047V18.959Z"
        fill="white"
      />
      <rect x="9.66992" y="3" width="2.27985" height="2.27985" fill="white" />
      <rect x="11.9492" y="5.27985" width="2.27985" height="2.27985" fill="white" />
      <rect x="9.66992" y="7.55971" width="2.27985" height="2.27985" fill="white" />
      <rect x="11.9492" y="9.83956" width="2.27985" height="2.27985" fill="white" />
      <rect x="9.66992" y="12.1194" width="2.27985" height="2.27985" fill="white" />
      <rect x="11.9492" y="14.3993" width="2.27985" height="2.27985" fill="white" />
      <rect x="9.66992" y="16.6791" width="2.27985" height="2.27985" fill="white" />
    </svg>
  );
}
