import {
  Checkbox,
  CheckboxProps,
  Collapse,
  CollapseProps,
  Divider,
  Radio,
  RadioChangeEvent,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetLeftDrawer } from "../../store/reducers/drawerSlice";

import { useNavigate } from "react-router";
import CloseSvg from "../../assets/svg/dismiss.svg";
import TaskingSvg from "../../assets/svg/tasking.svg";
import {
  changeShowOrderTasking,
  setOrderedProvider,
  setPolygonOrderTasking,
} from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import ButtonIconComp from "./components/ButtonIcon";
import { RenderInfoPolygon } from "./ultis";
import CommonIcons from "../../assets/icons";
import { useEffect, useState } from "react";
import ApplySvg from "../../assets/svg/apply.svg";
import { getProviderAsync } from "../../store/reducers/mapTaskingSlice";

const InfoPolygonComp = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );
  const [loading, setLoading] = useState(false);

  const CheckboxGroup = Checkbox.Group;

  const [allProviders, setAllProviders] = useState<any>([]);

  const [checkedList, setCheckedList] = useState<any>([]);

  const checkAll = allProviders?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList.length < allProviders?.length;

  const handleOrderTasking = () => {
    setLoading(true);

    dispatch(setOrderedProvider(checkedList));
    dispatch(changeShowOrderTasking(true));
    dispatch(setPolygonOrderTasking(selectedPolygon));
    setTimeout(() => {
      navigate("/tasking-order");
    }, 1000);
  };
  const onChange = (list: string[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    setCheckedList(
      e.target.checked ? allProviders?.map((item: any) => item?.value) : []
    );
  };

  const getProviders = async () => {
    // @ts-ignore
    let resProvider = await dispatch(getProviderAsync()).unwrap();

    let a = resProvider?.data?.data?.content_page
      ?.filter((item: any) => item?.actived)
      .map((item: any) => ({
        label: item?.name,
        value: item?.id,
      }));

    setAllProviders(a ?? []);
  };

  const handleChangeRadio = (event: RadioChangeEvent) => {
    setCheckedList([event.target.value]);
  };

  useEffect(() => {
    if (window.location.pathname === "/explore-tasking") {
      getProviders();
    }
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    const providerCapella = allProviders?.find(
      (item: any) => item?.label === "Capella"
    );
    setCheckedList([providerCapella?.value]);
  }, [allProviders]);
  console.log("checkedList", checkedList);
  return (
    <div
      key={1}
      hidden={props?.hidden}
      className="w-[460px] h-full p-3 overflow-x-auto"
    >
      <Spin className="div-spin !max-h-full" spinning={loading}>
        <div className="flex justify-between items-center py-1 sticky -top-3 z-10 bg-common-ink100">
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <img src={TaskingSvg} />

            <div>{selectedPolygon?.name}</div>
          </div>

          <div className="flex items-center gap-2">
            {/* <ButtonIconComp
            item={{ title: "Order Tasking" }}
            onClick={() => {
              handleOrderTasking();
            }}
            className="!text-xs !h-7 !px-3 div-one-center"
            disabled={!selectedPolygon?.id}
          /> */}
            {window.location.pathname === "/explore-tasking" && (
              <ButtonIconComp
                item={{ title: "Order", icon: ApplySvg }}
                onClick={() => handleOrderTasking()}
                className="!text-xs !h-7 !px-2"
                disabled={checkedList?.length === 0}
              />
            )}
            <ButtonIconComp
              item={{ des: "Close", icon: CloseSvg }}
              onClick={() => dispatch(resetLeftDrawer())}
              className="!text-xs !h-7"
            />
          </div>
        </div>

        <Divider className="border-common-line !mt-1 !mb-2" />
        {window.location.pathname === "/explore-tasking" && (
          <div className="mb-4 mt-2" style={{ color: "white" }}>
            {/* <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
              style={{ color: "white" }}
              className="mb-2"
            >
              All Providers
            </Checkbox> */}
            <br />
            {/* <CheckboxGroup
              options={allProviders}
              value={checkedList}
              onChange={onChange}
              style={{ color: "white" }}
            /> */}

            <Radio.Group onChange={handleChangeRadio} value={checkedList[0]}>
              {allProviders?.map((item: any, index: number) => {
                return (
                  <Radio key={index} value={item?.value}>
                    {item?.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        )}

        <div className="bg-[#2C3243]">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            // defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: "Polygon information",
                children: RenderInfoPolygon(selectedPolygon),
              },
            ]}
            defaultActiveKey={["1"]}
            onChange={() => {}}
            className="!p-0"
            expandIcon={({ isActive }) => (
              <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                <CommonIcons.ChevronUpIcon />
              </div>
            )}
          />
        </div>
      </Spin>
    </div>
  );
};

export default InfoPolygonComp;
