import { useSelector } from "react-redux";
import { RootState } from "../store/store";

export const CheckRoleAccessControlAdmin = () => {
  const roleUsing = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );

  return roleUsing;
};
