import React from "react";

export default function DownloadIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 8.75V2.11166" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.95423 7.77282L7.00007 9.72699L5.0459 7.77282" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.86328 12.1145H12.1377" stroke="currentColor" strokeLinecap="round" />
    </svg>
  );
}
