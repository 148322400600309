import { Slider } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import {
  getProductTypeIdAsync,
  getProductionAsync,
} from "../../../store/reducers/orderSlice";
import CommonComponents from "../../Commons";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";
import CardItemConfirmComp from "../CardItemConfirm";

const Step4 = (props: any) => {
  const dispatch = useDispatch();

  const roleAdmin = CheckRoleAccessControlAdmin();

  const [productTypes, setProductTypes] = useState<any>([]);
  const [dataProduction, setDataProduction] = useState<any>([]);

  const getDataProductTypeId = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getProductTypeIdAsync({ type: "archive" })
    ).unwrap();

    setProductTypes(res?.data?.data?.productTypes ?? []);
  };

  useEffect(() => {
    if (props?.initialValues && props.form && dataProduction?.length > 0) {
      const initialValuesObject = props.initialValues.reduce(
        (acc: any, item: any) => {
          acc[item.key] =
            item.key !== "elevation" ? item.value : parseInt(item.value);
          return acc;
        },
        {}
      );
      console.log(initialValuesObject, "xuan");

      props.form.setFieldsValue({
        oneatlas: initialValuesObject,
      });
    }
  }, [props?.initialValues, dataProduction, props.form]);

  useEffect(() => {
    getDataProductTypeId();
  }, []);

  const getProduction = async () => {
    // @ts-ignore
    let res = await dispatch(getProductionAsync()).unwrap();

    setDataProduction(
      res?.data?.data?.availableOptions?.filter(
        (item: any) => item?.category === "production_option"
      ) ?? []
    );
  };

  useEffect(() => {
    getProduction();
  }, [props?.initialValues]);

  const parseOptions = useCallback((data: any) => {
    if (!data) {
      return [];
    }
    return data?.map((item: any) => {
      return {
        label: item?.label,
        value: item?.id,
      };
    });
  }, []);

  console.log(
    parseInt(props?.form.getFieldValue("elevation")),
    'parseInt(props?.form.getFieldValue("elevation"))'
  );

  return (
    <div className="p-4 div-form-step-4">
      <div className="grid grid-cols-3 gap-x-3" style={{ color: "white" }}>
        {dataProduction?.map((item: any) => {
          if (!!item?.range) {
            return (
              <CommonComponents.FormFieldUI
                name={["oneatlas", item?.name]}
                component={
                  <div>
                    <p className="mb-1 text-white text-sm">
                      {item?.label}
                      {item?.mandatory === "true" && (
                        <span className="text-common-red500">{` *`}</span>
                      )}
                    </p>
                    <Slider
                      defaultValue={
                        parseInt(
                          props?.initialValues?.filter(
                            (itemF: any) => itemF?.key === "elevation"
                          )?.[0]?.value
                        ) ?? 0
                      }
                      min={item?.range[0]}
                      max={item?.range[1]}
                      onChangeComplete={(value) => {
                        props.form.setFieldsValue({
                          oneatlas: {
                            [`${item?.name}`]: String(value),
                          },
                        });
                      }}
                      disabled={props?.readOnly}
                    />
                  </div>
                }
              />
            );
          }
          return (
            <CommonComponents.FormFieldUI
              name={["oneatlas", item?.name]}
              rules={[
                {
                  required: item?.mandatory === "true" ? true : false,
                  message: "Please select here!",
                },
              ]}
              component={
                <>
                  <CommonComponents.SelectUI
                    label={item?.label}
                    required={item?.mandatory === "true" ? true : false}
                    data={parseOptions(item?.values)}
                    disabled={props?.readOnly}
                  />
                </>
              }
            />
          );
        })}

        <CommonComponents.FormFieldUI
          name={["oneatlas", "product_type_id"]}
          component={
            <CommonComponents.SelectUI
              label="Product Type ID"
              placeholder="Product Type ID"
              data={productTypes}
              disabled={props?.readOnly}
              required
              nameValue={"productTypeId"}
              nameLabel="productTypeLabel"
            />
          }
          rules={[{ required: true, message: "Please select here!" }]}
        />
      </div>

      {props?.dataImages?.map((item: any) => (
        <CardItemConfirmComp
          item={item}
          onMouseLeaveItem={() =>
            onMouseLeaveItem(item?.feature, props.map, props.sourceId)
          }
          onMouseEnterItem={() =>
            onMouseEnterItem(item?.feature, props.map, props.sourceId)
          }
        />
      ))}
    </div>
  );
};

export default Step4;
