import React from "react";

export default function RadaIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13.2667C10.9087 13.2667 13.2667 10.9087 13.2667 8C13.2667 5.0913 10.9087 2.73333 8 2.73333C5.0913 2.73333 2.73333 5.0913 2.73333 8C2.73333 10.9087 5.0913 13.2667 8 13.2667ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        fill="currentColor"
      />
      <path d="M7.2876 2.18906H8.61411V5.41896H7.2876V2.18906Z" fill="currentColor" />
      <path d="M13.7183 7.17813L13.7183 8.50465L10.4884 8.50465L10.4884 7.17813L13.7183 7.17813Z" fill="currentColor" />
      <path d="M5.51123 7.17813L5.51123 8.50465L2.28133 8.50465L2.28133 7.17813L5.51123 7.17813Z" fill="currentColor" />
      <path d="M7.2876 10.581H8.61411V13.8109H7.2876V10.581Z" fill="currentColor" />
    </svg>
  );
}
