import React from "react";

export default function InfoCircleIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00033 1.16667C10.2087 1.16667 12.8337 3.79167 12.8337 7C12.8337 10.2083 10.2087 12.8333 7.00033 12.8333C3.79199 12.8333 1.16699 10.2083 1.16699 7C1.16699 3.79167 3.79199 1.16667 7.00033 1.16667Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M7 9.33334V6.41667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.99707 4.66666H7.00231" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
