import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKey } from "../../constants/localStorageKeys";

interface ILayoutState {
  expanded?: boolean;
  layoutName?: string;
  dataBreadcrumb?: any;
}

const GetExpanLocal = () => {
  const result = window.localStorage.getItem(LocalStorageKey.EXPAND);
  if (result) {
    switch (result) {
      case "true":
        return true;
      case "false":
        return false;
    }
  }
  return true;
};
const initialState: ILayoutState = {
  // expanded: GetExpanLocal(),
  expanded: false,
  layoutName: "Map",
  dataBreadcrumb: [],
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    changeExpanded: (state, action: PayloadAction<boolean>) => {
      state.expanded = action.payload;
    },
    changeLayoutName: (state, action: PayloadAction<string>) => {
      state.layoutName = action.payload;
    },

    changeDataBreadcrumb: (
      state,
      action: PayloadAction<
        {
          title: string | any;
          path?: string;
        }[]
      >
    ) => {
      state.dataBreadcrumb = action.payload;
    },
  },
});

export const { changeExpanded, changeLayoutName, changeDataBreadcrumb } =
  layoutSlice.actions;

export default layoutSlice.reducer;
