import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetBasemap from "../../../../services/basemap/hooks/useGetBasemap";
import { IBaseMapSelected } from "../../../../services/basemap/interfaces/IBasemap.interface";
import { setBasemapSelected } from "../../../../store/reducers/mapSlice";
import { RootState } from "../../../../store/store";
import themeColor from "../../../../themes/colorTheme";
import CommonComponents from "../../../Commons";
import DropdownUI from "../../../Commons/Dropdown";
import CommonIcons from "../../../../assets/icons";
import { Button, Dropdown, MenuProps } from "antd";
import { buttonTheme } from "../../../../themes/commonTheme";
import {
  CoordinateDisplayType,
  MouseCoordinateElementIds,
} from "../../../../enums";
import {
  displayMouseCoordinate,
  getCurrentMouseCoordinate,
} from "../../../../helpers/map";
import { setCoordinateDisplayType } from "../../../../store/reducers/mapTaskingSlice";

const BaseMap = () => {
  //! state
  const dispatch = useDispatch();
  const coordinateDisplayType = useSelector(
    (state: RootState) => state.mapTasking.coordinateDisplayType
  );
  const baseMapSelected = useSelector(
    (state: RootState) => state.mapSlice.baseMapSelected
  );
  const { data, isLoading } = useGetBasemap();

  //! function
  const handleSelectBasemap = (item: IBaseMapSelected) => {
    dispatch(setBasemapSelected(item));
  };

  //! effect
  //! render
  const renderBasemap = useCallback(() => {
    if (data?.data && data.data.configs) {
      return data.data.configs.map((item, index) => {
        // const itemBaseMap: IBaseMapSelected = JSON.parse(item);
        const itemBaseMap: any = item;
        return (
          <div
            className="flex flex-col gap-1 p-1 cursor-pointer hover:outline hover:outline-2 hover:outline-common-primary100 hover:-outline-offset-2 !rounded-[3px]"
            style={{
              backgroundColor:
                baseMapSelected?.url === itemBaseMap.url
                  ? themeColor.primary100
                  : "transparent",
            }}
            onClick={() => handleSelectBasemap(itemBaseMap)}
          >
            <CommonComponents.TooltipUI
              title={String(itemBaseMap?.name)}
              placement="bottom"
            >
              <div>
                <img
                  src={itemBaseMap.thumbURL}
                  alt=""
                  // className="!w-[144px] !h-[94px]"
                />
              </div>

              <div className="flex items-center gap-1 text-sm text-common-white font-normal">
                <span className="truncate">{itemBaseMap?.name}</span>
              </div>
            </CommonComponents.TooltipUI>
          </div>
        );
      });
    }
  }, [isLoading, baseMapSelected]);
  return (
    <div>
      <div className="grid grid-cols-4 gap-4">{renderBasemap()}</div>
    </div>
  );
};

export default BaseMap;
