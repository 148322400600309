import React from "react";
import { UploadType } from "../../../enums";
import CommonIcons from "../../../assets/icons";

interface IProps {
  type: UploadType;
  onClick?: () => void;
}

export default function UploadItemCardUI({ type, onClick }: IProps) {
  let icon;

  switch (type) {
    case UploadType.SHAPE:
      icon = <CommonIcons.ShapeIcon />;
      break;

    case UploadType.KML:
      icon = <CommonIcons.KMLIcon />;
      break;

    case UploadType.GEOJSON:
      icon = <CommonIcons.GeojsonIcon />;
      break;

    case UploadType.CSV:
      icon = <CommonIcons.CSVIcon />;
      break;

    case UploadType.QUERY:
      icon = <CommonIcons.QueryIcon />;
      break;

    case UploadType.GEODATABASE:
      icon = <CommonIcons.GeodatabaseIcon />;
      break;

    default:
      break;
  }
  return (
    <div className="flex flex-col items-center gap-3 cursor-pointer" onClick={onClick}>
      <div className="bg-common-ink300 w-[85px] h-[85px] flex justify-center items-center text-common-white">{icon}</div>

      <div className="text-sm text-common-white font-normal text-center">{type}</div>
    </div>
  );
}
