import React from "react";

export default function Camera0Icon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21021 2.08685C4.21021 1.31158 4.83869 0.683105 5.61395 0.683105H8.42144C9.1967 0.683105 9.82518 1.31158 9.82518 2.08685L12.0363 2.08685C13.1408 2.08685 14.0362 2.98228 14.0362 4.08685V11.3168C14.0362 12.4214 13.1408 13.3168 12.0362 13.3168H1.99882C0.894246 13.3168 -0.0011834 12.4214 -0.0011834 11.3168V4.08685C-0.0011834 2.98228 0.894247 2.08685 1.99882 2.08685L4.21021 2.08685ZM12.6317 3.49059H1.40175V11.913H12.6317V3.49059ZM7.01749 10.5093C8.56802 10.5093 9.82497 9.25236 9.82497 7.70182C9.82497 6.15129 8.56802 4.89434 7.01749 4.89434C5.46696 4.89434 4.21 6.15129 4.21 7.70182C4.21 9.25236 5.46696 10.5093 7.01749 10.5093ZM7.01744 9.1047C6.24241 9.1047 5.61413 8.47641 5.61413 7.70139C5.61413 6.92636 6.24241 6.29808 7.01744 6.29808C7.79246 6.29808 8.42074 6.92636 8.42074 7.70139C8.42074 8.47641 7.79246 9.1047 7.01744 9.1047ZM11.2282 4.19247C10.8405 4.19247 10.5263 4.5067 10.5263 4.89434C10.5263 5.28197 10.8405 5.59621 11.2282 5.59621C11.6158 5.59621 11.9301 5.28197 11.9301 4.89434C11.9301 4.5067 11.6158 4.19247 11.2282 4.19247Z"
        fill="currentColor"
      />
      <mask id="mask0_25_14301" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="0" width="16" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21041 2.08685C4.21041 1.31158 4.83889 0.683105 5.61416 0.683105H8.42165C9.19691 0.683105 9.82539 1.31158 9.82539 2.08685L12.0365 2.08685C13.141 2.08685 14.0365 2.98228 14.0365 4.08685V11.3168C14.0365 12.4214 13.141 13.3168 12.0365 13.3168H1.99902C0.894452 13.3168 -0.000976562 12.4214 -0.000976562 11.3168V4.08685C-0.000976562 2.98228 0.894454 2.08685 1.99902 2.08685L4.21041 2.08685ZM12.6319 3.49059H1.40196V11.913H12.6319V3.49059ZM7.01769 10.5093C8.56823 10.5093 9.82518 9.25236 9.82518 7.70182C9.82518 6.15129 8.56823 4.89434 7.01769 4.89434C5.46716 4.89434 4.21021 6.15129 4.21021 7.70182C4.21021 9.25236 5.46716 10.5093 7.01769 10.5093ZM7.01764 9.1047C6.24262 9.1047 5.61433 8.47641 5.61433 7.70139C5.61433 6.92636 6.24262 6.29808 7.01764 6.29808C7.79267 6.29808 8.42095 6.92636 8.42095 7.70139C8.42095 8.47641 7.79267 9.1047 7.01764 9.1047ZM11.2284 4.19247C10.8408 4.19247 10.5265 4.5067 10.5265 4.89434C10.5265 5.28197 10.8408 5.59621 11.2284 5.59621C11.616 5.59621 11.9303 5.28197 11.9303 4.89434C11.9303 4.5067 11.616 4.19247 11.2284 4.19247Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_25_14301)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 14H14V0H0V14Z" fill="currentColor" />
      </g>
    </svg>
  );
}
