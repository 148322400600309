import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React from "react";
interface IProps {
  label?: string;
  required?: string;
  value?: any;
  children?: React.ReactNode | string;
  className?: string;
  checked?: boolean;
  onChange?: (e: CheckboxChangeEvent) => any;
}
const CheckboxUI = (props: IProps) => {
  //! state
  const {
    label,
    required,
    value,
    children,
    className,
    checked,
    onChange,
    ...res
  } = props;
  //! function
  //! render
  return (
    <div>
      {label && (
        <p className="mb-1 text-white text-sm">
          {label}
          {required && <span className="text-common-red500">{` *`}</span>}
        </p>
      )}
      <Checkbox
        className={className}
        value={value}
        checked={checked}
        onChange={onChange}
        {...res}
      >
        {children}
      </Checkbox>
    </div>
  );
};

export default CheckboxUI;
