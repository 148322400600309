import { apis } from "../../constants/apis";
import httpService from "../httpService";

export async function getMyProjects(payload: any) {
  return httpService.get(`${apis.project.getMyProjects}`, {
    params: {
      ...payload,
    },
  });
}

class ProjectService {
  GetProjects(query?: any) {
    return httpService.get(apis.project.url, {
      params: { ...query },
    });
  }
  GetProjectById(id: number) {
    return httpService.get(`${apis.project.url}/${id}`);
  }
  PostProject(body: any) {
    return httpService.post(apis.project.url, body);
  }
  PutProject(id: number, body: any) {
    return httpService.put(`${apis.project.url}/${id}`, body);
  }
  DeletProject(id: number) {
    return httpService.delete(`${apis.project.url}/${id}`);
  }
}

export default new ProjectService();
