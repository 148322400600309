import CardManagementComp from "../Commons/CardManagement";

import FilePdfSvg from "../../assets/svg/ManageData/file-pdf.svg";
import DeleteSvg from "../../assets/svg/delete.svg";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";

const UploadDataComp = () => {
  const arrData = [
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
    },
    {
      imgSrc: FilePdfSvg,
      title: "0fff66d6a362563c0f73.pdf",
      des: "1.2 MB",
    },
  ];
  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {arrData?.map((item: any, ind: number) => (
          <CardManagementComp
            key={ind}
            data={item}
            actions={[<img alt="example" src={DeleteSvg} />]}
          />
        ))}
      </div>
    </ContentLayoutManagement>
  );
};

export default UploadDataComp;
