import React from "react";
import InfoSvg from "../../../../assets/svg/ManageData/info.svg";
import DismissSvg from "../../../../assets/svg/dismiss.svg";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { changeShowDrawer } from "../../../../store/reducers/drawerSlice";
import { changeShowDrawerListImages } from "../../../../store/reducers/orderSlice";
import CardItemImageComp from "./CardItemImage";
import CardManagementComp from "../../../Commons/CardManagement";
import ImageDefault from "../../../../assets/svg/Order/default-item.png";

const DrawerListImages = () => {
  const dispatch = useDispatch();

  const renderHeader = () => {
    return (
      <div
        className="flex justify-between items-center pb-3"
        style={{ borderBottom: "1px solid #FFFFFF33" }}
      >
        <div className="flex gap-2" style={{ color: "white" }}>
          <img src={InfoSvg} /> Danh sách ảnh yêu cầu
        </div>
        <div>
          <Button
            className="btn-action-icon"
            icon={<img src={DismissSvg} />}
            onClick={() => {
              dispatch(changeShowDrawer(false));
              dispatch(changeShowDrawerListImages(false));
            }}
          />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="mt-3">
        <CardItemImageComp
          data={{
            imgSrc: ImageDefault,
            title: "Sentinel-1 Synthetic Aperture Radar (SAR)",
            des: (
              <span>
                11/29/2023 16:24:14 UTC <br /> Vệ tinh: SPOT 6 | Cloud(%): 0.1
              </span>
            ),
          }}
          actions={[]}
        />
      </div>
    );
  };
  return (
    <div className="p-[14px]">
      {renderHeader()} {renderBody()}
    </div>
  );
};

export default DrawerListImages;
