import React from "react";

export default function KMLIcon() {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.03418 30.5588V3.44116C6.03418 2.61273 6.70575 1.94116 7.53418 1.94116H20.3498C20.783 1.94116 21.195 2.12842 21.4799 2.45476L26.9924 8.77016C27.231 9.04342 27.3624 9.39384 27.3624 9.75656V30.5588C27.3624 31.3872 26.6908 32.0588 25.8624 32.0588H7.53418C6.70575 32.0588 6.03418 31.3872 6.03418 30.5588Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M20.5361 7.97058V1.94116L27.1244 9.47057H22.0361C21.2077 9.47057 20.5361 8.799 20.5361 7.97058Z" fill="white" />
      <rect x="2.65332" y="12.4516" width="28.0279" height="12.9076" rx="1.5" fill="white" />
      <path
        d="M22.4615 22.6547V22.7547H22.5615H27.299H27.399V22.6547V21.4973V21.3973H27.299H24.0422V15.0331V14.9331H23.9422H22.5615H22.4615V15.0331V22.6547Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M13.0645 14.9331H12.9645V15.0331V22.6547V22.7547H13.0645H14.3893H14.4893V22.6547V17.7475L16.4736 22.6698L16.4989 22.7324H16.5664H17.5563H17.6237L17.649 22.6699L19.6334 17.7581V22.6547V22.7547H19.7334H21.0582H21.1582V22.6547V15.0331V14.9331H21.0582H19.3687H19.3016L19.2761 14.9952L17.0613 20.401L14.8465 14.9952L14.8211 14.9331H14.754H13.0645Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M5.87266 22.6547V22.7547H5.97266H7.35333H7.45333V22.6547V20.4213L8.19033 19.5512L10.3899 22.7118L10.4197 22.7547H10.4719H12.1317H12.325L12.2133 22.597L9.28504 18.4592L12.1814 15.0984L12.3239 14.9331H12.1057H10.4198H10.3736L10.3436 14.9684L7.45333 18.3723V15.0331V14.9331H7.35333H5.97266H5.87266V15.0331V22.6547Z"
        fill="black"
        stroke="black"
        strokeWidth="0.2"
      />
    </svg>
  );
}
