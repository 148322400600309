import React from "react";

export default function ShapeIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.00016 14.6666H10.0002C13.3335 14.6666 14.6668 13.3333 14.6668 9.99992V5.99992C14.6668 2.66659 13.3335 1.33325 10.0002 1.33325H6.00016C2.66683 1.33325 1.3335 2.66659 1.3335 5.99992V9.99992C1.3335 13.3333 2.66683 14.6666 6.00016 14.6666Z"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M12 4L4 12" stroke="#959595" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.0002 6.66667V4H9.3335" stroke="#959595" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4 9.33325V11.9999H6.66667" stroke="#959595" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
