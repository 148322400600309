import { Checkbox, Dropdown, MenuProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonComponents from "..";
import DefaultImg from "../../../assets/images/default.png";
import DeleteSvg from "../../../assets/svg/delete.svg";
import EyeInvisibleSvg from "../../../assets/svg/eye-invisible.svg";
import EyeSvg from "../../../assets/svg/eye.svg";
import InfoSvg from "../../../assets/svg/info.svg";
import MoreSvg from "../../../assets/svg/more.svg";
import {
  capitalizeFirstLetter,
  ConvertTimeStamp,
} from "../../../helpers/common";
import {
  changeFlagShowInfoImageInCart,
  changeLeftDrawerType,
} from "../../../store/reducers/drawerSlice";
import {
  changeSelectedImage,
  searchAsync,
} from "../../../store/reducers/mapTaskingSlice";
import { RootState } from "../../../store/store";

const CardItemInCartComp = (props: any) => {
  const dispatch = useDispatch();
  const [_checked, setChecked] = useState<boolean>(
    props?.defaultChecked || props?.checked
  );

  let selected = useSelector((state: RootState) => state.mapTasking.selected);

  useEffect(() => {
    if (props?.defaultChecked === undefined) {
      setChecked(props?.checked);
    }
  }, [props?.checked]);

  const onChange = (e: CheckboxChangeEvent) => {
    setChecked(e.target.checked);

    props?.onSelect(e.target.checked);
  };

  let a = props?.data?.metadata?.filter(
    (item: any) => item?.key === "acquired"
  );

  const actions: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <span
          className="flex gap-2"
          onClick={async () => {
            // @ts-ignore
            let res = await dispatch(
              // @ts-ignore
              searchAsync({
                id: props?.data?.feature?.properties[
                  `${props?.data?.provider_name?.toLowerCase()}:id`
                ],
              })
            ).unwrap();

            dispatch(
              changeSelectedImage(
                res?.status === 200
                  ? res?.data?.data?.features?.[0]
                  : props?.data
              )
            );
            dispatch(changeLeftDrawerType("info"));
            dispatch(changeFlagShowInfoImageInCart(true));
          }}
        >
          <img src={InfoSvg} />
          Information
        </span>
      ),
    },

    {
      key: "3",
      label: (
        <span className="flex gap-2" onClick={() => props?.deleteImage()}>
          <img src={DeleteSvg} />
          Detete
        </span>
      ),
    },
  ];

  const renderStatus = () => {
    let a = false;
    let index = selected?.findIndex((x: any) => x.id === props?.data?.id);
    if (index > -1) {
      a = true;
    }
    return a;
  };
  return (
    <>
      <div
        onMouseLeave={() => {
          props?.onMouseLeaveItem && props?.onMouseLeaveItem();
        }}
        onMouseEnter={() => {
          props?.onMouseEnterItem && props?.onMouseEnterItem();
        }}
        className={`w-full flex items-center p-3 bg-[#232735] hover:bg-[#3E4353] gap-3 !h-[100px] !rounded-[3px] ${props?.className}`}
      >
        {!props.noCheckbox && (
          <div className="flex-none w-fit">
            <Checkbox checked={_checked} onChange={onChange} />
          </div>
        )}

        <div className="grow flex items-center gap-3">
          <div
            className="flex-none !w-[72px]  bg-black rounded relative"
            style={{ border: "1px solid #FFFFFF33" }}
          >
            {!props.noEye && (
              <div
                style={{
                  border: "1px solid #000000",
                  background: "#0000009B",
                  left: "2px",
                  top: "3px",
                }}
                className="rounded p-[2px] absolute cursor-pointer"
                onClick={async (e) => {
                  props.onShowInMap(e, props?.data ?? {});
                }}
              >
                <img src={renderStatus() ? EyeSvg : EyeInvisibleSvg} />
              </div>
            )}

            <img
              src={
                props?.data?.feature?.links?.filter(
                  (itemF: any) => itemF?.rel === "thumbnail"
                )?.[0].href
              }
              alt=""
              className="!w-[72px] !h-[72px] object-contain"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = DefaultImg;
              }}
              loading="lazy"
            />
          </div>

          <div className="grow h-full flex flex-col">
            <div className="flex justify-between items-center cursor-pointer">
              <div
                className={` rounded-sm h-[20px]  px-1 py-[2px] flex flex-col justify-center items-center`}
                style={{
                  color: "white",
                  fontSize: "12px",
                  background: props?.data?.color_provider_name ?? "#004E72",
                }}
              >
                {capitalizeFirstLetter(props?.data?.provider_name)}
              </div>
              {/* @ts-ignore */}
              {!props.noMore && (
                <Dropdown menu={{ items: actions }} placement="bottomLeft">
                  <img src={MoreSvg} />
                </Dropdown>
              )}
            </div>

            <div className="text-base font-semibold text-common-white break-words break-all !mb-1 line-clamp-1">
              {props?.data?.feature?.id ?? props?.data?.name}
            </div>

            <div className="flex justify-between items-center text-sm font-normal text-common-white">
              <div className="text-one-line">
                {a?.[0]?.value && (
                  <>
                    <CommonComponents.TooltipUI
                      title={ConvertTimeStamp(a?.[0]?.value)}
                    >
                      {ConvertTimeStamp(a?.[0]?.value)}
                    </CommonComponents.TooltipUI>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardItemInCartComp;
