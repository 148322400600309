import React from "react";

export default function CheckIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3125 7.4375L4.79904 10.7021C5.19775 11.0754 5.82226 11.0597 6.20175 10.6669L13.125 3.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
