import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IDrawerState {
  showDrawer: boolean;
  leftDrawerType?: string;
  flagShowInfoImageInCart?: boolean;
  showBottomDrawer?: boolean;
  bottomDrawerType?: string;
  leftDrawerTypeBefore?: string;
}

const initialState: IDrawerState = {
  showDrawer: false,
  leftDrawerType: "",
  flagShowInfoImageInCart: false, // check when show info image in cart, close drawer ShowInfoImageInCart open drawer cart,
  showBottomDrawer: false,
  bottomDrawerType: "",
  leftDrawerTypeBefore: "",
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    changeShowDrawer: (state, action: PayloadAction<boolean>) => {
      state.showDrawer = action.payload;
    },

    changeLeftDrawerType: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.leftDrawerType = action.payload;
    },
    changeLeftDrawerTypeBefore: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.leftDrawerTypeBefore = action.payload;
    },

    changeFlagShowInfoImageInCart: (state, action: PayloadAction<boolean>) => {
      state.flagShowInfoImageInCart = action.payload;
    },

    changeBottomDrawerType: (state, action: PayloadAction<string>) => {
      state.bottomDrawerType = action.payload;
    },

    changeShowBottomDrawer: (state, action: PayloadAction<boolean>) => {
      state.showBottomDrawer = action.payload;
    },

    resetLeftDrawer: (state) => {
      state.showDrawer = false;
      state.leftDrawerType = "";
    },

    resetBottomDrawer: (state) => {
      state.showBottomDrawer = false;
      state.bottomDrawerType = "";
    },

    resetAllDrawer: (state) => {
      state.showDrawer = false;
      state.leftDrawerType = "";

      state.showBottomDrawer = false;
      state.bottomDrawerType = "";

      state.leftDrawerTypeBefore = "";
    },
  },
});

export const {
  changeShowDrawer,
  changeLeftDrawerType,
  changeFlagShowInfoImageInCart,
  changeBottomDrawerType,
  changeShowBottomDrawer,
  resetLeftDrawer,
  resetAllDrawer,
  resetBottomDrawer,
  changeLeftDrawerTypeBefore,
} = drawerSlice.actions;

export default drawerSlice.reducer;
