import { Divider, Form, Slider } from "antd";
import CommonIcons from "../../assets/icons";
import ApplySvg from "../../assets/svg/apply.svg";
import ButtonIconComp from "./components/ButtonIcon";

import { RangePickerProps } from "antd/es/date-picker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import CloseSvg from "../../assets/svg/dismiss.svg";
import { DefaultFormatDateTime, ETypeImage } from "../../enums";
import {
  changeLeftDrawerType,
  changeShowDrawer,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import {
  setCommonFilter,
  setFlagSearch,
} from "../../store/reducers/mapTaskingSlice";
import {
  activeLoadingProvider,
  setDataImages,
  setFirstLink,
  setIsSearching,
  setNextLink,
  setPage,
} from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";

const DrawerFilterSearchComp = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  let commonFilter = useSelector(
    (state: RootState) => state.mapTasking.commonFilter
  );
  const typeImage = useSelector(
    (state: RootState) => state.mapTasking.typeImage
  );

  let isSearching = useSelector((state: RootState) => state.search.isSearching);
  const handleApply = () => {
    let a = {
      sources: {},
      resolution: form.getFieldValue("resolution"),
      incidenceAngle: form.getFieldValue("incidenceAngle"),
      cloudCover:
        typeImage !== ETypeImage.SAR ? form.getFieldValue("cloudCover") : null,
      timeFrom: form.getFieldValue("timeFrom")
        ? form.getFieldValue("timeFrom").valueOf()
        : undefined,
      timeTo: form.getFieldValue("timeTo")
        ? form.getFieldValue("timeTo").valueOf()
        : undefined,
    };

    dispatch(setCommonFilter(a));
    dispatch(setFlagSearch(true));
    dispatch(changeShowDrawer(true));
    dispatch(changeLeftDrawerType("Search results"));
    dispatch(setIsSearching(true));
    dispatch(activeLoadingProvider());
    dispatch(setDataImages([]));
    dispatch(setPage(1));
    dispatch(setNextLink(undefined));
    dispatch(setFirstLink(undefined));
  };
  const renderHeaderDrawer = () => {
    return (
      <div className="flex justify-between items-center py-1">
        <div className="flex items-center gap-3 text-sm font-normal text-common-white">
          <div className="scale-125">
            <CommonIcons.FilterIcon />
          </div>
        </div>

        <div className="flex items-center gap-2">
          <ButtonIconComp
            item={{
              description: "View all in map",
              icon: ApplySvg,
              title: "Apply",
            }}
            className="!h-7 !px-3 !rounded !bg-[#272D3C]"
            onClick={handleApply}
          />

          <ButtonIconComp
            item={{
              description: "Close",
              icon: CloseSvg,
            }}
            onClick={() => {
              if (isSearching) {
                dispatch(changeLeftDrawerType("Search results"));
              } else {
                dispatch(resetLeftDrawer());
              }
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          />
        </div>
      </div>
    );
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return (
      current && current < dayjs(form.getFieldValue("timeFrom")).endOf("day")
    );
  };

  const renderForm = () => {
    return (
      <Form
        form={form}
        initialValues={{
          ...commonFilter,
          timeFrom:
            commonFilter.timeFrom && dayjs(commonFilter.timeFrom).isValid()
              ? dayjs(commonFilter.timeFrom)
              : undefined,
          timeTo:
            commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
              ? dayjs(commonFilter.timeTo)
              : undefined,
        }}
        layout="vertical"
      >
        <div className="w-full">
          <div className="text-sm text-common-white font-medium">Time</div>

          <div className="grid grid-cols-2 gap-3 p-3 w-full">
            <CommonComponents.FormFieldUI
              name="timeFrom"
              component={
                <CommonComponents.DatePickerUI
                  allowClear={false}
                  className="w-full"
                  defaultValue={
                    commonFilter.timeFrom &&
                    dayjs(commonFilter.timeFrom).isValid()
                      ? dayjs(commonFilter.timeFrom)
                      : undefined
                  }
                  value={
                    commonFilter.timeFrom &&
                    dayjs(commonFilter.timeFrom).isValid()
                      ? dayjs(commonFilter.timeFrom)
                      : undefined
                  }
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  placeholder="From"
                />
              }
            />

            <CommonComponents.FormFieldUI
              name="timeTo"
              component={
                <CommonComponents.DatePickerUI
                  allowClear={false}
                  className="w-full"
                  maxDate={dayjs()}
                  defaultValue={
                    commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
                      ? dayjs(commonFilter.timeTo)
                      : undefined
                  }
                  value={
                    commonFilter.timeTo && dayjs(commonFilter.timeTo).isValid()
                      ? dayjs(commonFilter.timeTo)
                      : undefined
                  }
                  placeholder="To"
                  format={DefaultFormatDateTime.DATE_FORMAT}
                  disabledDate={disabledDate}
                />
              }
            />
          </div>
        </div>
        {typeImage !== ETypeImage.SAR && (
          <div>
            <div className="text-sm text-common-white font-medium">
              Cloud cover [0-100] (%)
            </div>
            <div className="grid grid-cols-1 gap-3 p-3">
              <CommonComponents.FormFieldUI
                name="cloudCover"
                component={
                  <Slider
                    range
                    defaultValue={commonFilter.cloudCover}
                    value={commonFilter.cloudCover}
                  />
                }
              />
            </div>
          </div>
        )}

        <div>
          <div className="text-sm text-common-white font-medium">
            Resolution [0-40] (m)
          </div>
          <div className="grid grid-cols-1 gap-3 p-3">
            <CommonComponents.FormFieldUI
              name="resolution"
              component={
                <Slider
                  range
                  defaultValue={commonFilter.resolution}
                  value={commonFilter.resolution}
                  min={0}
                  max={40}
                />
              }
            />
          </div>
        </div>

        <div>
          <div className="text-sm text-common-white font-medium">
            Incidence angle [0-90](°)
          </div>
          <div className="grid grid-cols-1 gap-3 p-3">
            <CommonComponents.FormFieldUI
              name="incidenceAngle"
              component={
                <Slider
                  range
                  defaultValue={commonFilter.incidenceAngle}
                  value={commonFilter.incidenceAngle}
                  min={0}
                  max={90}
                />
              }
            />
          </div>
        </div>
      </Form>
    );
  };
  return (
    <div className="h-full p-3 pt-0 overflow-hidden !w-[460px]">
      <div className="w-full sticky top-0 z-10 bg-common-ink100 py-3 h-full">
        {renderHeaderDrawer()}

        <Divider className="bg-common-line my-2" />

        <div
          className="flex flex-col gap-2 mt-4 justify-between"
          style={{ height: `calc(100% - 36px)` }}
        >
          {renderForm()}
        </div>
      </div>
    </div>
  );
};

export default DrawerFilterSearchComp;
