import React from "react";

export default function ZoomIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-outside-1_15_12287" maskUnits="userSpaceOnUse" x="-0.078125" y="-0.0802307" width="6" height="6" fill="black">
        <rect fill="white" x="-0.078125" y="-0.0802307" width="6" height="6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.921875 0.919766V0.919769V1.99289V4.91977H1.995V1.99289L4.92187 1.99289V0.919766L0.921875 0.919766Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.921875 0.919766V0.919769V1.99289V4.91977H1.995V1.99289L4.92187 1.99289V0.919766L0.921875 0.919766Z"
        fill="white"
      />
      <path
        d="M0.921875 0.919766L0.921875 0.719766L0.721875 0.719766V0.919766H0.921875ZM0.921875 4.91977H0.721875V5.11977H0.921875V4.91977ZM1.995 4.91977V5.11977H2.195V4.91977H1.995ZM1.995 1.99289V1.79289H1.795V1.99289H1.995ZM4.92187 1.99289V2.19289H5.12187V1.99289H4.92187ZM4.92187 0.919766H5.12187V0.719766H4.92187V0.919766ZM1.12188 0.919769V0.919766H0.721875V0.919769H1.12188ZM1.12188 1.99289V0.919769H0.721875V1.99289H1.12188ZM1.12188 4.91977V1.99289H0.721875V4.91977H1.12188ZM1.995 4.71977H0.921875V5.11977H1.995V4.71977ZM1.795 1.99289V4.91977H2.195V1.99289H1.795ZM4.92187 1.79289L1.995 1.79289V2.19289L4.92187 2.19289V1.79289ZM4.72187 0.919766V1.99289H5.12187V0.919766H4.72187ZM0.921875 1.11977L4.92187 1.11977V0.719766L0.921875 0.719766L0.921875 1.11977Z"
        fill="white"
        mask="url(#path-1-outside-1_15_12287)"
      />
      <mask id="path-3-outside-2_15_12287" maskUnits="userSpaceOnUse" x="-0.078125" y="10.0072" width="6" height="6" fill="black">
        <rect fill="white" x="-0.078125" y="10.0072" width="6" height="6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.921875 15.0072V15.0072V13.9341V11.0072H1.995V13.9341L4.92187 13.9341V15.0072L0.921875 15.0072Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.921875 15.0072V15.0072V13.9341V11.0072H1.995V13.9341L4.92187 13.9341V15.0072L0.921875 15.0072Z"
        fill="white"
      />
      <path
        d="M0.921875 15.0072L0.921875 15.2072L0.721875 15.2072V15.0072H0.921875ZM0.921875 11.0072H0.721875V10.8072H0.921875V11.0072ZM1.995 11.0072V10.8072H2.195V11.0072H1.995ZM1.995 13.9341V14.1341H1.795V13.9341H1.995ZM4.92187 13.9341V13.7341H5.12187V13.9341H4.92187ZM4.92187 15.0072H5.12187V15.2072H4.92187V15.0072ZM1.12188 15.0072V15.0072H0.721875V15.0072H1.12188ZM1.12188 13.9341V15.0072H0.721875V13.9341H1.12188ZM1.12188 11.0072V13.9341H0.721875V11.0072H1.12188ZM1.995 11.2072H0.921875V10.8072H1.995V11.2072ZM1.795 13.9341V11.0072H2.195V13.9341H1.795ZM4.92187 14.1341L1.995 14.1341V13.7341L4.92187 13.7341V14.1341ZM4.72187 15.0072V13.9341H5.12187V15.0072H4.72187ZM0.921875 14.8072L4.92187 14.8072V15.2072L0.921875 15.2072L0.921875 14.8072Z"
        fill="white"
        mask="url(#path-3-outside-2_15_12287)"
      />
      <mask id="path-5-outside-3_15_12287" maskUnits="userSpaceOnUse" x="10.1914" y="-0.0802307" width="6" height="6" fill="black">
        <rect fill="white" x="10.1914" y="-0.0802307" width="6" height="6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1914 0.919766V0.919769V1.99289V4.91977H14.1183V1.99289L11.1914 1.99289V0.919766L15.1914 0.919766Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1914 0.919766V0.919769V1.99289V4.91977H14.1183V1.99289L11.1914 1.99289V0.919766L15.1914 0.919766Z"
        fill="white"
      />
      <path
        d="M15.1914 0.919766L15.1914 0.719766L15.3914 0.719766V0.919766H15.1914ZM15.1914 4.91977H15.3914V5.11977H15.1914V4.91977ZM14.1183 4.91977V5.11977H13.9183V4.91977H14.1183ZM14.1183 1.99289V1.79289H14.3183V1.99289H14.1183ZM11.1914 1.99289V2.19289H10.9914V1.99289H11.1914ZM11.1914 0.919766H10.9914V0.719766H11.1914V0.919766ZM14.9914 0.919769V0.919766H15.3914V0.919769H14.9914ZM14.9914 1.99289V0.919769H15.3914V1.99289H14.9914ZM14.9914 4.91977V1.99289H15.3914V4.91977H14.9914ZM14.1183 4.71977H15.1914V5.11977H14.1183V4.71977ZM14.3183 1.99289V4.91977H13.9183V1.99289H14.3183ZM11.1914 1.79289L14.1183 1.79289V2.19289L11.1914 2.19289V1.79289ZM11.3914 0.919766V1.99289H10.9914V0.919766H11.3914ZM15.1914 1.11977L11.1914 1.11977V0.719766L15.1914 0.719766L15.1914 1.11977Z"
        fill="white"
        mask="url(#path-5-outside-3_15_12287)"
      />
      <mask id="path-7-outside-4_15_12287" maskUnits="userSpaceOnUse" x="10.1914" y="10.0072" width="6" height="6" fill="black">
        <rect fill="white" x="10.1914" y="10.0072" width="6" height="6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.1914 15.0072V15.0072V13.9341V11.0072H14.1183V13.9341L11.1914 13.9341V15.0072L15.1914 15.0072Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1914 15.0072V15.0072V13.9341V11.0072H14.1183V13.9341L11.1914 13.9341V15.0072L15.1914 15.0072Z"
        fill="white"
      />
      <path
        d="M15.1914 15.0072L15.1914 15.2072L15.3914 15.2072V15.0072H15.1914ZM15.1914 11.0072H15.3914V10.8072H15.1914V11.0072ZM14.1183 11.0072V10.8072H13.9183V11.0072H14.1183ZM14.1183 13.9341V14.1341H14.3183V13.9341H14.1183ZM11.1914 13.9341V13.7341H10.9914V13.9341H11.1914ZM11.1914 15.0072H10.9914V15.2072H11.1914V15.0072ZM14.9914 15.0072V15.0072H15.3914V15.0072H14.9914ZM14.9914 13.9341V15.0072H15.3914V13.9341H14.9914ZM14.9914 11.0072V13.9341H15.3914V11.0072H14.9914ZM14.1183 11.2072H15.1914V10.8072H14.1183V11.2072ZM14.3183 13.9341V11.0072H13.9183V13.9341H14.3183ZM11.1914 14.1341L14.1183 14.1341V13.7341L11.1914 13.7341V14.1341ZM11.3914 15.0072V13.9341H10.9914V15.0072H11.3914ZM15.1914 14.8072L11.1914 14.8072V15.2072L15.1914 15.2072L15.1914 14.8072Z"
        fill="white"
        mask="url(#path-7-outside-4_15_12287)"
      />
    </svg>
  );
}
