import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Cell,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
const Report = () => {
  //! state
  const dispatch = useDispatch();
  //! function
  //! effect
  useEffect(() => {
    dispatch(changeLayoutName("/report"));
    dispatch(changeDataBreadcrumb([{ title: "Dashboard" }]));

    dispatch(changeShowDrawer(false));
    dispatch(changeLeftDrawerType(""));

    dispatch(changeShowBottomDrawer(false));
    dispatch(changeBottomDrawerType(""));
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(changeShowDrawer(false));
    };
  }, []);

  const data = [
    {
      name: "Tháng 1",
      success: 100,
      error: 10,
      reject: 5,
    },
    {
      name: "Tháng 2",
      success: 200,
      error: 68,
      reject: 22,
    },
    {
      name: "Tháng 3",
      success: 130,
      error: 10,
      reject: 20,
    },
    {
      name: "Tháng 4",
      success: 300,
      error: 8,
      reject: 30,
    },
    {
      name: "Tháng 5",
      success: 600,
      error: 100,
      reject: 40,
    },
    {
      name: "Tháng 6",
      success: 400,
      error: 80,
      reject: 11,
    },
    {
      name: "Tháng 7",
      success: 290,
      error: 40,
      reject: 21,
    },
  ];

  //! render

  return (
    <ContentLayoutManagement>
      <div className="px-6 py-10">
        {/* <div className="flex gap-3 justify-end mb-10">
          <CommonComponents.DatePickerUI placeholder="Start" />
          <CommonComponents.DatePickerUI placeholder="End" />
        </div> */}

        <div className="text-white text-lg mb-4">Search Results</div>
        <div className="flex gap-4 mb-20">
          <div className="bg-[#2e323e] px-4 py-2 rounded basis-1/4">
            <div className="text-[#9b9da3] mb-4" style={{ fontSize: "16px" }}>
              Planet
            </div>
            <div className="text-white">50</div>
          </div>

          <div className="bg-[#2e323e] px-4 py-2 rounded basis-1/4">
            <div className="text-[#9b9da3] mb-4" style={{ fontSize: "16px" }}>
              OneAtlas
            </div>
            <div className="text-white">10</div>
          </div>

          <div className="bg-[#2e323e] px-4 py-2 rounded basis-1/4">
            <div className="text-[#9b9da3] mb-4" style={{ fontSize: "16px" }}>
              Capella
            </div>
            <div className="text-white">20</div>
          </div>

          <div className="bg-[#2e323e] px-4 py-2 rounded basis-1/4">
            <div className="text-[#9b9da3] mb-4" style={{ fontSize: "16px" }}>
              Geohub
            </div>
            <div className="text-white">50</div>
          </div>
        </div>

        <div className="flex gap-5 mb-20">
          <div className="basis-2/3 ">
            <p className="text-white text-center">Thống kê chi tiêu</p>
            <div style={{ width: "100%", height: 400 }}>
              <ChartExpenditure />
            </div>
          </div>

          <div className="basis-1/3 ">
            <p className="text-white text-center">Thống kê dự án</p>
            <div style={{ width: "100%", height: 400 }}>
              <ChartProject />
            </div>
          </div>
        </div>

        {/* <div style={{ width: "100%", height: 500 }}>
          <p className="text-white text-center text-xl">
            Biểu đồ thống kê hình ảnh
          </p>

          <ResponsiveContainer>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis dataKey="name" stroke="#fff" />
              <YAxis orientation="left" stroke="#fff" />
              <Tooltip />
              <Legend />
              <Bar dataKey="success" fill={themeColor.green300} />
              <Bar dataKey="error" fill={themeColor.red400} />
              <Bar dataKey="reject" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div> */}
      </div>
    </ContentLayoutManagement>
  );
};

export default Report;

const ChartExpenditure = () => {
  const dataExpenditure = [
    {
      name: "Tháng 1",
      expenditure: 4000,
    },
    {
      name: "Tháng 2",
      expenditure: 3000,
    },
    {
      name: "Tháng 3",
      expenditure: 2000,
    },
    {
      name: "Tháng 4",
      expenditure: 2780,
    },
    {
      name: "Tháng 5",
      expenditure: 1890,
    },
    {
      name: "Tháng 6",
      expenditure: 2390,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={400}
        height={100}
        data={dataExpenditure}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="name" stroke="#fff" />
        <YAxis stroke="#fff" />
        <Tooltip />
        {/* <Legend /> */}

        <Line type="monotone" dataKey="expenditure" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
};

const ChartProject = () => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const data = [
    { name: "Success", value: 400 },
    { name: "Unfinished", value: 300 },
    { name: "Implemented", value: 300 },
  ];
  const COLORS = ["#00C49F", "#FF8042", "#FFBB28"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#999"
        >{`Project ${value}`}</text>
      </g>
    );
  };
  const onPieEnter = (_: any, index: any) => {
    setActiveIndex(index);
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          innerRadius={70}
          outerRadius={160}
          fill="#8884d8"
          dataKey="value"
          paddingAngle={2}
          onMouseEnter={onPieEnter}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};
