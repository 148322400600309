import { Card } from "antd";
import React from "react";
const { Meta } = Card;
const CardItemImageComp = (props: any) => {
  return (
    <Card
      className="card-default-item-image"
      cover={<img alt="example" src={props?.data?.imgSrc} />}
      actions={props?.actions}
    >
      <Meta
        className="card-default-body"
        title={props?.data?.title}
        description={props?.data?.des}
      />
      <div className="div-additional-info-card">{props?.additionalInfo}</div>
    </Card>
  );
};

export default CardItemImageComp;
