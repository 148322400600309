import React from "react";

export default function PolygonDrawIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3332 4.63332V11.3667C13.2265 11.3467 13.1132 11.3333 12.9998 11.3333C12.0798 11.3333 11.3332 12.08 11.3332 13C11.3332 13.1133 11.3465 13.2267 11.3665 13.3333H4.63317C4.65317 13.2267 4.6665 13.1133 4.6665 13C4.6665 12.08 3.91984 11.3333 2.99984 11.3333C2.8865 11.3333 2.77317 11.3467 2.6665 11.3667V4.63332C2.77317 4.65332 2.8865 4.66666 2.99984 4.66666C3.91984 4.66666 4.6665 3.91999 4.6665 2.99999C4.6665 2.88666 4.65317 2.77332 4.63317 2.66666H11.3665C11.3465 2.77332 11.3332 2.88666 11.3332 2.99999C11.3332 3.91999 12.0798 4.66666 12.9998 4.66666C13.1132 4.66666 13.2265 4.65332 13.3332 4.63332Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66683 3.00001C4.66683 3.92001 3.92016 4.66668 3.00016 4.66668C2.88683 4.66668 2.7735 4.65334 2.66683 4.63334C1.90683 4.48001 1.3335 3.80668 1.3335 3.00001C1.3335 2.08001 2.08016 1.33334 3.00016 1.33334C3.80683 1.33334 4.48016 1.90668 4.6335 2.66668C4.6535 2.77334 4.66683 2.88668 4.66683 3.00001Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 3.00001C14.6668 3.80668 14.0935 4.48001 13.3335 4.63334C13.2268 4.65334 13.1135 4.66668 13.0002 4.66668C12.0802 4.66668 11.3335 3.92001 11.3335 3.00001C11.3335 2.88668 11.3468 2.77334 11.3668 2.66668C11.5202 1.90668 12.1935 1.33334 13.0002 1.33334C13.9202 1.33334 14.6668 2.08001 14.6668 3.00001Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66683 13C4.66683 13.1133 4.6535 13.2267 4.6335 13.3333C4.48016 14.0933 3.80683 14.6667 3.00016 14.6667C2.08016 14.6667 1.3335 13.92 1.3335 13C1.3335 12.1933 1.90683 11.52 2.66683 11.3667C2.7735 11.3467 2.88683 11.3333 3.00016 11.3333C3.92016 11.3333 4.66683 12.08 4.66683 13Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 13C14.6668 13.92 13.9202 14.6667 13.0002 14.6667C12.1935 14.6667 11.5202 14.0933 11.3668 13.3333C11.3468 13.2267 11.3335 13.1133 11.3335 13C11.3335 12.08 12.0802 11.3333 13.0002 11.3333C13.1135 11.3333 13.2268 11.3467 13.3335 11.3667C14.0935 11.52 14.6668 12.1933 14.6668 13Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
