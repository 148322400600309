import NoDataSvg from "../../assets/svg/NoData.svg";

const NoData = () => {
  //! state
  //! function
  //! render
  return (
    <div className="text-center text-white">
      <div className="flex justify-center mt-40">
        <img src={NoDataSvg} alt="Nodata" />
      </div>
      <p className="text-2xl mt-6 font-medium">No data</p>
    </div>
  );
};

export default NoData;
