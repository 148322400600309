import { useDispatch } from "react-redux";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { useEffect } from "react";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import { resetLeftDrawer } from "../../../store/reducers/drawerSlice";
import { baseRoutes } from "../../../router/RouterPage";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Form } from "antd";
import CommonComponents from "../../../components/Commons";
import { displayNotification } from "../../../store/reducers/notificationSlice";
import { getErrorMsg } from "../../../helpers/common";
import projectServices from "../../../services/project/projectServices";
import { ESttausCode } from "../../../enums";
import useFormValidation from "../../../hooks/useFormValidation";
import useGetProjectID from "../../../services/project/hooks/useGetProjecId";
import dayjs from "dayjs";

interface IInitialValues {
  name: string;
  description: string;
  start_date: string | any;
  end_date: string | any;
}
const DetailProject = () => {
  //! state
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const rule = searchParams.get("rule");
  const [form] = Form.useForm();
  const { project } = useFormValidation();
  const naviagte = useNavigate();
  const { data: detailProject, isLoading: loadingDetailProject } =
    useGetProjectID(Number(id), !isNaN(Number(id)));

  const initialValues: IInitialValues = {
    name: detailProject?.data?.name ?? "",
    description: detailProject?.data?.description ?? "",
    start_date: detailProject?.data?.start_date
      ? dayjs(detailProject?.data?.start_date, "DD/MM/YYYY")
      : "",
    end_date: detailProject?.data?.end_date
      ? dayjs(detailProject?.data?.end_date, "DD/MM/YYYY")
      : "",
  };
  //! functions

  const handleSubmit = async (value: IInitialValues) => {
    try {
      let result;
      if (rule === "edit") {
        let dataPut: any = {
          ...value,
          start_date: dayjs(value.start_date).format("DD/MM/YYYY"),
          end_date: value.end_date
            ? dayjs(value.end_date).format("DD/MM/YYYY")
            : null,
        };

        result = await projectServices.PutProject(Number(id), dataPut);
      } else {
        let dataPost: any = {
          ...value,
          start_date: dayjs(value.start_date).format("DD/MM/YYYY"),
          end_date: value.end_date
            ? dayjs(value.end_date).format("DD/MM/YYYY")
            : null,
        };
        result = await projectServices.PostProject(dataPost);
      }
      if (
        result.status === ESttausCode.CREATED ||
        result.status === ESttausCode.OK
      ) {
        dispatch(
          displayNotification({
            type: "success",
            content: "Successfully",
          })
        );
        naviagte(baseRoutes.myProject.path);
      }
    } catch (error) {
      dispatch(
        displayNotification({
          type: "error",
          content: getErrorMsg(error),
        })
      );
    }
  };

  const handleCancel = () => {
    naviagte(baseRoutes.myProject.path);
  };

  //! effect
  useEffect(() => {
    dispatch(changeLayoutName("/manage-project-detail"));
    dispatch(
      changeDataBreadcrumb([
        { title: "My projects", path: baseRoutes.myProject.path },
        { title: isNaN(Number(id)) ? "create" : id },
      ])
    );
    dispatch(resetLeftDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [loadingDetailProject]);

  //! render
  return (
    <ContentLayoutManagement>
      <Form
        initialValues={initialValues}
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        className="div-form-small"
      >
        <CommonComponents.FormFieldUI
          name="name"
          rules={project.name()}
          component={
            <CommonComponents.InputUI
              required
              label="Project name"
              placeholder="Project name"
            />
          }
        />
        <div className="grid grid-cols-2 gap-2">
          <CommonComponents.FormFieldUI
            name="start_date"
            rules={project.start_date()}
            component={
              <CommonComponents.DatePickerUI
                required
                className="w-full"
                label="Start Date"
                placeholder="Start Date"
                format={"DD/MM/YYYY"}
              />
            }
          />

          <CommonComponents.FormFieldUI
            name="end_date"
            component={
              <CommonComponents.DatePickerUI
                className="w-full"
                label="End Date"
                placeholder="End Date"
                format={"DD/MM/YYYY"}
              />
            }
          />
        </div>
        <CommonComponents.FormFieldUI
          name="description"
          component={
            <CommonComponents.TextAreaUI
              label="Description"
              placeholder="Description project"
              rows={4}
            />
          }
        />

        <div>
          <div className="flex justify-end gap-2">
            <CommonComponents.ButtonUI
              commonType="default"
              className="btn-cancel hover:!bg-[#3E4353]"
              onClick={handleCancel}
            >
              Cancel
            </CommonComponents.ButtonUI>
            {(isNaN(Number(id)) || rule === "edit") && (
              <CommonComponents.ButtonUI htmlType="submit" className="h-[28px]">
                Save
              </CommonComponents.ButtonUI>
            )}
          </div>
        </div>
        {/* <CommonComponents.FormAntdDebugs /> */}
      </Form>
    </ContentLayoutManagement>
  );
};

export default DetailProject;
