import { Radio, Select, Space } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import CommonComponents from "../Commons";
import {
  getProductionAsync,
  getSegmentsByIdOrderAsync,
} from "../../store/reducers/orderSlice";

const StepTaskingOneAlats = (props: any) => {
  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const [valueSensor, setValueSensor] = useState("Pléiades");

  const [valuePlan, setValuePlan] = useState("");

  const [dataSegments, setDataSegments] = useState<any>([]);
  const [heightSelect, setHeightSelect] = useState<any>("32px");
  const [dataProduction, setDataProduction] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setValueSensor(props?.form.getFieldValue("sensor"));
  }, [props?.form.getFieldValue("sensor")]);

  useEffect(() => {
    setValuePlan(props?.form.getFieldValue("plan"));
  }, [props?.form.getFieldValue("plan")]);

  useEffect(() => {
    const acquisitionValue = props?.form.getFieldValue("acquisition");
    if (acquisitionValue) {
      console.log("abc");
      setHeightSelect("100px");
    } else {
      setHeightSelect("32px");
    }
  }, [props?.form]); // Giữ hook theo dõi form

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.acquisition) {
      setHeightSelect("100px");
    } else {
      setHeightSelect("32px");
    }
  };

  const getSegments = async () => {
    // @ts-ignore
    let res = await dispatch(getSegmentsByIdOrderAsync(id)).unwrap();

    setDataSegments(
      res?.data?.data?.progCapacities?.[0]?.progTypes?.[0]?.segments ?? []
    );
  };

  const getProduction = async () => {
    // @ts-ignore
    let res = await dispatch(getProductionAsync()).unwrap();

    setDataProduction(
      res?.data?.data?.availableOptions?.filter(
        (item: any) => item?.category === "production_option"
      ) ?? []
    );
  };

  useEffect(() => {
    getProduction();
  }, []);

  return (
    <div className={"mt-3 text-white" + (showOrderTasking ? " w-[40%]" : "")}>
      <div className="grid grid-cols-2 gap-x-4">
        {dataProduction?.map((item: any) => (
          <CommonComponents.FormFieldUI
            name={["production", item?.name]}
            label={item?.label}
            rules={[
              {
                required: item?.mandatory === "true" ? true : false,
                message: "Please select here!",
              },
            ]}
            initialValue={item?.defaultValue}
            component={
              <Select
                className={`w-full [&_div.ant-select-selector]:!border-[#585F77] [&_div.ant-select-selector]:!border-0 [&_div.ant-select-selector]:!border-b [&_div.ant-select-selector]:focus-within:!border-[#60CDFF] [&_div.ant-select-selector]:!rounded-t-sm [&_div.ant-select-selector]:!rounded-b-sm [&_div.ant-select-selector]:!shadow-none [&_span.ant-select-clear]:!bg-[#23293A] [&_span.ant-select-clear_span]:!text-common-white [&_span.ant-select-arrow_span]:!text-common-white`}
                style={{
                  borderRadius: "3px",
                }}
              >
                {item?.values?.map((itemM: any) => (
                  <Select.Option value={itemM?.id}>
                    {itemM?.label}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        ))}
      </div>
    </div>
  );
};

export default StepTaskingOneAlats;
