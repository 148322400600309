import React from "react";

export default function MapIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.52637 5.18667V11.6733C1.52637 12.94 2.42637 13.46 3.5197 12.8333L5.08637 11.94C5.42637 11.7467 5.99303 11.7267 6.34637 11.9067L9.84637 13.66C10.1997 13.8333 10.7664 13.82 11.1064 13.6267L13.993 11.9733C14.3597 11.76 14.6664 11.24 14.6664 10.8133V4.32667C14.6664 3.06 13.7664 2.54 12.673 3.16667L11.1064 4.06C10.7664 4.25334 10.1997 4.27334 9.84637 4.09334L6.34637 2.34667C5.99303 2.17334 5.42637 2.18667 5.08637 2.38L2.1997 4.03334C1.82637 4.24667 1.52637 4.76667 1.52637 5.18667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.70703 2.66669V11.3334" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4863 4.41333V13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
