import React, { ReactNode } from "react";
import { Button, Dropdown, DropDownProps } from "antd";
import CommonIcons from "../../../assets/icons";
import { buttonTheme } from "../../../themes/commonTheme";

interface IProps extends DropDownProps {
  children?: ReactNode;
  buttonlabel?: ReactNode;
  buttonClass?: string;
  style?: any;
}

export default function DropdownUI({
  children,
  trigger = ["click"],
  buttonlabel = " ",
  buttonClass = "!text-xs !h-7 !min-w-[87px] flex justify-between",
  style,
  ...res
}: IProps) {
  return (
    <Dropdown trigger={trigger} {...res}>
      {children ? (
        children
      ) : (
        <Button
          style={style}
          icon={<CommonIcons.ArrowDownIcon />}
          iconPosition="end"
          className={`${buttonTheme.className.standard} ${buttonClass}`}
        >
          {buttonlabel}
        </Button>
      )}
    </Dropdown>
  );
}
