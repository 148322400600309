import { Divider, Form } from "antd";
import React, { useEffect } from "react";
import Step1 from "./Step1";
import FormProvider from "./FormByProvider/FormProvider";
import CommonComponents from "../Commons";

const OrderTaskingForm = (props: any) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.formRef) {
      props.formRef.current = form; // Gán form vào ref
    }
  }, [form, props.formRef]);
  return (
    <Form
      form={form}
      initialValues={props?.initialValues}
      layout="vertical"
      onFinish={async (values) => {
        console.log(values, "1234");
        // props?.handleSubmitBookingImage(values);
      }}
    >
      <div
        className={"text-sm text-white mt-3 h-full flex justify-between gap-7"}
      >
        <Step1
          form={form}
          dataPriority={props?.dataPriority ?? []}
          valuesForm={props?.valuesForm}
          datProject={props?.datProject ?? []}
        />

        <Divider
          type="vertical"
          style={{ background: "#353B4F", margin: 0, height: "100%" }}
        />
        <FormProvider form={form} dataSchema={props?.dataSchema} />
      </div>
    </Form>
  );
};

export default OrderTaskingForm;
