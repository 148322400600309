import { message } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

const NotificationProvider = () => {
  const notification = useSelector(
    (state: RootState) => state.notificationSlice
  );

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (notification.content) {
      messageApi.open({
        type: notification.type,
        content: notification.content,
        className: "custom-class-message",
        style: {
          position: "absolute",
          left: "50%",
          marginTop: "90vh",
          transform: "translate(-50%, -50%)",
          //   backgroundColor: "red",
        },
      });
    }
  }, [notification]);
  return (
    <>
      <div> {contextHolder}</div>
    </>
  );
};

export default NotificationProvider;
