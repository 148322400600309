import { FormRule } from "antd";

const useFormValidation = () => {
  const empty = `is required`;
  return {
    project: {
      name(): FormRule[] {
        const fieldName = `Project name`;
        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
        ];
      },
      start_date(): FormRule[] {
        const fieldName = `Start date`;
        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
        ];
      },
    },

    provider: {
      name(): FormRule[] {
        const fieldName = `Provider name`;
        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
        ];
      },
      logo(): FormRule[] {
        const fieldName = `Provider logo`;
        return [
          {
            required: true,
            message: `${fieldName} ${empty}`,
          },
        ];
      },
    },
  };
};

export default useFormValidation;
