import CommonComponents from "../Commons";
import CommonIcons from "../../assets/icons";
import { InfoCircleOutlined } from "@ant-design/icons";
import useGetOrderID from "../../services/order/hooks/useGetOrderId";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import LoadingUI from "../Commons/Loading";
import CardItemInCartComp from "../Commons/Cart/CardItem";

interface IProps {
  hidden?: boolean;
  onClose?: () => void;
}
const InfoOrder = (props: IProps) => {
  //! state
  const { hidden, onClose } = props;
  let [searchParams] = useSearchParams();
  const id = searchParams.get("order");
  const {
    data: detailInfo,
    isLoading: loadingInfo,
    refetch: refetchInfo,
  } = useGetOrderID(Number(id), false);

  //! function
  const renderItemOrder = () => {
    if (loadingInfo) {
      return <LoadingUI />;
    }
    return detailInfo?.data?.orderDetails.map((item: any, index: any) => {
      return item.images.map((image: any, indexImage: any) => {
        return (
          <div className="my-2" key={indexImage}>
            <CardItemInCartComp
              data={image}
              noMore={true}
              noCheckbox={true}
              noEye={true}
            />
          </div>
        );
      });
    });
  };
  //! effect
  useEffect(() => {
    if (id && !hidden) {
      refetchInfo();
    }
  }, [id, hidden]);
  //! render
  return (
    <div
      className="h-[calc(100%-56px)] mt-[56px] px-[14px] w-[460px]"
      hidden={hidden}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-3">
          <div className="size-5 text-white">
            <InfoCircleOutlined style={{ fontSize: 20 }} />
          </div>

          <div className="text-white text-sm">Items in order</div>
        </div>

        <div className="flex items-center gap-1">
          <CommonComponents.ButtonUI
            icon={<CommonIcons.DismissIcon />}
            commonType="standard"
            className="!text-xs !h-7"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="!h-[calc(100%-56px)]" style={{ overflow: "auto" }}>
        {renderItemOrder()}
      </div>
    </div>
  );
};

export default InfoOrder;
