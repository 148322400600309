import MapboxDraw from "@mapbox/mapbox-gl-draw";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CoordinateDisplayType,
  DrawType,
  MouseCoordinateElementIds,
} from "../../enums";
import {
  displayMouseCoordinate,
  getMouseCoordinateDisplayType,
} from "../../helpers/map";
import DrawRectangle from "../../helpers/mapbox-gl-draw-rectangle-mode";
import {
  addFeature,
  changeActiveFeature,
  changeMaplibreGL,
  setDraw as setDrawStore,
  setSelected,
  setSourceData,
  updateFeature,
} from "../../store/reducers/mapTaskingSlice";
import { RootState } from "../../store/store";
import { LeftDrawerType } from "../../types/common";
import Footer from "../Map/Footer";
import Header from "../MapTasking/Header";
import {
  centroid,
  changeColor,
  initCartLayer,
  initHoverLayer,
  initItemsCollectionLayer,
  initTextlayer,
  resetDrawMode,
  updateFeaturesText,
} from "../Map/ultis";

import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowDrawer,
  resetAllDrawer,
} from "../../store/reducers/drawerSlice";

export default function MapTasking(props: any) {
  const dispatch = useDispatch();
  const mapContainer = useRef<any>();

  const { activeFeature, sourceData, selected, maplibreGL } = useSelector(
    (state: RootState) => state.mapTasking
  );

  let sourceId =
    useSelector((state: RootState) => state.mapTasking.sourceId) +
    "_confirm_tasking";

  let featuresDraw: any = useSelector(
    (state: RootState) => state.mapTasking.features
  );

  const [map, setMap] = useState<any>();
  const [draw, setDraw] = useState<MapboxDraw>();
  const [drawType, setDrawType] = useState<DrawType | undefined>();

  useEffect(() => {
    initMap();
    dispatch(resetAllDrawer());
    return () => {
      dispatch(resetAllDrawer());
    };
  }, []);

  useEffect(() => {
    if (draw !== undefined && maplibreGL === undefined) {
      dispatch(changeMaplibreGL(map));
    }
  }, [draw]);

  useEffect(() => {
    if (selected?.length > 0) {
      drawItems(selected);
    }
  }, [selected]);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (map && Object.keys(map).length && map.getSource(sourceId)) {
        // @ts-ignore
        map.getSource(sourceId).setData(sourceData);
      }
    }, 200);
  }, [sourceData]);

  useEffect(() => {
    if (
      activeFeature &&
      Object.keys(activeFeature).length &&
      draw &&
      activeFeature?.id
    ) {
      // Kiểm tra nếu không có đối tượng nào trong draw
      if (draw.getAll()?.features?.length === 0 && featuresDraw?.length) {
        let a: any = {
          type: "FeatureCollection",
          features: featuresDraw,
        };
        draw.add(a); // Thêm lại tất cả các đối tượng từ Redux
      }

      // Kiểm tra nếu activeFeature tồn tại trong draw
      const activeFeatureFromDraw = draw.get(activeFeature?.id);
      if (activeFeatureFromDraw) {
        // Thay đổi màu cho feature được chọn
        changeColor([activeFeatureFromDraw], "#FFFFFF33", "#EF6C00", draw);

        // Lọc các feature khác và thay đổi màu sắc
        let feat = featuresDraw?.filter(
          (item: any) => item?.id !== activeFeature?.id
        );
        changeColor(feat, "#FFFFFF33", "#000000", draw); // ko duoc chon
      }
    }

    // Cập nhật các feature trên bản đồ (nếu cần thiết)

    setTimeout(() => {
      updateFeaturesText(map, featuresDraw);
    }, 100);
  }, [featuresDraw, activeFeature, draw, map]);

  const initMap = () => {
    if (map) return;

    const _map: any = new maplibregl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [
              "https://mt1.google.com/vt/lyrs=s&hl=pl&&x={x}&y={y}&z={z}",
            ],
            tileSize: 256,
          },
        },
        glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        layers: [
          {
            id: "Google Satellite",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [110, 12],
      zoom: 4,
      attributionControl: false,
    });

    _map.setRenderWorldCopies(false);

    setTimeout(() => {
      // init items collection layer
      initItemsCollectionLayer(_map, sourceId, sourceData);

      initHoverLayer(_map, sourceId, sourceData);

      initTextlayer(_map, sourceId);

      initCartLayer(_map, sourceId, sourceData);
    }, 50);

    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

    const draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        polygon: false,
        point: false,
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangle,
        // simple_select: { ...MapboxDraw.modes.simple_select, onDrag() {} },
        // direct_select: { ...MapboxDraw.modes.direct_select, onDrag() {} },
      },
      boxSelect: true,

      styles: [
        {
          id: "gl-draw-polygon-color-picker",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["has", "user_portColor"],
          ],
          paint: {
            "fill-color": ["get", "user_portColor"],
            "fill-opacity": 0.9,
          },
        },
        {
          id: "gl-draw-polygon-stroke-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": ["get", "user_lineColor"],
            "line-width": 2,
          },
        },
        {
          // style for line when drawing
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        {
          // style for color outline point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#fff",
          },
        },
        {
          // style for color fill point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 3,
            "circle-color": "#fbb03b",
          },
        },
      ],
    });

    _map.addControl(draw, "top-left");

    _map.on("draw.create", updateArea);
    _map.on("draw.delete", updateArea);
    _map.on("draw.update", updateArea); // nos owr trong init map

    _map.on("styledata", function () {});

    _map.on("load", () => {});

    _map.on("mousemove", (e: any) => {
      const _currentCoordinateDisplayType = getMouseCoordinateDisplayType(
        MouseCoordinateElementIds.TYPE_ID
      );
      displayMouseCoordinate(
        MouseCoordinateElementIds.LNG_ID,
        MouseCoordinateElementIds.LAT_ID,
        CoordinateDisplayType.DD,
        _currentCoordinateDisplayType,
        {
          lng: e?.lngLat?.lng ?? 0,
          lat: e?.lngLat?.lat ?? 0,
        }
      );
    });

    _map.on("moveend", (e: any) => {});

    let hoveredStateId: any = null;

    // When the user moves their mouse over the items collection layer, we'll update the
    // feature state for the feature under the mouse.
    _map.on("mousemove", sourceId + "_fill_hover", (e: any) => {
      if (e.features.length > 0) {
        if (hoveredStateId) {
          _map.setFeatureState(
            { source: sourceId, id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        _map.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: true }
        );
      }
    });

    // When the mouse leaves the items collection layer, update the feature state of the
    // previously hovered feature.
    _map.on("mouseleave", sourceId + "_fill_hover", () => {
      if (hoveredStateId) {
        _map.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    });

    // Change the cursor to a pointer when the mouse is over the items collection layer.
    _map.on("mouseenter", sourceId + "_fill_hover", () => {
      _map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    _map.on("mouseleave", sourceId + "_fill_hover", () => {
      _map.getCanvas().style.cursor = "";
    });

    setDraw(draw);
    setMap(_map);

    dispatch(changeMaplibreGL(map));
    dispatch(setDrawStore(draw));
  };

  const updateArea = (e: any) => {
    if (maplibreGL === undefined) {
      dispatch(changeMaplibreGL(e.target));
    }
    switch (e["type"]) {
      case "draw.create":
        e.target.getSource(sourceId).setData({
          type: "FeatureCollection",
          features: [],
        });

        e.target
          .getSource(sourceId + "_hover")
          .setData({ type: "FeatureCollection", features: [] });

        dispatch(
          setSourceData({
            type: "FeatureCollection",
            features: [],
          })
        );

        dispatch(setSelected([]));

        dispatch(
          addFeature({
            ...e["features"][0],
          })
        );

        dispatch(
          changeActiveFeature({
            ...e["features"][0],
          })
        );

        resetDrawMode(e.target, draw);

        setDrawType(undefined);

        break;
      case "draw.update":
        dispatch(updateFeature(e["features"][0]));
        break;
      case "draw.delete":
        break;
    }
  };

  const drawItems = async (items: Array<any>) => {
    let data = { ...sourceData }; // copy object
    let centerPoint: any;

    if (items?.length > (data?.features.length ?? 0)) {
      for (let i = 0; i < items?.length; i++) {
        if (
          items[i] &&
          data?.features?.findIndex(
            (x: any) => x?.properties?.id === items[i]?.id
          ) < 0
        ) {
          let properties = items[i]?.properties
            ? { ...items[i]?.properties }
            : {};
          properties.id = properties?.id ? properties?.id : items[i]?.id;

          properties.collection = items[i]?.collection ?? "";
          data.features = [
            ...data?.features,
            {
              id: data?.features.length + 1,
              type: items[i]?.type ? items[i]?.type : "Feature",
              geometry: items[i]?.geometry,
              properties,
            },
          ];

          centerPoint = centroid(items[i]?.geometry);
        }
      }
    } else {
      if (data?.features) {
        data.features = data?.features.filter((x: any) =>
          items?.map((y: any) => y?.id).includes(x?.properties?.id)
        );
      }
    }

    dispatch(setSourceData(data));

    setTimeout(() => {
      // @ts-ignore
      if (Object.keys(map)?.length && map.getSource(sourceId)) {
        // @ts-ignore
        // map.getSource(sourceId).setData(data);
        if (centerPoint) {
          // @ts-ignore
          map.panTo(centerPoint);
        }
      }
    }, 200);
  };

  return (
    <div className="relative w-full h-full">
      <div ref={mapContainer} className="w-full h-full" />
    </div>
  );
}
