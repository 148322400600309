import React from "react";

export default function CubeFilledIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_269_4603)">
        <path
          d="M0 3.73956L7.65347 6.68729V15.6778L0 12.631V3.73956Z"
          fill="currentColor"
        />
        <path
          d="M8.32227 6.71144L15.9999 3.73956V12.78L8.32227 15.6778V6.71144Z"
          fill="currentColor"
        />
        <path
          d="M0.375 3.04426L7.87955 5.99199L15.434 3.04426L7.90451 0.0723877L0.375 3.04426Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_269_4603">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
