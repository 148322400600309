import MapboxDraw from "@mapbox/mapbox-gl-draw";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CoordinateDisplayType,
  DrawType,
  MouseCoordinateElementIds,
} from "../../enums";
import {
  displayMouseCoordinate,
  getMouseCoordinateDisplayType,
} from "../../helpers/map";
import DrawRectangle from "../../helpers/mapbox-gl-draw-rectangle-mode";
import {
  changeMaplibreGL,
  setDraw as setDrawStore,
} from "../../store/reducers/mapSlice";
import { RootState } from "../../store/store";
import {
  initCartLayer,
  initHoverLayer,
  initItemsCollectionLayer,
  initTextlayer,
  resetDrawMode,
} from "../Map/ultis";

export default function Map() {
  const dispatch = useDispatch();
  const mapContainer = useRef<any>();

  const { sourceId, sourceData, maplibreGL } = useSelector(
    (state: RootState) => state.mapSlice
  );

  const [map, setMap] = useState<any>();
  const [draw, setDraw] = useState<MapboxDraw>();
  useState<CoordinateDisplayType>(CoordinateDisplayType.DD);

  useEffect(() => {
    initMap();
  }, []);

  const initMap = () => {
    if (map) return;

    const _map: any = new maplibregl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: ["https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"],
            tileSize: 256,
          },
        },
        glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        layers: [
          {
            id: "Google Satellite",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [110, 12],
      zoom: 4,
      attributionControl: false,
    });

    _map.setRenderWorldCopies(false);

    setTimeout(() => {
      // init items collection layer
      initItemsCollectionLayer(_map, sourceId, sourceData);
    }, 50);

    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

    const draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        polygon: false,
        point: false,
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangle,
        // simple_select: { ...MapboxDraw.modes.simple_select, onDrag() {} },
        // direct_select: { ...MapboxDraw.modes.direct_select, onDrag() {} },
      },
      boxSelect: true,

      styles: [
        {
          id: "gl-draw-polygon-color-picker",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["has", "user_portColor"],
          ],
          paint: {
            "fill-color": ["get", "user_portColor"],
            "fill-opacity": 0.9,
          },
        },
        {
          id: "gl-draw-polygon-stroke-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": ["get", "user_lineColor"],
            "line-width": 2,
          },
        },
        {
          // style for line when drawing
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        {
          // style for color outline point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#fff",
          },
        },
        {
          // style for color fill point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 3,
            "circle-color": "#fbb03b",
          },
        },
      ],
    });

    _map.addControl(draw, "top-left");

    _map.getCanvas().style.cursor = "crosshair";

    _map.on("draw.create", updateArea);
    _map.on("draw.delete", updateArea);
    _map.on("draw.update", updateArea); // nos owr trong init map

    _map.on("styledata", function () {});

    _map.on("load", () => {});

    _map.on("mousemove", (e: any) => {
      const _currentCoordinateDisplayType = getMouseCoordinateDisplayType(
        MouseCoordinateElementIds.TYPE_ID
      );
      displayMouseCoordinate(
        MouseCoordinateElementIds.LNG_ID,
        MouseCoordinateElementIds.LAT_ID,
        CoordinateDisplayType.DD,
        _currentCoordinateDisplayType,
        {
          lng: e?.lngLat?.lng ?? 0,
          lat: e?.lngLat?.lat ?? 0,
        }
      );
    });

    _map.on("moveend", (e: any) => {});

    setDraw(draw);
    setMap(_map);

    //     dispatch(changeMaplibreGL(map));
    //     dispatch(setDrawStore(draw));
  };

  const updateArea = (e: any) => {
    //     if (maplibreGL === undefined) {
    //       dispatch(changeMaplibreGL(e.target));
    //     }=9=
    switch (e["type"]) {
      case "draw.create":
        resetDrawMode(e.target, draw);

        break;
      case "draw.update":
        break;
      case "draw.delete":
        break;
    }
  };

  return <div ref={mapContainer} className="w-[440px] h-[400px]" />;
}
