import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";

const ButtonIconComp = (props: any) => {
  const [buttonClass, setButtonClass] = useState(props.className);

  useEffect(() => {
    setButtonClass(props.className);
  }, [props.className]);
  return (
    <Tooltip title={props?.item?.description} arrow={false}>
      <div
        className={
          "btn-action-item " +
          (buttonClass ? buttonClass : "") +
          (!props?.item?.title ? " div-one-center" : "") +
          (props?.disabled ? " btn-disabled" : "")
        }
        onClick={(e) =>
          props?.onClick && !props?.disabled ? props?.onClick(e) : {}
        }
        style={props?.style}
      >
        <span className="flex gap-2">
          {props?.item?.icon && (
            <img src={props?.item?.icon} className={props?.classNameImage} />
          )}
          {props?.item?.iconNode && props?.item?.iconNode}
          {props?.item?.title && (
            <span style={{ color: "white", fontSize: "12px" }}>
              {props?.item?.title}
            </span>
          )}
        </span>
      </div>
    </Tooltip>
  );
};

export default ButtonIconComp;
