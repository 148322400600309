import React from "react";

export default function CloudIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.84702 8.67333C4.49368 8.49333 4.10035 8.4 3.70035 8.4C0.580351 8.62 0.580351 13.16 3.70035 13.38H11.0937C11.9937 13.3867 12.8604 13.0533 13.5204 12.4467C15.7137 10.5333 14.5404 6.68667 11.6537 6.32C10.6137 0.073334 1.59368 2.44667 3.73368 8.4"
        stroke="currentColor"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5664 6.61336C10.9131 6.44003 11.2931 6.34669 11.6797 6.34003"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
