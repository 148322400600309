import React, { useEffect } from "react";
import MyDataComp from "../../components/ManageData/MyData/MyData";
import { useDispatch } from "react-redux";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";
import { changeShowDrawerInfoImages } from "../../store/reducers/manageDataSlice";

const MyDataPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayoutName("manage-data/my-images"));
    dispatch(
      changeDataBreadcrumb([
        { title: "Quản lý dữ liệu" },
        {
          title: "Ảnh của tôi",
        },
      ])
    );

    dispatch(changeLeftDrawerType(""));
    dispatch(changeShowDrawer(false));

    dispatch(changeShowBottomDrawer(false));
    dispatch(changeBottomDrawerType(""));
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(changeShowDrawer(false));
      dispatch(changeLeftDrawerType(""));
      dispatch(changeShowDrawerInfoImages(false));
    };
  }, []);
  return <MyDataComp />;
};

export default MyDataPage;
