import httpService from "../httpService";
import { apis } from "../../constants/apis";
import { ISearchImageFilter } from "./searchImageFilterInterface";
import { IResponse } from "../../types/common";

class SearchImageService {
  search(filter: ISearchImageFilter) {
    return httpService.post<IResponse<any>>(
      `${apis.searchImage.search}`,
      filter
    );
  }
}

export default new SearchImageService();

export async function getHistoryByUser(payload: any) {
  return httpService.get(`${apis.searchImage.getHistoryByUser}`, {
    params: payload,
  });
}
