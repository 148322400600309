import React from "react";

export default function PlatformIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6668 5.99992C12.6668 6.96659 12.3802 7.85326 11.8868 8.59326C11.1668 9.65993 10.0268 10.4133 8.70016 10.6066C8.4735 10.6466 8.24016 10.6666 8.00016 10.6666C7.76016 10.6666 7.52683 10.6466 7.30016 10.6066C5.9735 10.4133 4.8335 9.65993 4.1135 8.59326C3.62016 7.85326 3.3335 6.96659 3.3335 5.99992C3.3335 3.41992 5.42016 1.33325 8.00016 1.33325C10.5802 1.33325 12.6668 3.41992 12.6668 5.99992Z"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1669 12.3134L13.0669 12.5734C12.8202 12.6334 12.6269 12.8201 12.5735 13.0668L12.3402 14.0468C12.2135 14.5801 11.5335 14.7401 11.1802 14.3201L8.00022 10.6668L4.82022 14.3268C4.46688 14.7468 3.78688 14.5868 3.66022 14.0534L3.42688 13.0734C3.36688 12.8268 3.17355 12.6334 2.93355 12.5801L1.83355 12.3201C1.32688 12.2001 1.14688 11.5668 1.51355 11.2001L4.11355 8.6001C4.83355 9.66676 5.97355 10.4201 7.30022 10.6134C7.52688 10.6534 7.76022 10.6734 8.00022 10.6734C8.24022 10.6734 8.47355 10.6534 8.70022 10.6134C10.0269 10.4201 11.1669 9.66676 11.8869 8.6001L14.4869 11.2001C14.8535 11.5601 14.6735 12.1934 14.1669 12.3134Z"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.38681 3.98675L8.78014 4.77341C8.83348 4.88008 8.97348 4.98675 9.10014 5.00675L9.81348 5.12674C10.2668 5.20008 10.3735 5.53341 10.0468 5.86008L9.49348 6.4134C9.40014 6.50674 9.34681 6.68675 9.38014 6.82008L9.54014 7.50675C9.66681 8.04675 9.38014 8.26007 8.90014 7.9734L8.23348 7.58007C8.11348 7.50674 7.91348 7.50674 7.79348 7.58007L7.12681 7.9734C6.64681 8.2534 6.36014 8.04675 6.48681 7.50675L6.64681 6.82008C6.67348 6.69341 6.62681 6.50674 6.53348 6.4134L5.98014 5.86008C5.65348 5.53341 5.76014 5.20674 6.21348 5.12674L6.92681 5.00675C7.04681 4.98675 7.18681 4.88008 7.24014 4.77341L7.63348 3.98675C7.82681 3.56008 8.17348 3.56008 8.38681 3.98675Z"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
