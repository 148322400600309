import React from "react";
import Page404 from "../components/404Page";
import { ERoleAccessControl } from "../enums";
import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";

interface IProps {
  children?: React.ReactNode;
  accessPathUser?: boolean;
}
const RoleAccessControlProviderUser = (props: IProps) => {
  //! state
  const { children, accessPathUser } = props;
  const roleAdmin = CheckRoleAccessControlAdmin();
  //!function

  if (!!accessPathUser) {
    return <>{children}</>;
  }

  if (roleAdmin?.includes(ERoleAccessControl.user)) {
    return <Page404 />;
  }

  //! render
  return <>{children}</>;
};

export default RoleAccessControlProviderUser;
