import React, { ReactNode } from "react";
import { infoTheme } from "../../../themes/commonTheme";

interface IProps {
  label?: ReactNode;
  value?: ReactNode;
  orientation?: "vertical" | "horizontal";
  rootClassName?: string;
  labelClassName?: string;
  valueClassName?: string;
}

export default function InfoItemUI({
  label,
  value,
  orientation = "horizontal",
  rootClassName,
  labelClassName = infoTheme.title.className.default,
  valueClassName = infoTheme.value.className.default,
}: IProps) {
  const _className = orientation === "vertical" ? "flex flex-col gap-1" : "flex items-center gap-1";

  return (
    <div className={`${_className}${rootClassName ? " " + rootClassName : ""}`}>
      <div className={labelClassName}>{label}</div>
      <div className={valueClassName}>{value}</div>
    </div>
  );
}
