import React from "react";

export default function InstrumentsIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.3465 13H4.99984C4.5865 13 4.21984 12.9867 3.89317 12.94C2.13984 12.7467 1.6665 11.92 1.6665 9.66667V6.33333C1.6665 4.08 2.13984 3.25333 3.89317 3.06C4.21984 3.01333 4.5865 3 4.99984 3H7.3065"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0132 3H10.9998C11.4132 3 11.7798 3.01333 12.1065 3.06C13.8599 3.25333 14.3332 4.08 14.3332 6.33333V9.66667C14.3332 11.92 13.8599 12.7467 12.1065 12.94C11.7798 12.9867 11.4132 13 10.9998 13H10.0132"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M10 1.33325V14.6666" stroke="#959595" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.3335 5.66675V10.3334" stroke="#959595" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
