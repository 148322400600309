import { ReactNode } from "react";
import MenuBar from "../components/MenuBar";

import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";

import LoadingUI from "../components/Commons/Loading";

export default function PageLayout({ children }: { children: ReactNode }) {
  const roleUsing = CheckRoleAccessControlAdmin();
  if (roleUsing && roleUsing?.length === 0) {
    return <LoadingUI />;
  }

  return (
    <div className="fixed w-[100vw] h-[100vh] top-0 left-0 flex">
      <div className="flex-none w-fit">
        <MenuBar roleUsing={roleUsing} />
      </div>

      <div className="grow relative">{children}</div>
    </div>
  );
}
