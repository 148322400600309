import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { getApiUrl } from "../helpers/common";
import { LocalStorageKey } from "../constants/localStorageKeys";
// import { LocalKey } from "@base/constants/LocalKey";
// import { apiAuthorize, apiUrl } from "@base/configs";
// import { clearLocalStorage } from "@base/helpers/common";
// import { IAuth } from "@base/modules/auth/auth.interface";
// import { baseRoutes } from "@base/routes/baseRoutes";

class Services {
  axios: AxiosInstance;
  constructor() {
    this.axios = axios.create();
    this.axios.defaults.withCredentials = true;

    //! Interceptor request
    // this.axios.interceptors.request.use(
    //   (config) => {
    //     const { accessToken } = this.getToken() || "";
    //     if (accessToken && config.url !== `${apiUrl}/auth/oauth/token`) {
    //       config.headers[`Authorization`] = `Bearer ${accessToken}`;
    //     } else {
    //       config.headers[`Authorization`] = apiAuthorize;
    //     }
    //     return config;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   }
    // );

    //! Interceptor response
    this.axios.interceptors.response.use(
      (config) => {
        return config;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error.response) {
          if (error.response.status === 401) {
            originalRequest._retry = true;

            let a = JSON.parse(
              localStorage.getItem(LocalStorageKey.STATIC_CONFIG) ?? ""
            );

            if (!a.REACT_APP_SKIP_REDIRECT) {
              window.location.href = a.REACT_APP_ENDPOINT_ACCOUNT;
            }

            // fetch("/config.json")
            //   .then((r) => r.json())
            //   .then((data) => {
            //     if (!data.REACT_APP_SKIP_REDIRECT) {
            //       window.location.href = data.REACT_APP_ENDPOINT_ACCOUNT;
            //     }
            //   });
            try {
            } catch (_error) {}
          }
        }

        return Promise.reject(error);
      }
    );
  }

  async get<T = any>(url: string, config?: AxiosRequestConfig) {
    const _url = await getApiUrl(url);
    return this.axios.get<T>(_url, config);
  }

  async post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    const _url = await getApiUrl(url);
    return this.axios.post<T>(_url, data, config);
  }

  async delete<T = any>(url: string, config?: AxiosRequestConfig) {
    const _url = await getApiUrl(url);
    return this.axios.delete<T>(_url, config);
  }

  async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    const _url = await getApiUrl(url);
    return this.axios.put<T>(_url, data, config);
  }

  async patch<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    const _url = await getApiUrl(url);
    return this.axios.patch<T>(_url, data, config);
  }

  // setToken(auth: IAuth) {
  //   localStorage.setItem(LocalKey.AUTH, JSON.stringify(auth));
  // }

  // getToken() {
  //   const authLocal = localStorage.getItem(LocalKey.AUTH);

  //   if (authLocal) {
  //     const auth = JSON.parse(authLocal) as IAuth;

  //     return { accessToken: auth.access_token, refreshToken: auth.refresh_token };
  //   }

  //   return { accessToken: undefined, refresh_token: undefined };
  // }

  // logout() {
  //   clearLocalStorage();

  //   if (location.pathname !== baseRoutes.login) {
  //     window.location.replace(baseRoutes.login);
  //   }
  // }
}

export default new Services();
