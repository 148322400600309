import React from "react";
import ContentLayout from "../../layouts/ContentLayout";
import CardManagementComp from "../Commons/CardManagement";
import MyDataSvg from "../../assets/svg/ManageData/icon-card-preview-my-data.svg";

import InfoSvg from "../../assets/svg/ManageData/info.svg";
import ShareSvg from "../../assets/svg/ManageData/share.svg";
import DownloadSvg from "../../assets/svg/ManageData/download.svg";
import CancelSvg from "../../assets/svg/ManageData/cancel.svg";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";

const MySharedDataComp = () => {
  const arrData = [
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT6_123458",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT6_123458",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT6_123458",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT6_123458",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT6_123458",
      des: "24/12/2023 17:24",
    },
  ];
  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {arrData?.map((item: any, ind: number) => (
          <CardManagementComp
            key={ind}
            data={item}
            actions={[
              <img alt="example" src={InfoSvg} />,
              <img alt="example" src={DownloadSvg} />,
            ]}
          />
        ))}
      </div>
    </ContentLayoutManagement>
  );
};

export default MySharedDataComp;
