import React from "react";

export default function FootIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2505 5.12194C10.6756 4.38054 9.74757 3.96347 8.63692 3.94772C7.67445 3.93401 6.64372 4.23034 5.87957 4.74045C3.97824 6.00976 3.94236 8.45124 3.92486 9.6243C3.8299 16.0716 10.2991 16.9101 9.76486 11.103C9.75139 10.9567 9.60725 10.8608 9.46591 10.9012C8.83207 11.0827 8.16321 11.1286 7.50292 11.0486C7.40396 11.0366 7.30902 11.0883 7.26449 11.1775C7.10422 11.4985 6.90187 11.7974 6.65889 12.0716C6.57264 12.1693 6.42471 12.1762 6.3296 12.0917C6.23306 12.0063 6.22431 11.8587 6.30977 11.7622C6.46645 11.5854 6.604 11.397 6.72206 11.198C6.79949 11.0674 6.73218 10.8997 6.5869 10.8558C6.22624 10.7467 5.87272 10.5998 5.53252 10.4159C5.41557 10.3526 5.37415 10.2047 5.44211 10.0904C5.50598 9.98333 5.64306 9.94484 5.75273 10.0043C5.81007 10.033 6.1061 10.2018 6.54983 10.3537C6.69768 10.407 6.86454 10.4498 6.9917 10.4827C7.79738 10.6738 8.54975 10.6553 9.21211 10.4864C12.0079 9.7769 12.4984 6.73261 11.2505 5.12194Z"
        fill="currentColor"
      />
      <path
        d="M8.41703 3.45122C9.01303 3.45122 9.49618 2.94195 9.49618 2.31374C9.49618 1.68553 9.01303 1.17627 8.41703 1.17627C7.82104 1.17627 7.33789 1.68553 7.33789 2.31374C7.33789 2.94195 7.82104 3.45122 8.41703 3.45122Z"
        fill="currentColor"
      />
      <path
        d="M4.6285 4.13967C4.95224 4.47157 4.9607 4.99539 4.64745 5.30717C4.32855 5.62479 3.80747 5.6012 3.48577 5.2713C3.16202 4.93939 3.15356 4.41557 3.46681 4.10379C3.78489 3.78696 4.30615 3.80913 4.6285 4.13967Z"
        fill="currentColor"
      />
      <path
        d="M12.7627 2.51882C12.7627 3.58213 11.8459 4.44518 10.9037 4.19616C10.1422 3.99345 9.70818 3.12197 9.93713 2.25312C10.0567 1.79871 10.342 1.41226 10.7197 1.19235C11.6773 0.636358 12.7627 1.34891 12.7627 2.51882Z"
        fill="currentColor"
      />
      <path
        d="M6.7539 2.60634C7.1109 3.28436 6.6562 4.08505 5.92558 4.08505C5.57142 4.08505 5.21466 3.88824 5.02435 3.52653C4.76127 3.02662 4.93423 2.41297 5.40993 2.15893C5.88504 1.90557 6.4873 2.10019 6.7539 2.60634Z"
        fill="currentColor"
      />
    </svg>
  );
}
