import React from "react";
import Input, { InputProps } from "antd/es/input/Input";

interface IProps extends InputProps {
  className?: string;
  label?: string;
  required?: boolean;
  classNameDiv?: string;
}

export default function InputUI({
  className,
  label,
  classNameDiv,
  required,
  ...res
}: any) {
  return (
    <div className={`${classNameDiv}`}>
      {label && (
        <p className="mb-1 text-white text-sm">
          {label}
          {required && <span className="text-common-red500">{` *`}</span>}
        </p>
      )}
      <Input
        {...res}
        className={`border-0 border-b rounded-[3px] !shadow-none${
          className ? " " + className : ""
        }`}
      />
    </div>
  );
}
