import React from "react";
import InfoSvg from "../../../assets/svg/ManageData/info.svg";
import DismissSvg from "../../../assets/svg/dismiss.svg";
import { Button, Divider } from "antd";
import { useDispatch } from "react-redux";
import { changeShowDrawer } from "../../../store/reducers/drawerSlice";
import DefaultImg from "../../../assets/svg/ManageData/default-img.png";
import PlatformSvg from "../../../assets/svg/ManageData/platform.svg";
import DatetimeSvg from "../../../assets/svg/ManageData/datetime.svg";
const DrawerInfoDataComp = () => {
  const dispatch = useDispatch();
  const renderHeader = () => {
    return (
      <div
        className="flex justify-between items-center pb-3"
        style={{ borderBottom: "1px solid #FFFFFF33" }}
      >
        <div className="flex gap-2" style={{ color: "white" }}>
          <img src={InfoSvg} /> Metadata
        </div>
        <div>
          <Button
            className="btn-action-icon"
            icon={<img src={DismissSvg} />}
            onClick={() => dispatch(changeShowDrawer(false))}
          />
        </div>
      </div>
    );
  };

  const renderInfo = (
    data: {
      svg?: any;
      title: string;
      value: string;
    }[]
  ) => {
    return data?.map(
      (item: { svg?: any; title: string; value: string }, ind: number) => (
        <div>
          <span className="text-des flex gap-1">
            <img src={item?.svg} />
            {item?.title}
          </span>
          <p className="ml-6" style={{ color: "white", fontSize: "14px" }}>
            {item?.value}
          </p>
        </div>
      )
    );
  };

  const renderBody = () => {
    return (
      <>
        <div className="relative rounded mb-2 bg-black !h-[200px] flex flex-col justify-center items-center mt-3 mb-4">
          <img
            className="w-full !h-[200px] !object-contain"
            src={DefaultImg}
            alt="layer"
            onError={(e: any) => {
              e.target.src = DefaultImg;
            }}
          />
        </div>
        {/* <div
          style={{ borderBottom: "1px solid #FFFFFF33", marginBottom: "12px" }}
        /> */}
        <p style={{ color: "#FFFFFFB2", fontSize: "14px" }}>
          CAPELLA_C10_SP_GEO_HH_20230327220355_20230327220423
        </p>

        <div className="grid grid-cols-2 gap-4 mt-3">
          {renderInfo([
            { svg: PlatformSvg, title: "Platform", value: "SPOT1" },
            { svg: DatetimeSvg, title: "Datetime", value: "24/09/2024 13:30" },
          ])}
        </div>

        <Divider />
      </>
    );
  };
  return (
    <div className="p-[14px]">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};

export default DrawerInfoDataComp;
