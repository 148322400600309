import { Collapse } from "antd";
import axios from "axios";
import { CollapseProps } from "rc-collapse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import CommonIcons from "../../../assets/icons";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import PolygonInfo from "../../../components/MapTasking/PolygonInfo";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  getDetailOrderTaskingByAdminAsync,
  getDetailOrderTaskingByUerAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { BuyerInfomationTaskingOrderAdmin } from "./[UpdateTaskingOrderAdmin]";
import { RenderHeader, renderInfoOrder } from "./ultis";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import { ERoleAccessControl } from "../../../enums";

const InfoTaskingOrderUserPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  let publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );

  let groups = useSelector((state: RootState) => state.appCommonSlice.groups);
  const roleAdmin = CheckRoleAccessControlAdmin();

  const [data, setData] = useState<any>({});

  const [itemsPolygonPoint, setItemsPolygonPoint] = useState<any>([]);
  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});

  const itemsInfoUser: CollapseProps["items"] = [
    {
      key: "1",
      label: "Buyer's information",
      children: <BuyerInfomationTaskingOrderAdmin whoami={dataUser} />,
    },
  ];

  const getData = async () => {
    const flagAdmin = roleAdmin?.includes(ERoleAccessControl.admin)
      ? true
      : false;

    let res = !flagAdmin
      ? // @ts-ignore
        await dispatch(getDetailOrderTaskingByUerAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getDetailOrderTaskingByAdminAsync(id)).unwrap();

    if (flagAdmin && res?.data?.data?.user_id !== "anonymousUser") {
      axios
        .get(
          `${publicConfig?.REACT_APP_API_KARATOS}/oa/admin/identities/${res?.data?.data?.user_id}`,
          {
            withCredentials: true,
          }
        )
        .then((data) => {
          setDataUser(data.data);
        });
    }

    setItemsInfoOder([
      {
        key: "1",
        label: "Order's Information",
        children: renderInfoOrder(res?.data?.data),
      },
    ]);

    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="!cursor-pointer"
              onClick={() => navigate("/order/management-tasking")}
            >
              Tasking Orders
            </span>
          ),
        },
        { title: res?.data?.data?.name },
      ])
    );

    let selectedPolygon = {
      id: uuidv4(),
      type: "Feature",
      geometry: res?.data?.data?.order_details?.[0]?.request_order?.geometry,
      properties:
        res?.data?.data?.order_details?.[0]?.request_order?.properties,
      name: "Polygon 01",
    };

    setItemsPolygonPoint([
      {
        key: "1",
        label: "Polygon points",
        children: <PolygonInfo selectedPolygon={selectedPolygon} />,
      },
    ]);

    setData(res?.data?.data ?? []);
  };

  useEffect(() => {
    getData();
  }, [id, groups]);

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-tasking/:id"));

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  console.log(
    data?.order_details?.[0]?.request_order,
    data,
    "data?.data?.data?.order_details?.[0]?.request_order"
  );

  return (
    <ContentLayoutManagement>
      <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
        <div className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2">
          {RenderHeader({ data: data })}

          {groups?.includes("admin") && (
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsInfoUser}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
          )}
          <div className="div-collapse-pol-point-cus">
            <Collapse
              bordered={false}
              expandIconPosition="end"
              items={itemsInfoOder}
              defaultActiveKey={["1"]}
              onChange={() => {}}
              className="!p-0"
              expandIcon={({ isActive }) => (
                <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                  <CommonIcons.ChevronUpIcon />
                </div>
              )}
            />
          </div>

          <div className="div-collapse-pol-point-cus">
            <Collapse
              bordered={false}
              expandIconPosition="end"
              items={itemsPolygonPoint}
              defaultActiveKey={["1"]}
              onChange={() => {}}
              className="!p-0"
              expandIcon={({ isActive }) => (
                <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                  <CommonIcons.ChevronUpIcon />
                </div>
              )}
            />
          </div>
        </div>
        <div className="w-1/2 ">
          <MapTaskingPlugin
            showOnConfirm={true}
            activePolygon={
              data?.order_details?.[0]?.request_order?.geometry ?? {}
            }
          />
        </div>
      </div>
    </ContentLayoutManagement>
  );
};

export default InfoTaskingOrderUserPage;
