import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteObject, useLocation, useRoutes } from "react-router-dom";
import Page404 from "../components/404Page";
import ManagementTasking from "../components/ManageOrder/ManagementTasking";
import BasemapSetting from "../pages/basemapSetting";
import SettingDataBaseMap from "../pages/basemapSetting/setting";
import HistoryUploadDataPage from "../pages/managedata/HistoryUploadData";
import MyDataPage from "../pages/managedata/MyData";
import MySharedDataPage from "../pages/managedata/MySharedData";
import MySharingDataPage from "../pages/managedata/MySharingData";
import UploadDataPage from "../pages/managedata/UploadData";
import ExploreOrderTaskingPage from "../pages/maptasking/ExploreOrderTasking";
import MyProjectPage from "../pages/myprojects";
import DetailProject from "../pages/myprojects/[id]";
import InfoArchiveOrderPage from "../pages/order/ArchiveOrder/[InfoArchiveOrderUser]";
import ArchiveOrderPage from "../pages/order/ConfirmArchiveOrder";
import TaskingOrderPage from "../pages/order/ConfirmTaskingOrder";
import ManagementOrderPage from "../pages/order/ManagementOrder";
import InfoTaskingOrderUserPage from "../pages/order/ManageTaskingOrder/[InfoTaskingOrderUser]";
import UpdateTaskingOrderAdminPage from "../pages/order/ManageTaskingOrder/[UpdateTaskingOrderAdmin]";
import UpdateArchiveOrderPage from "../pages/order/UpdateArchiveOrder";
import ProviderBranch from "../pages/providerBranch";
import ProviderBranchUpdate from "../pages/providerBranch/[id]";
import CreateProvider from "../pages/providerBranch/newProvider";
import Report from "../pages/report";
import RoleAccessControlProvider from "../providers/RoleAccessControlProvider";
import RoleAccessControlProviderUser from "../providers/RoleAccessControlProviderUser";
import SwitchLayout from "../providers/SwitchLayout";
import { setCurrentURLPath } from "../store/reducers/appCommonSlice";

export const baseRoutes = {
  map: { path: "/" },
  tasking: {
    explore: "/explore-tasking",
  },
  myProject: {
    path: "/my-projects",
  },
  myData: {
    path: "/manage-data",
    children: {
      images: { path: "/manage-data/my-images" },
      sharedWithMeImages: { path: "/manage-data/my-sharing-images" },
      sharedByMeImages: { path: "/manage-data/my-shared-images" },
      uploadedData: { path: "/manage-data/upload-data" },
      uploadedDataHistory: { path: "/manage-data/uploaded-data-history" },
    },
  },
  report: {
    path: "/report",
  },
  order: {
    path: "/order",
    children: {
      managementOrder: { path: "/order/management-order" },
      tasking: { path: "/order/management-tasking" },
      approvalOrder: { path: "/order/approval-order" },
      unapprovalOrder: { path: "/order/unapproval-order" },
    },
  },

  providerBranch: "/provider-branch",
  basemapSetting: "/basemapSetting",
};

export const routes: RouteObject[] = [
  {
    path: "archive-order",
    element: <ArchiveOrderPage />,
  },

  {
    path: "/",
    element: <SwitchLayout />,
  },

  {
    path: `${baseRoutes.providerBranch}`,

    children: [
      {
        index: true,
        element: (
          <RoleAccessControlProviderUser>
            <ProviderBranch />
          </RoleAccessControlProviderUser>
        ),
      },

      {
        path: `${baseRoutes.providerBranch}/new`,
        element: (
          <RoleAccessControlProviderUser>
            <CreateProvider />
          </RoleAccessControlProviderUser>
        ),
      },

      {
        path: `${baseRoutes.providerBranch}/:id`,
        element: (
          <RoleAccessControlProviderUser>
            <ProviderBranchUpdate />
          </RoleAccessControlProviderUser>
        ),
      },
    ],
  },
  {
    path: `${baseRoutes.basemapSetting}`,
    children: [
      {
        index: true,
        element: (
          <RoleAccessControlProviderUser>
            <BasemapSetting />
          </RoleAccessControlProviderUser>
        ),
      },
      {
        path: `${baseRoutes.basemapSetting}/setting`,
        element: (
          <RoleAccessControlProviderUser>
            <SettingDataBaseMap />
          </RoleAccessControlProviderUser>
        ),
      },
    ],
  },

  {
    path: "/explore-tasking",
    element: (
      <RoleAccessControlProviderUser accessPathUser>
        <ExploreOrderTaskingPage />
      </RoleAccessControlProviderUser>
    ),
  },

  {
    path: "/tasking-order",
    element: <TaskingOrderPage />,
  },

  {
    path: "order/management-tasking/:id",
    element: <InfoTaskingOrderUserPage />,
  },

  {
    path: "order/management-tasking/:id/update",
    element: (
      <RoleAccessControlProvider>
        <UpdateTaskingOrderAdminPage />
      </RoleAccessControlProvider>
    ),
  },

  {
    path: baseRoutes.map.path,
    element: null,
  },
  {
    path: baseRoutes.myProject.path,
    children: [
      {
        index: true,
        element: <MyProjectPage />,
        path: baseRoutes.myProject.path,
      },

      {
        element: <DetailProject />,
        path: `${baseRoutes.myProject.path}:id`,
      },
    ],
  },
  {
    path: baseRoutes.myData.path,
    children: [
      {
        index: true,
        path: baseRoutes.myData.children.images.path,
        element: <MyDataPage />,
      },
      {
        path: baseRoutes.myData.children.sharedWithMeImages.path,
        element: <MySharingDataPage />,
      },
      {
        path: baseRoutes.myData.children.sharedByMeImages.path,
        element: <MySharedDataPage />,
      },
      {
        path: baseRoutes.myData.children.uploadedData.path,
        element: <UploadDataPage />,
      },
      {
        path: baseRoutes.myData.children.uploadedDataHistory.path,
        element: <HistoryUploadDataPage />,
      },
    ],
  },

  {
    path: baseRoutes.order.children.managementOrder.path,
    // element: <RoleAccessControlProvider />,
    children: [
      {
        index: true,
        element: <ManagementOrderPage />,
      },
      {
        path: ":id",
        element: <InfoArchiveOrderPage />,
      },
      {
        path: ":id/update",
        element: (
          <RoleAccessControlProvider>
            <UpdateArchiveOrderPage />
          </RoleAccessControlProvider>
        ),
      },
    ],
  },

  {
    path: baseRoutes.order.children.tasking.path,
    element: <ManagementTasking />,
  },
  { path: baseRoutes.report.path, element: <Report /> },

  { path: "*", element: <Page404 /> },
];

export default function RouterPage() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentURLPath(location.pathname));
  }, [location.pathname]);

  return useRoutes(routes);
}
