import { apis } from "../../constants/apis";
import httpService from "../httpService";

class BaseMapService {
  GetbaseMap() {
    return httpService.get(apis.basemap.url);
  }
  PostBasemap(body: any) {
    return httpService.post(apis.basemap.url, body);
  }
}

export default new BaseMapService();
