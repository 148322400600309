import { Collapse, CollapseProps, Divider, Form } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../helpers/common";
import { RootState } from "../../store/store";

import CommonIcons from "../../assets/icons";
import { EProviderName } from "../../enums";
import MapTaskingPlugin from "../MapTasking/Map";
import { RenderInfoPolygon } from "../MapTasking/ultis";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { BuyerInfomation, OrderInfomation } from "./utils/BuyerInfomation";
import Step2 from "./Step2";

const ConfirmOrderComp = (props: any) => {
  const whoami = useSelector((state: RootState) => state.appCommonSlice.whoami);
  const selectedImages = useSelector(
    (state: RootState) => state.cartSlice.selectedImagesConfirm
  );

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const maplibreGLConfirmOrder = useSelector(
    (state: RootState) => state.mapTasking.maplibreGLConfirmOrder
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  const valueForm = useSelector(
    (state: RootState) => state.orderSlice.valuesForm
  );

  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  const [itemsImages, setItemsImages] = useState<any>([]);

  const itemsInfoOder: CollapseProps["items"] = [
    // archive order
    {
      key: "1",
      label: "Order's information",
      children: (
        <Step1
          form={props?.form}
          dataPriority={props?.data?.priority ?? []}
          datProject={props?.data?.project ?? []}
        />
      ),
    },
  ];

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Buyer's information",
      children: <BuyerInfomation whoami={whoami} />,
    },
  ];

  const itemsValueOrder: CollapseProps["items"] = [
    {
      key: "1",
      label: "Order's information",
      children: <OrderInfomation valueForm={valueForm} />,
    },
  ];

  const itemsPolygonPoint: CollapseProps["items"] = [
    {
      key: "1",
      label: "Polygon points",
      children: RenderInfoPolygon(selectedPolygon),
    },
  ];

  const renderProviderInSelectedImages = () => {
    const groupedByProvider = selectedImages.reduce((acc: any, image: any) => {
      const res = JSON.parse(JSON.stringify(acc));
      const providerName = image.provider_name;

      if (!res[providerName]) {
        res[providerName] = [];
      }

      res[providerName] = res[providerName].concat(image);

      return res;
    }, {});

    return groupedByProvider;
  };
  useEffect(() => {
    let a: any = [];

    if (selectedImages?.length > 0 && map) {
      Object.keys(renderProviderInSelectedImages()).map(
        (item: any, index: any) => {
          a = a.concat({
            key: index,
            label: (
              <span>
                {capitalizeFirstLetter(item)} (
                {renderProviderInSelectedImages()?.[item]?.length})
              </span>
            ),
            children: (
              <div className="w-full flex gap-3">
                <div className="w-full">
                  {item.toLowerCase() === "planet" ? (
                    <Step3
                      form={props?.form}
                      dataImages={renderProviderInSelectedImages()?.[item]}
                      map={map}
                      sourceId={sourceId}
                    />
                  ) : item.toLowerCase() === "oneatlas" ? (
                    <Step4
                      dataImages={renderProviderInSelectedImages()?.[item]}
                      total={renderProviderInSelectedImages()?.[item]?.length}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.oneatlas
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                      form={props?.form}
                    />
                  ) : (
                    <Step2
                      items={renderProviderInSelectedImages()?.[item]}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.capella
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                    />
                  )}
                </div>
              </div>
            ),
          });
        }
      );
    }

    setItemsImages(a);
  }, [selectedImages, maplibreGLConfirmOrder, map]);

  console.log(selectedImages, "selectedImages");

  return (
    // <div className="flex w-full gap-3 h-full overflow-hidden card-archive-order">
    <div className="flex !flex-row gap-3 h-full">
      <div className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2">
        <div className="div-collapse-pol-point-cus">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            items={items}
            // defaultActiveKey={["1"]}
            onChange={() => {}}
            className="!p-0"
            expandIcon={({ isActive }) => (
              <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                <CommonIcons.ChevronUpIcon />
              </div>
            )}
          />
        </div>

        {!showOrderTasking ? (
          <>
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsInfoOder}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
            <Divider style={{ background: "#353B4F", margin: "10px 0" }} />
            <p className="text-base mb-[10px] mt-1 font-semibold">
              Images in order
            </p>
            <div className="card-archive-order">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsImages}
                onChange={() => {}}
                defaultActiveKey={["0", "1", "2", "3"]}
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
          </>
        ) : (
          <>
            {/* for tasking */}
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsValueOrder}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsPolygonPoint}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
          </>
        )}
      </div>
      <div className="w-1/2 ">
        <MapTaskingPlugin
          showOnConfirm={true}
          activePolygon={showOrderTasking ? selectedPolygon?.geometry : {}}
        />
      </div>
    </div>
  );
};

export const renderInfoOrder = (props: any) => {
  return (
    <>
      <p
        className="flex justify-between"
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des">Order name:</span>
        <span className="text-right">{props?.data?.name}</span>
      </p>

      <p
        className="flex justify-between"
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des">Priority:</span>{" "}
        <span
          className="text-right"
          style={{ color: props?.data?.priority?.description }}
        >
          {props?.data?.priority?.name}
        </span>
      </p>

      {props?.data?.currentStatus?.value && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">Status:</span>{" "}
          <span
            className="text-right"
            style={{ color: props?.data?.currentStatus?.description }}
          >
            {props?.data?.currentStatus?.value}
          </span>
        </p>
      )}

      {props?.data?.project && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">Project:</span>{" "}
          <span className="text-right">
            {props?.data?.project?.name ?? props?.data?.project?.id}
          </span>
        </p>
      )}

      {props?.data?.description && (
        <p
          className="flex justify-between"
          style={{ fontSize: "14px", color: "white" }}
        >
          <span className="text-des">Description:</span>{" "}
          <span className="text-right">{props?.data?.description}</span>
        </p>
      )}
    </>
  );
};

export default ConfirmOrderComp;
