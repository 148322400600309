import React from "react";

export default function AccountMenuIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 8.00001C14.6668 4.32668 11.6735 1.33334 8.00016 1.33334C4.32683 1.33334 1.3335 4.32668 1.3335 8.00001C1.3335 9.93334 2.16683 11.6733 3.48683 12.8933C3.48683 12.9 3.48683 12.9 3.48016 12.9067C3.54683 12.9733 3.62683 13.0267 3.6935 13.0867C3.7335 13.12 3.76683 13.1533 3.80683 13.18C3.92683 13.28 4.06016 13.3733 4.18683 13.4667C4.2335 13.5 4.2735 13.5267 4.32016 13.56C4.44683 13.6467 4.58016 13.7267 4.72016 13.8C4.76683 13.8267 4.82016 13.86 4.86683 13.8867C5.00016 13.96 5.14016 14.0267 5.28683 14.0867C5.34016 14.1133 5.3935 14.14 5.44683 14.16C5.5935 14.22 5.74016 14.2733 5.88683 14.32C5.94016 14.34 5.9935 14.36 6.04683 14.3733C6.20683 14.42 6.36683 14.46 6.52683 14.5C6.5735 14.5133 6.62016 14.5267 6.6735 14.5333C6.86016 14.5733 7.04683 14.6 7.24016 14.62C7.26683 14.62 7.2935 14.6267 7.32016 14.6333C7.54683 14.6533 7.7735 14.6667 8.00016 14.6667C8.22683 14.6667 8.4535 14.6533 8.6735 14.6333C8.70016 14.6333 8.72683 14.6267 8.7535 14.62C8.94683 14.6 9.1335 14.5733 9.32016 14.5333C9.36683 14.5267 9.4135 14.5067 9.46683 14.5C9.62683 14.46 9.7935 14.4267 9.94683 14.3733C10.0002 14.3533 10.0535 14.3333 10.1068 14.32C10.2535 14.2667 10.4068 14.22 10.5468 14.16C10.6002 14.14 10.6535 14.1133 10.7068 14.0867C10.8468 14.0267 10.9868 13.96 11.1268 13.8867C11.1802 13.86 11.2268 13.8267 11.2735 13.8C11.4068 13.72 11.5402 13.6467 11.6735 13.56C11.7202 13.5333 11.7602 13.5 11.8068 13.4667C11.9402 13.3733 12.0668 13.28 12.1868 13.18C12.2268 13.1467 12.2602 13.1133 12.3002 13.0867C12.3735 13.0267 12.4468 12.9667 12.5135 12.9067C12.5135 12.9 12.5135 12.9 12.5068 12.8933C13.8335 11.6733 14.6668 9.93334 14.6668 8.00001ZM11.2935 11.3133C9.48683 10.1 6.52683 10.1 4.70683 11.3133C4.4135 11.5067 4.1735 11.7333 3.9735 11.98C2.96016 10.9533 2.3335 9.54668 2.3335 8.00001C2.3335 4.87334 4.8735 2.33334 8.00016 2.33334C11.1268 2.33334 13.6668 4.87334 13.6668 8.00001C13.6668 9.54668 13.0402 10.9533 12.0268 11.98C11.8335 11.7333 11.5868 11.5067 11.2935 11.3133Z"
        fill="currentColor"
      />
      <path
        d="M8 4.62C6.62 4.62 5.5 5.74 5.5 7.12C5.5 8.47333 6.56 9.57333 7.96667 9.61333C7.98667 9.61333 8.01333 9.61333 8.02667 9.61333C8.04 9.61333 8.06 9.61333 8.07333 9.61333C8.08 9.61333 8.08667 9.61333 8.08667 9.61333C9.43333 9.56666 10.4933 8.47333 10.5 7.12C10.5 5.74 9.38 4.62 8 4.62Z"
        fill="currentColor"
      />
    </svg>
  );
}
