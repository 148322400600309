import axios from "axios";
import { apis } from "../constants/apis";
import httpService from "./httpService";

export async function createOrder(payload: any) {
  return httpService.post(`-flow/api/v1/order`, payload);
}

export async function getPriority() {
  return httpService.get(`${apis.order.getPriority}`);
}

export async function createTaskingOrder(payload: any) {
  return httpService.post(`${apis.order.createTasking}`, payload);
}

export async function getArchiveOrderById(id?: any) {
  return httpService.get(`${apis.order.getArchiveOrderById}/${id}`);
}

export async function getProductTypeId(payload: any) {
  return httpService.get(`${apis.order.getProductTypeId}/${payload?.type}`);
}

export async function getDetailOrderTaskingByUer(id: string) {
  return httpService.get(
    `${apis.orderTasking.getDetailOrderTaskingByUer}/${id}`
  );
}

export async function getDetailOrderTaskingByAdmin(id: string) {
  return httpService.get(
    `${apis.orderTasking.getDetailOrderTaskingByAdmin}/${id}/by-admin`
  );
}

export async function getOrderByIdUser(id: string) {
  return httpService.get(`${apis.order.getOrderByIdUser}/${id}`);
}

export async function getOrderByIdAdmin(id: string) {
  return httpService.get(`${apis.order.getOrderByIdAdmin}/${id}/by-admin`);
}

export async function getSegmentsByIdOrder(payload: any) {
  return httpService.post(`${apis.order.getSegmentByIdOrder}`, payload);
}

export async function getProduction() {
  return httpService.get(`${apis.order.getProduction}`);
}

export async function confirmOrderTaskingAdmin(payload: any) {
  const { id, ...newPay } = payload;
  return httpService.put(
    `${apis.order.confirmOrderTaskingAdmin}/${payload?.id}`,
    newPay
  );
}

export async function getMetakeys(payload: any) {
  return httpService.get(`${apis.metaKey.default}`, payload);
}

export async function updateStatusTaskingOrder(payload: any) {
  let newPay = { note: payload?.note };
  return httpService.put(
    `${apis.orderTasking.updateStatus}/${payload?.id}/${payload?.statusId}`,
    newPay
  );
}

export async function approveArchiveOrder(payload: any) {
  const { id, ...newPay } = payload;

  return httpService.put(
    `${apis.order.approveArchiveOrder}/${payload?.id}`,
    newPay
  );
}

export async function getTaskingSchema(payload: any) {
  return httpService.get(
    `-flow/api/v1/schema/${payload?.idProvider}/${payload?.type}`
  );
}
