import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import EditSvg from "../../assets/svg/edit.svg";
import InfoSvg from "../../assets/svg/ManageData/info.svg";

import CommonComponents from "../../components/Commons";
import CardManagementComp from "../../components/Commons/CardManagement";
import { baseRoutes } from "../../router/RouterPage";
import useGetProviderConfigs from "../../services/providerConfigs/hooks/useGetProviderConfigs";
import LoadingUI from "../../components/Commons/Loading";
import NoData from "../../components/NoData";
import defaultImage from "../../assets/images/default.png";
export interface IFilterProvider {
  page?: number;
  size?: number;
  sort?: string;
}
const ListProviderBranch = () => {
  //! state
  const navigate = useNavigate();
  const [filter, setFilter] = useState<IFilterProvider>({
    page: 0,
    size: 10,
  });
  const { data, isLoading } = useGetProviderConfigs(filter);
  const providerConfigStore = data?.data;

  //! functionx
  const ParseData = useCallback(() => {
    if (
      providerConfigStore?.content_page &&
      providerConfigStore?.content_page?.length < 1
    ) {
      return [];
    }

    return providerConfigStore?.content_page?.map((item) => {
      const imageBranch =
        item?.metadatas && item?.metadatas.find((item) => item.key === "LOGO");
      return {
        id: item?.id,
        imgSrc: imageBranch?.value,
        title: item?.name,
        actived: item?.actived,
        des: item?.description,
        errorImage: defaultImage,
      };
    });
  }, [providerConfigStore]);

  const handleEdit = (id: number) => {
    navigate(`${baseRoutes.providerBranch}/${id}?rule=edit`);
  };

  const handleInfo = (id: number) => {
    navigate(`${baseRoutes.providerBranch}/${id}`);
  };

  //! render
  const renderProvider = useCallback(() => {
    if (isLoading) {
      return <LoadingUI />;
    }
    if (providerConfigStore?.content_page?.length === 0) {
      return <NoData />;
    }
    return ParseData()?.map((item, index) => {
      return (
        <CardManagementComp
          key={index}
          data={item}
          onClick={() => {
            // handleInfo(item.id);
          }}
          actions={[
            <CommonComponents.TooltipUI title="Update">
              <img
                alt="EditSvg"
                src={EditSvg}
                onClick={() => {
                  handleEdit(Number(item.id));
                }}
              />
            </CommonComponents.TooltipUI>,
            <CommonComponents.TooltipUI title="Infomation">
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  handleInfo(Number(item.id));
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
        />
      );
    });
  }, [providerConfigStore]);
  return <div className="p-6">{renderProvider()}</div>;
};

export default ListProviderBranch;
