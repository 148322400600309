import { apis } from "../../constants/apis";
import httpService from "../httpService";

class OrderService {
  GetOrderUser(payload: any) {
    return httpService.get(`${apis.order.userOrder}`, {
      params: payload,
    });
  }
  GetOrderAdmin(payload: any) {
    return httpService.get(`${apis.order.adminOrder}`, {
      params: payload,
    });
  }
  GetOrderId(id: number) {
    return httpService.get(`${apis.order.userOrder}/${id}`, {});
  }
  ChangeStatusOrder(idOrder: number, idStatus: number) {
    return httpService.put(`${apis.order.changeStatus}/${idOrder}/${idStatus}`);
  }
}

export default new OrderService();
