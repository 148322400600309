export const buttonTheme = {
  className: {
    default:
      "bg-common-primary500 !text-common-black hover:bg-common-primary400 hover:text-common-black disabled:!bg-common-ink400 disabled:!text-common-ink500 rounded py-[6px] px-3 text-sm font-normal border-none",
    standard:
      "bg-common-ink400 !text-common-white hover:!bg-[#272D3C] hover:!text-common-white disabled:!bg-[#333333] disabled:!text-common-ink500 rounded py-[6px] px-3 text-sm font-normal border-none",
    subtle:
      "bg-common-ink300 !text-common-white hover:!bg-[#3A3A3A] hover:!text-common-white disabled:!bg-common-ink300 disabled:!text-common-ink500 rounded py-[6px] px-3 text-sm font-normal border-none",
  },
};

export const infoTheme = {
  title: {
    className: {
      default: "text-sm text-common-white font-medium",
    },
  },
  value: {
    className: {
      default: "text-sm text-common-white text-opacity-70 font-medium",
    },
  },
};
