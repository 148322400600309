import { capitalizeFirstLetter } from "../../../helpers/common";

export const BuyerInfomation = ({
  whoami,
  valueForm,
}: {
  whoami?: any;
  valueForm?: any;
}) => {
  const data = {
    email: whoami?.identity?.traits?.email ?? "",
    phonenumber: whoami?.identity?.traits?.phone,
    fullName: `${whoami?.identity?.traits?.name?.last ?? ""} ${
      whoami?.identity?.traits?.name?.first ?? ""
    }`,
    address: whoami?.identity?.traits?.address ?? "",
    ...valueForm?.production,
  };
  const renderInfoBuyer = () => {
    return Object.entries(data).map(([key, value]: any) => (
      <p
        className="flex justify-between"
        key={key}
        style={{ fontSize: "14px", color: "white" }}
      >
        <span className="text-des !mr-1">{capitalizeFirstLetter(key)}: </span>
        <span className="text-right">{value ?? ""}</span>
      </p>
    ));
  };
  return <div className="!text-white">{renderInfoBuyer()}</div>;
};

export const OrderInfomation = ({ valueForm }: { valueForm?: any }) => {
  const data = {
    ...valueForm?.production,
  };

  const renderOrderInfomation = () => {
    return Object.entries(data).map(([key, value]: any) => (
      <p className="flex justify-between text-sm text-white" key={key}>
        <span className="text-des !mr-1">{capitalizeFirstLetter(key)}: </span>
        <span className="text-right">{value ?? ""}</span>
      </p>
    ));
  };

  return <div className="!text-white">{renderOrderInfomation()}</div>;
};
