import React from "react";

export default function DismissIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.08859 3.21569L3.14645 3.14645C3.32001 2.97288 3.58944 2.9536 3.78431 3.08859L3.85355 3.14645L7 6.293L10.1464 3.14645C10.3417 2.95118 10.6583 2.95118 10.8536 3.14645C11.0488 3.34171 11.0488 3.65829 10.8536 3.85355L7.707 7L10.8536 10.1464C11.0271 10.32 11.0464 10.5894 10.9114 10.7843L10.8536 10.8536C10.68 11.0271 10.4106 11.0464 10.2157 10.9114L10.1464 10.8536L7 7.707L3.85355 10.8536C3.65829 11.0488 3.34171 11.0488 3.14645 10.8536C2.95118 10.6583 2.95118 10.3417 3.14645 10.1464L6.293 7L3.14645 3.85355C2.97288 3.67999 2.9536 3.41056 3.08859 3.21569L3.14645 3.14645L3.08859 3.21569Z"
        fill="currentColor"
      />
    </svg>
  );
}
