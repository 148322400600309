import { IAssetsPlanet, IRadioItem } from "../types/common";

export const listOrderType: IRadioItem[] = [
  { title: "Full", value: "full" },
  { title: "Partial", value: "partial" },
];

export const listAssetsPlanetBooking: IAssetsPlanet[] = [
  {
    title: "Visual",
    description: "Optimized for visual analysis - RGB only",
  },
  {
    title: "Surfacs reffectance - 4 band",
    description:
      "Corrected for surface reffectance: recommended for most analytic applications - includes RGB NIR",
    tags: ["UDM2"],
  },
  {
    title: "Surfacs reffectance - 8 band",
    description:
      "Corrected for surface reffectance: recommended for most analytic applications - also includes coastal RGB NIR",
    tags: ["UDM2"],
  },
  {
    title: "Analytic radiance (TOAR) - 3 band",
    description: "Calibrated to top atmosphere radiance - RGB only",
  },
];

export const configKey = {
  COLOR_BRANCH: "COLOR_BRANCH",
};
