import React from "react";

export default function NotificationMenuIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_124_2090)">
        <path
          d="M13.3897 9.66001L12.6552 8.55334C12.5009 8.30668 12.3613 7.84001 12.3613 7.56668V5.88001C12.3613 4.31334 11.3477 2.96001 9.88597 2.32668C9.50401 1.71334 8.79886 1.33334 7.99087 1.33334C7.19023 1.33334 6.47039 1.72668 6.08843 2.34668C4.65609 2.99334 3.66447 4.33334 3.66447 5.88001V7.56668C3.66447 7.84001 3.52491 8.30668 3.37066 8.54668L2.62878 9.66001C2.33496 10.1067 2.26886 10.6 2.45249 11.0533C2.62878 11.5 3.04746 11.8467 3.59102 12.0133C5.01601 12.4533 6.51446 12.6667 8.01291 12.6667C9.51136 12.6667 11.0098 12.4533 12.4348 12.02C12.949 11.8667 13.3456 11.5133 13.5366 11.0533C13.7276 10.5933 13.6762 10.0867 13.3897 9.66001Z"
          fill="currentColor"
        />
        <path
          d="M9.88678 13.34C9.60678 14.1133 8.86678 14.6667 8.00012 14.6667C7.47345 14.6667 6.95345 14.4533 6.58678 14.0733C6.37345 13.8733 6.21345 13.6067 6.12012 13.3333C6.20678 13.3467 6.29345 13.3533 6.38678 13.3667C6.54012 13.3867 6.70012 13.4067 6.86012 13.42C7.24012 13.4533 7.62678 13.4733 8.01345 13.4733C8.39345 13.4733 8.77345 13.4533 9.14678 13.42C9.28678 13.4067 9.42678 13.4 9.56012 13.38C9.66678 13.3667 9.77345 13.3533 9.88678 13.34Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_124_2090">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
