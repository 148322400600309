import React from "react";
import CommonComponents from "../Commons";
import CommonIcons from "../../assets/icons";
import TaskingSvg from "../../assets/svg/tasking.svg";
import DownloadSvg from "../../assets/svg/download.svg";
import { useDispatch } from "react-redux";
import { setShowOrderTasking } from "../../store/reducers/appCommonSlice";
import Map from "./MapTasking";

const DrawPolygonComp = () => {
  const dispatch = useDispatch();
  return (
    <div className="h-full p-3 pt-0 overflow-x-auto !w-[460px]">
      <div id="resultList" />
      <div className="w-full sticky top-0 z-10 bg-common-ink100 py-3">
        <div className="flex justify-between items-center py-1">
          <div className="flex items-center gap-2 text-sm font-normal text-common-white">
            <img src={TaskingSvg} />
            Tasking
          </div>

          <div className="flex items-center gap-2">
            <CommonComponents.ButtonUI
              icon={<img src={DownloadSvg} />}
              commonType="standard"
              className="!text-xs !h-7"
            >
              Download
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              commonType="standard"
              className="!text-xs !h-7"
            >
              Continue
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              icon={<CommonIcons.DismissIcon />}
              commonType="standard"
              className="!text-xs !h-7"
              onClick={() => dispatch(setShowOrderTasking(false))}
            ></CommonComponents.ButtonUI>
          </div>
        </div>

        <div
          className="w-full !rounded bg-common-ink200 mt-4"
          style={{ borderRadius: "3px" }}
        >
          <Map />
        </div>
      </div>
    </div>
  );
};

export default DrawPolygonComp;
