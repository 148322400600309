const themeColor = {
  primary500: "#60CDFF",
  primary400: "#48ADDC",
  primary300: "#308EB9",
  primary200: "#186E95",
  primary100: "#004E72",

  blue500: "#2962FF",
  blue400: "#2A52BF",
  blue300: "#2B4795",
  blue200: "#2B3C6B",
  blue100: "#2C3141",
  mainBlue: "#4cc2ff",

  orange500: "#EF6C00",
  orange400: "#B4590D",
  orange300: "#8D4C16",
  orange200: "#663F1F",
  orange100: "#3F3228",

  green500: "#00E676",
  green400: "#0DAE60",
  green300: "#27AE60",
  green200: "#1F6342",
  green100: "#283E33",

  red500: "#DD2C00",
  red400: "#A82C0D",
  red300: "#842C16",
  red200: "#612C1F",
  red100: "#3D2C28",

  purple500: "#AA00FF",
  purple400: "#840DBF",
  purple300: "#6B1695",
  purple200: "#521F6B",
  purple100: "#382841",

  ink500: "#3E4353",
  ink400: "#353A4B",
  ink300: "#2C3243",
  ink200: "#23293A",
  ink100: "#1A2032",

  line: "#353B4F",
  background: "#232735",
  textDefault: "#4F5569",
  textDefault1: "#61687E",
  hoverTextbox: "#393939",
  white: "#FFFFFF",
  black: "#000000",
  sbackground1: "#1A2032",

  activeAction: "#004E72",
};

export default themeColor;
