import { Collapse, CollapseProps, Divider, Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import CommonIcons from "../../../assets/icons";
import MapTaskingPlugin from "../../../components/MapTasking/Map";
import { capitalizeFirstLetter } from "../../../helpers/common";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import {
  getOrderByIdAdminAsync,
  getOrderByIdUserAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import { RenderHeader, renderInfoOrder } from "../ManageTaskingOrder/ultis";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import CardItemConfirmComp from "../../../components/BookingImage/CardItemConfirm";
import {
  onMouseEnterItem,
  onMouseLeaveItem,
} from "../../../components/Map/ultis";
import { BuyerInfomation } from "../../../components/BookingImage/utils/BuyerInfomation";
import axios from "axios";
import { BuyerInfomationTaskingOrderAdmin } from "../ManageTaskingOrder/[UpdateTaskingOrderAdmin]";
import Step3 from "../../../components/BookingImage/Step3";
import Step4 from "../../../components/BookingImage/Step4";
import Step2 from "../../../components/BookingImage/Step2";
import { EProviderName, ERoleAccessControl } from "../../../enums";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";

const InfoArchiveOrderPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [form] = Form.useForm();
  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  const dataItem = location?.state?.item;

  const [data, setData] = useState<any>({});
  const [itemsImages, setItemsImages] = useState<any>([]);
  const [items, setItems] = useState<any>([]);
  const [itemsInfoOder, setItemsInfoOder] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});

  const maplibreGL = useSelector(
    (state: RootState) => state.mapTasking.maplibreGL
  );
  const whoami = useSelector((state: RootState) => state.appCommonSlice.whoami);

  const groups = useSelector((state: RootState) => state.appCommonSlice.groups);
  let publicConfig = useSelector(
    (state: RootState) => state.appCommonSlice.publicConfig
  );

  const { providerConfigStore } = useSelector(
    (state: RootState) => state.appCommonSlice
  );

  const roleAdmin = useSelector(
    (state: RootState) => state.appCommonSlice.roleUsing
  );

  const getData = async () => {
    let res = !roleAdmin?.includes(ERoleAccessControl.admin)
      ? // @ts-ignore
        await dispatch(getOrderByIdUserAsync(id)).unwrap()
      : // @ts-ignore
        await dispatch(getOrderByIdAdminAsync(id)).unwrap();

    if (roleAdmin?.includes(ERoleAccessControl.admin)) {
      axios
        .get(
          `${publicConfig?.REACT_APP_API_KARATOS}/oa/admin/identities/${res?.data?.data?.user_id}`,
          {
            withCredentials: !publicConfig?.REACT_APP_SKIP_CREDENTIALS ?? true,
          }
        )
        .then((data) => {
          setDataUser(data.data);

          setItems([
            {
              key: "1",
              label: "Buyer's information",
              children: <BuyerInfomationTaskingOrderAdmin whoami={data.data} />,
            },
          ]);
        });
    } else {
      setItems([
        {
          key: "1",
          label: "Buyer's information",
          children: <BuyerInfomation whoami={whoami} valueForm={{}} />,
        },
      ]);
    }

    setItemsInfoOder([
      {
        key: "1",
        label: "Order's Information",
        children: renderInfoOrder(res?.data?.data),
      },
    ]);

    const newValues = {};
    form.setFieldsValue(newValues);

    setData(res?.data?.data ?? []);
  };

  useEffect(() => {
    let a: any = [];

    if (data?.order_details?.length > 0) {
      data?.order_details?.map((item: any, index: any) => {
        console.log(
          item?.request_order?.optionsPerProductType?.[0]?.options,
          " item?.request_order?.optionsPerProductType?.[0]?.options"
        );
        if (item?.images?.length !== 0)
          a = a.concat({
            key: index,
            label: (
              <span>
                {capitalizeFirstLetter(item?.provider?.name)} (
                {item?.images?.length})
              </span>
            ),
            children: (
              <div className="w-full flex gap-3">
                <div className="w-full">
                  {item?.provider?.name?.toLowerCase() === "planet" ? (
                    <Step3
                      form={form}
                      dataImages={item?.images}
                      map={map}
                      sourceId={sourceId}
                      initialValues={item?.request_order?.products}
                      readOnly
                    />
                  ) : item?.provider?.name?.toLowerCase() === "oneatlas" ? (
                    <Step4
                      form={form}
                      dataImages={item?.images}
                      total={item?.images?.length}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.oneatlas
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                      initialValues={item?.request_order?.optionsPerProductType?.[0]?.options.concat(
                        {
                          key: "product_type_id",
                          value:
                            item?.request_order?.optionsPerProductType?.[0]
                              ?.productTypeId,
                        }
                      )}
                      readOnly
                    />
                  ) : (
                    <Step2
                      form={form}
                      items={item?.images}
                      dataProvider={
                        providerConfigStore?.content_page?.filter(
                          (item: any) =>
                            item?.name.toLowerCase() === EProviderName.capella
                        )?.[0]?.configs
                      }
                      map={map}
                      sourceId={sourceId}
                    />
                  )}
                </div>
              </div>
            ),
          });
      });
    }

    setItemsImages(a);
  }, [data, maplibreGL, providerConfigStore]);

  useEffect(() => {
    getData();
  }, [id]);

  useEffect(() => {
    dispatch(changeLayoutName("/view-order"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-order")}
            >
              Existing Image Orders
            </span>
          ),
        },
        { title: ` ${dataItem?.title}` },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, [id]);

  return (
    <Form form={form}>
      <ContentLayoutManagement>
        <div className="flex !flex-row w-full gap-3 h-full overflow-hidden card-archive-order div-page-default ">
          <div className="w-1/2 div-info-confirm-order text-white h-full overflow-auto pr-2">
            {RenderHeader({ data: data })}

            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={items}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
            <div className="div-collapse-pol-point-cus">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsInfoOder}
                defaultActiveKey={["1"]}
                onChange={() => {}}
                className="!p-0"
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>

            <Divider
              style={{ background: "#353B4F", margin: "16px 0 10px 0" }}
            />

            <p className="text-base my-3 mt-1 font-semibold">Images in order</p>

            <div className="card-archive-order">
              <Collapse
                bordered={false}
                expandIconPosition="end"
                items={itemsImages}
                onChange={() => {}}
                defaultActiveKey={["0", "1", "2", "3"]}
                expandIcon={({ isActive }) => (
                  <div style={{ rotate: isActive ? "0deg" : "180deg" }}>
                    <CommonIcons.ChevronUpIcon />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="w-1/2 ">
            <MapTaskingPlugin showOnConfirm={true} activePolygon={{}} />
          </div>
        </div>
      </ContentLayoutManagement>
    </Form>
  );
};

export default InfoArchiveOrderPage;
