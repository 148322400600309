import React from "react";

export default function TrashIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 3.48834C10.3075 3.29584 8.35333 3.19667 6.405 3.19667C5.25 3.19667 4.095 3.255 2.94 3.37167L1.75 3.48834"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.95801 2.89916L5.08634 2.135C5.17967 1.58083 5.24967 1.16666 6.23551 1.16666H7.76384C8.74968 1.16666 8.82551 1.60416 8.91301 2.14083L9.04134 2.89916"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9956 5.33167L10.6164 11.2058C10.5522 12.1217 10.4997 12.8333 8.87224 12.8333H5.12724C3.49974 12.8333 3.44724 12.1217 3.38307 11.2058L3.00391 5.33167"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.02539 9.625H7.96789" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.54199 7.29166H8.45866" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
