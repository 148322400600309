import React from "react";

export default function SunElevationIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3332 8.00008C12.3332 5.60675 10.3932 3.66675 7.99984 3.66675C5.6065 3.66675 3.6665 5.60675 3.6665 8.00008"
        stroke="#959595"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.32682 3.32658L3.24015 3.23991M12.6735 3.32658L12.7602 3.23991L12.6735 3.32658ZM8.00016 1.38658V1.33325V1.38658ZM1.38684 7.99992H1.3335H1.38684ZM14.6668 7.99992H14.6135H14.6668Z"
        stroke="#959595"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M2.6665 10H13.3332" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4 12H12" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6 14H10" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
