import React from "react";

export default function SearchIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.66683 14C11.1646 14 14.0002 11.1645 14.0002 7.66668C14.0002 4.16887 11.1646 1.33334 7.66683 1.33334C4.16903 1.33334 1.3335 4.16887 1.3335 7.66668C1.3335 11.1645 4.16903 14 7.66683 14Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.6668 14.6667L13.3335 13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
