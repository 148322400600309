import React from "react";

export default function DatasetIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_269_1641)">
        <path
          d="M12.9563 2.68965C13.0445 2.82826 12.8934 2.98974 12.7332 2.95333C12.4199 2.85999 12.0732 2.81333 11.7199 2.81333H9.60294C9.44572 2.81333 9.29766 2.73938 9.20322 2.6137L8.48656 1.65999C8.39265 1.52696 8.48142 1.33333 8.64426 1.33333H10.4799C11.5206 1.33333 12.4373 1.87382 12.9563 2.68965Z"
          fill="currentColor"
        />
        <path
          d="M13.4273 4.35999C13.1407 4.15333 12.814 3.99999 12.4607 3.91333C12.2207 3.84666 11.974 3.81333 11.7207 3.81333H9.24065C8.85398 3.81333 8.82732 3.78 8.62065 3.50666L7.68732 2.26666C7.25398 1.68666 6.91398 1.33333 5.82732 1.33333H4.28065C2.65398 1.33333 1.33398 2.65333 1.33398 4.27999V11.72C1.33398 13.3467 2.65398 14.6667 4.28065 14.6667H11.7207C13.3473 14.6667 14.6673 13.3467 14.6673 11.72V6.76C14.6673 5.76666 14.1807 4.89333 13.4273 4.35999ZM9.59398 10.8933H6.40065C6.14065 10.8933 5.94065 10.6867 5.94065 10.4267C5.94065 10.1733 6.14065 9.96 6.40065 9.96H9.59398C9.85398 9.96 10.0607 10.1733 10.0607 10.4267C10.0607 10.6867 9.85398 10.8933 9.59398 10.8933Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_269_1641">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
