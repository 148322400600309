import { Tooltip } from "antd";
import CloudSvg from "../../../assets/svg/cloud.svg";
import Icon1 from "../../../assets/svg/icon-1.svg";
import Icon2 from "../../../assets/svg/icon-2.svg";
import Icon3 from "../../../assets/svg/icon-3.svg";
import Icon4 from "../../../assets/svg/icon-4.svg";
import Icon5 from "../../../assets/svg/icon-5.svg";

export const dataImage = [];

export const dataProvider = [];

export const dataMoreDes = [
  {
    icon: CloudSvg,
    val: "10.4%",
  },
  {
    icon: Icon1,
    val: "landset-9",
  },
  {
    icon: Icon2,
    val: "7721, 7591",
  },
  {
    icon: Icon3,
    val: "30%",
  },
  {
    icon: Icon4,
    val: "160.34°",
  },
  {
    icon: Icon5,
    val: "63.09°",
  },
];
export const RenderMoreDesItem = (props: any) => {
  return (
    <div className="grid grid-cols-3 gap-2">
      {props?.data?.map((item: any, ind: any) => {
        if (!item) return null;
        return (
          <Tooltip title={item?.label}>
            <span
              className="flex justify-between items-center text-center"
              key={ind}
              style={{ fontSize: "14px" }}
            >
              <span className="flex gap-1 items-center text-center">
                <img src={item?.icon} className="w-4 h-4" />
                {item?.val}
              </span>

              <span
                style={{
                  display: (ind + 1) % 3 === 0 ? "none" : "initial",
                  color: "#85957F",
                }}
              >
                |
              </span>
            </span>
          </Tooltip>
        );
      })}
    </div>
  );
};

export function convertGeoJSONToSVG(
  geoJSON: any,
  svgWidth: number,
  svgHeight: number
) {
  const geoCoords =
    geoJSON?.geometry?.coordinates?.[0] ?? geoJSON?.coordinates?.[0];

  function convertGeoToSVG(
    coords: [number, number][],
    svgWidth: number,
    svgHeight: number
  ) {
    const minLon = Math.min(...coords.map((c) => c[0]));
    const maxLon = Math.max(...coords.map((c) => c[0]));
    const minLat = Math.min(...coords.map((c) => c[1]));
    const maxLat = Math.max(...coords.map((c) => c[1]));

    const lonRange = maxLon - minLon;
    const latRange = maxLat - minLat;

    // Tính tỷ lệ
    const aspectRatio = lonRange / latRange;

    // Tính toán kích thước cuối cùng của SVG
    const scaledWidth =
      aspectRatio > svgWidth / svgHeight ? svgWidth : svgHeight * aspectRatio;
    const scaledHeight =
      aspectRatio < svgWidth / svgHeight ? svgHeight : svgWidth / aspectRatio;

    return coords.map(([lon, lat]) => {
      const x = ((lon - minLon) / lonRange) * scaledWidth;
      const y = ((maxLat - lat) / latRange) * scaledHeight; // Đảo chiều Y
      return [x, y];
    });
  }

  const svgCoords = convertGeoToSVG(geoCoords, svgWidth, svgHeight);
  const pathData =
    svgCoords
      .map((point, index) =>
        index === 0 ? `M ${point[0]},${point[1]}` : `L ${point[0]},${point[1]}`
      )
      .join(" ") + " Z";

  const svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${svgWidth}" height="${svgHeight}">
    <path d="${pathData}" fill="none" stroke="black"/>
  </svg>`;

  return `data:image/svg+xml;base64,${btoa(svgString)}`;
}

export function getDistance(point1: any, point2: any) {
  const [lng1, lat1] = point1;
  const [lng2, lat2] = point2;

  const R = 6371000; // Bán kính Trái Đất (m)
  const φ1 = (lat1 * Math.PI) / 180;
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lng2 - lng1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}
