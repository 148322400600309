import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import EmptySvg from "../../assets/svg/empty.svg";
import BookingImage from "../../components/BookingImage";
import ConfirmOrderComp from "../../components/BookingImage/ConfirmOrder";
import CommonComponents from "../../components/Commons";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import {
  resetAllDrawer,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import { displayNotification } from "../../store/reducers/notificationSlice";
import {
  changeShowOrderTasking,
  createTaskingOrderAsync,
  getPriorityAsync,
  getTaskingSchemaAsync,
  setOrderedProvider,
  setPolygonOrderTasking,
} from "../../store/reducers/orderSlice";
import { RootState } from "../../store/store";
import { setValuesForm as setValuesFormStore } from "../../store/reducers/orderSlice";
import { getMyProjectsAsync } from "../../store/reducers/projectSlice";
import ApplySvg from "../../assets/svg/apply-black.svg";
import OrderTaskingForm from "../../components/BookingImage/OrderTasking";
import axios from "axios";
import { geometry } from "@turf/turf";
import useToggle from "../../hooks/useToggle";

const ConfirmTaskingOrderPage = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef<any>(null);
  const selectedImages = useSelector(
    (state: RootState) => state.cartSlice.selectedImagesConfirm
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const selectedPolygon = useSelector(
    (state: RootState) => state.orderSlice.polygonOrderTasking
  );

  const orderedProvider = useSelector(
    (state: RootState) => state.orderSlice.orderedProvider
  );

  const [flagConfirm, setflagConfirm] = useState<any>(false);
  const [valuesForm, setValuesForm] = useState<any>({});
  const [dataPriority, setDataPriority] = useState<any>([]);
  const [arrProjects, setArrProjects] = useState<any>([]);

  const [dataSchema, setDataSchema] = useState<any>({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [policy, setPolicy] = useState(false);
  const {
    open: openPolicy,
    toggle: togglePolicy,
    shouldRender: shouldRenderPolicy,
  } = useToggle();

  useEffect(() => {
    dispatch(changeLayoutName("/confirm-order-tasking"));
    dispatch(
      changeDataBreadcrumb([
        {
          title: (
            <span
              className="cursor-pointer"
              onClick={() => navigate("/order/management-tasking")}
            >
              Tasking Orders
            </span>
          ),
        },
        { title: "Information" },
      ])
    );
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(changeShowOrderTasking(false));
    };
  }, []);

  const getSchema = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getTaskingSchemaAsync({ idProvider: 2, type: "order_tasking" })
    ).unwrap();

    setDataSchema(res?.data?.data ?? {});
  };

  useEffect(() => {
    if (orderedProvider?.length > 0) {
      getSchema();
    }
  }, [orderedProvider]);

  useEffect(() => {
    if (flagConfirm) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => setflagConfirm(false)}
              >
                Tasking Orders
              </span>
            ),
          },
          { title: "Confirm" },
        ])
      );
    } else if (!flagConfirm && selectedPolygon?.length > 0) {
      dispatch(
        changeDataBreadcrumb([
          {
            title: (
              <span
                className="cursor-pointer"
                onClick={() => navigate("/order/management-tasking")}
              >
                Tasking Orders
              </span>
            ),
          },
          { title: "Information" },
        ])
      );
    }
  }, [flagConfirm, selectedPolygon]);

  const handleSubmit = async () => {
    let { production, ...newValues } = valuesForm;
    let pay = {
      ...newValues,
      aoi: selectedPolygon?.geometry,
      start_date_request: dayjs(valuesForm?.start_date_request).format(
        "DD/MM/YYYY HH:mm"
      ),
      end_date_request: valuesForm?.end_date_request
        ? dayjs(valuesForm?.end_date_request).format("DD/MM/YYYY HH:mm")
        : null,
      options: Object.entries(valuesForm?.production).map(([key, value]) => ({
        key,
        value,
        label: key,
      })),
    };

    // @ts-ignore
    const res = await dispatch(createTaskingOrderAsync(pay)).unwrap();

    if (res?.status === 201 || res?.status === 200) {
      dispatch(
        displayNotification({
          type: "success",
          content: "Order successfully",
        })
      );

      dispatch(changeShowOrderTasking(false));
      dispatch(resetLeftDrawer());
      // @ts-ignore
      dispatch(setValuesFormStore({}));
      setValuesForm({});
      navigate("/order/management-tasking");
    } else {
      dispatch(
        displayNotification({
          type: "error",
          content: res?.message ?? "Order fail",
        })
      );
    }
  };

  const renderCalssName = () => {
    let a = "";

    if (!flagConfirm && !showOrderTasking) {
      a = "div-form-small";
    } else if (
      (showOrderTasking && flagConfirm) ||
      (!showOrderTasking && flagConfirm)
    ) {
      a = "div-page-default";
    } else {
      a = "div-form-default !justify-start !pt-10";
    }
    return a;
  };

  useEffect(() => {
    handleGetPriority();
    getDataProject();
  }, []);

  const handleGetPriority = async () => {
    // @ts-ignore
    const res = await dispatch(getPriorityAsync()).unwrap();

    setDataPriority(
      res?.data?.data?.content_page?.filter(
        (itemF: any) => itemF?.key === "PRIORITY_LEVEL"
      ) ?? []
    );
  };

  const getDataProject = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMyProjectsAsync({ size: 1000, page: 0 })
    ).unwrap();

    setArrProjects(res?.data?.data?.content_page ?? []);
  };

  const handleSubmitBookingImage = (values: any) => {
    setflagConfirm(true);
    setValuesForm(values);
    dispatch(setValuesFormStore(values));
  };

  const triggerFormSubmit = async () => {
    try {
      setLoadingSubmit(true);
      if (formRef.current) {
        formRef.current.submit(); // Gọi hàm submit của form từ component cha
      }

      // @ts-ignore
      let values = formRef.current.getFieldsValue();

      let payload = {
        name: values?.name,
        priority_id: values?.priority_id,
        description: values?.description ?? undefined,
        // provider_id: orderedProvider,
        // geometry: selectedPolygon?.geometry,
        order_details: [
          {
            provider_id: Number(orderedProvider?.[0]),
            request_order: {
              geometry: selectedPolygon?.geometry,
              properties: values?.properties,
            },
          },
        ],
      };

      let urlRequest = dataSchema?.url_submit;

      let res = await axios.post(urlRequest, payload);

      if (res?.status === 201) {
        dispatch(
          displayNotification({
            type: "success",
            content: "Order successfully",
          })
        );
        // @ts-ignore
        dispatch(setPolygonOrderTasking({}));
        // @ts-ignore
        dispatch(setOrderedProvider([]));

        navigate("/order/management-tasking");
      }
    } catch (error) {
    } finally {
      togglePolicy();
      setLoadingSubmit(false);
    }
  };

  return (
    <ContentLayoutManagement
      header={
        flagConfirm ? (
          <div className="flex gap-2 !justify-end pr-6">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="btn-cancel hover:bg-[#3E4353] !text-xs !h-7 "
              onClick={() => setflagConfirm(false)}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              className="!text-xs !h-[27px]"
              onClick={() => handleSubmit()}
            >
              Send order
            </CommonComponents.ButtonUI>
          </div>
        ) : (
          <div className="flex gap-2 !justify-end pr-6">
            <CommonComponents.ButtonUI
              commonType="standard"
              className="!text-xs !h-7 mr-2"
              onClick={() => navigate("/explore-tasking")}
              disabled={!selectedPolygon?.id}
            >
              Cancel
            </CommonComponents.ButtonUI>
            <CommonComponents.ButtonUI
              htmlType="submit"
              commonType="default"
              className="!text-xs !h-7"
              icon={<img src={ApplySvg} />}
              disabled={!selectedPolygon?.id}
              onClick={() => {
                togglePolicy();
              }}
            >
              Order
            </CommonComponents.ButtonUI>
          </div>
        )
      }
    >
      {selectedPolygon && selectedPolygon?.id && showOrderTasking ? (
        <div className={renderCalssName()}>
          {!flagConfirm ? (
            // <BookingImage
            //   setflagConfirm={setflagConfirm}
            //   setValuesForm={setValuesForm}
            //   initialValues={valuesForm}
            //   dataPriority={dataPriority}
            //   datProject={arrProjects}
            //   handleSubmitBookingImage={handleSubmitBookingImage}
            //   formRef={formRef}
            // />

            <OrderTaskingForm
              dataSchema={dataSchema}
              dataPriority={dataPriority}
              datProject={arrProjects}
              // handleSubmitBookingImage={handleSubmitBookingImage}
              formRef={formRef}
            />
          ) : (
            <>
              <ConfirmOrderComp
                data={valuesForm}
                onCancel={() => setflagConfirm(false)}
                handleSubmit={handleSubmit}
              />
            </>
          )}
        </div>
      ) : (
        <div className="div-one-center h-full">
          <div className="div-center-col">
            <img src={EmptySvg} />
            <p className="text-white">No selected polygon</p>
          </div>
        </div>
      )}

      {shouldRenderPolicy && (
        <CommonComponents.ModalUI
          open={openPolicy}
          toggle={togglePolicy}
          title="Privacy Policy & Term"
          handleConfirm={triggerFormSubmit}
          disabled={!policy}
          loading={loadingSubmit}
        >
          <div>
            <CommonComponents.CheckboxUI
              checked={policy}
              onChange={() => {
                setPolicy(!policy);
              }}
              className="text-white"
            >
              <Link
                to={`https://geohub.ai/dieu-khoan-va-dieu-kien-su-dung-dich-vu`}
                target={"_blank"}
                className="underline underline-offset-4 cursor-pointer text-common-blue400 hover:!text-common-blue500 hover:!underline"
              >
                Terms of use
              </Link>
              <span> buy us products</span>
            </CommonComponents.CheckboxUI>
          </div>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default ConfirmTaskingOrderPage;
