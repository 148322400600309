import React from "react";

export default function CloudIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.84653 8.67333C4.4932 8.49333 4.09986 8.4 3.69986 8.4C0.579863 8.62 0.579863 13.16 3.69986 13.38H11.0932C11.9932 13.3867 12.8599 13.0533 13.5199 12.4467C15.7132 10.5333 14.5399 6.68667 11.6532 6.32C10.6132 0.073334 1.5932 2.44667 3.7332 8.4"
        stroke="#959595"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5669 6.61342C10.9136 6.44009 11.2936 6.34675 11.6802 6.34009"
        stroke="#959595"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
