import React from "react";

export default function CreatedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.3335 1.33325V3.33325" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.6665 1.33325V3.33325" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M14 5.66659V11.3333C14 13.3333 13 14.6666 10.6667 14.6666H5.33333C3 14.6666 2 13.3333 2 11.3333V5.66659C2 3.66659 3 2.33325 5.33333 2.33325H10.6667C13 2.33325 14 3.66659 14 5.66659Z"
        stroke="#959595"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M5.3335 7.33325H10.6668" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.3335 10.6667H8.00016" stroke="#959595" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}
