import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyDataSvg from "../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import InfoSvg from "../../assets/svg/ManageData/info.svg";
import RejectSvg from "../../assets/svg/reject.svg";
import SettingSvg from "../../assets/svg/setting.svg";
import { EOrderStatus, ERoleAccessControl, ESttausCode } from "../../enums";
import { getErrorMsg } from "../../helpers/common";
import { CheckRoleAccessControlAdmin } from "../../helpers/roleAccessControl";
import useToggle from "../../hooks/useToggle";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import useGetOrderAdmin from "../../services/order/hooks/useGetOrderAdmin";
import useGetOrderUser from "../../services/order/hooks/useGetOrderUser";
import { IOrderArchivedUser } from "../../services/order/interfaces/IOrder.interface";
import orderService from "../../services/order/order.service";
import { displayNotification } from "../../store/reducers/notificationSlice";
import { RootState } from "../../store/store";
import { IResponsePage } from "../../types/common";
import CommonComponents from "../Commons";
import CardManagementComp from "../Commons/CardManagement";
import { IItemData } from "../Commons/CardManagement/ICardManagement";
import LoadingUI from "../Commons/Loading";
import NoData from "../NoData";

const ManagementOrderComp = () => {
  //! state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pageSize = useSelector(
    (state: RootState) => state.appCommonSlice.pageSize
  );
  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );
  const {
    data: dataOrderUSer,
    isLoading: loadingOrderUser,
    refetch: refetchOrderUser,
  } = useGetOrderUser(false, { pageNumber: pageNumber, pageSize: pageSize });

  const {
    data: dataOrderAdmin,
    isLoading: loadingOrderAdmin,
    refetch: refetchOrderAdmin,
  } = useGetOrderAdmin(false, { pageNumber: pageNumber, pageSize: pageSize });

  const [dataUsing, setDataUsing] =
    useState<IResponsePage<IOrderArchivedUser>>();

  const {
    open: openConfirmReject,
    shouldRender: shouldRenderConfirmReject,
    toggle: toggleConfirmReject,
  } = useToggle();

  const {
    open: openUpdateStatus,
    shouldRender: shouldRenderUpdateStatus,
    toggle: toggleUpdateStatus,
  } = useToggle();

  const [idReject, setIdReject] = useState<number | null>(null);

  const roleAdmin = CheckRoleAccessControlAdmin();

  //! function
  const ParseDataOrder = useCallback(() => {
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return [];
    }
    return dataUsing?.content_page?.map((item) => {
      return {
        id: item.id,
        imgSrc: MyDataSvg,
        title: item.name,
        status: item.currentStatus,
        des: {
          created_at: item?.created_at,
          description: item?.description,
          priority: item?.priority,
        },
      };
    });
  }, [dataUsing]);

  const showInfo = (item: IItemData) => {
    navigate(`/order/management-order/${item.id}`, {
      state: {
        item: item,
      },
    });
    // dispatch(changeShowDrawer(true));
    // dispatch(changeLeftDrawerType("InforOrder"));
  };

  const handleConfirmReject = async () => {
    try {
      const result = await orderService.ChangeStatusOrder(
        Number(idReject),
        roleAdmin?.includes(ERoleAccessControl.admin) ? 9 : 7
      );
      if (result.status === ESttausCode.OK) {
        dispatch(
          displayNotification({
            type: "success",
            content: "Successfully",
          })
        );
        if (roleAdmin?.includes(ERoleAccessControl.admin)) {
          refetchOrderAdmin();
          return;
        }

        refetchOrderUser();
      }
    } catch (error) {
      dispatch(
        displayNotification({
          type: "error",
          content: getErrorMsg(error),
        })
      );
    } finally {
      toggleConfirmReject();
    }
  };
  //! effect
  useEffect(() => {
    if (roleAdmin?.includes("admin")) {
      refetchOrderAdmin();
      return;
    }
    if (roleAdmin?.includes("user")) {
      refetchOrderUser();
    }
  }, [roleAdmin, pageNumber]);

  useEffect(() => {
    if (dataOrderAdmin || dataOrderUSer) {
      setDataUsing(dataOrderAdmin?.data ?? dataOrderUSer?.data);
    }
  }, [loadingOrderUser, loadingOrderAdmin]);
  //! render
  const renderItemOrder = useCallback(() => {
    if (loadingOrderUser || loadingOrderAdmin) {
      return <LoadingUI />;
    }
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return <NoData />;
    }
    return ParseDataOrder()?.map((item: any, index) => {
      const orderStatus = item?.status?.name === EOrderStatus.ORDER_CREATED; // check cho nay trang thai user tu cancel thi ko cap nhat duoc
      const time = moment(item?.des?.created_at).format("DD/MM/YYYY");
      const dataItem = {
        ...item,
        des: (
          <div className="flex gap-1">
            {item?.des?.created_at && <span>{time}</span>}
            {item?.des?.description && (
              <span className="before:content-['|']">
                {item?.des?.description}
              </span>
            )}
            {item?.des?.priority && (
              <div className="before:content-['|']">
                <span
                  style={{
                    color: item?.des?.priority?.description,
                  }}
                >
                  {` ${item?.des?.priority.value}`}
                </span>
              </div>
            )}
          </div>
        ),
      };
      return (
        <CardManagementComp
          key={index}
          data={dataItem}
          actions={[
            roleAdmin?.includes(ERoleAccessControl.admin) && (
              <CommonComponents.TooltipUI title="Update">
                <img
                  className={`${orderStatus ? "" : "hidden"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/order/management-order/${item.id}/update`, {
                      state: {
                        item: item,
                      },
                    });
                  }}
                  src={SettingSvg}
                />
              </CommonComponents.TooltipUI>
            ),
            roleAdmin?.includes(ERoleAccessControl.user) && (
              <CommonComponents.TooltipUI
                title={
                  roleAdmin?.includes(ERoleAccessControl.admin)
                    ? "Reject"
                    : "Cancel"
                }
              >
                <img
                  className={`${orderStatus ? "" : "hidden"}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleConfirmReject();
                    setIdReject(Number(item.id));
                  }}
                  src={RejectSvg}
                />
              </CommonComponents.TooltipUI>
            ),

            // roleAdmin?.includes(ERoleAccessControl.admin) &&
            //   item?.status?.name !== EOrderStatus.ORDER_CANCELED && (
            //     <CommonComponents.TooltipUI title={"Update Status"}>
            //       <img
            //         // className={`${orderStatus ? "" : "hidden"}`}
            //         onClick={(e) => {
            //           e.stopPropagation();
            //           toggleUpdateStatus();
            //         }}
            //         src={UpdateSatusSvg}
            //       />
            //     </CommonComponents.TooltipUI>
            //   ),

            <CommonComponents.TooltipUI title="Infomation">
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  showInfo(item);
                }}
              />
            </CommonComponents.TooltipUI>,
            // <img alt="example" src={EditSvg} />,
          ]}
          onClick={() => {
            showInfo(item);
          }}
        />
      );
    });
  }, [dataUsing]);

  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {renderItemOrder()}
      </div>

      {shouldRenderConfirmReject && (
        <CommonComponents.ModalUI
          toggle={toggleConfirmReject}
          title="Confirm"
          open={openConfirmReject}
          handleConfirm={handleConfirmReject}
        >
          <p>Are you sure want reject this order ?</p>
        </CommonComponents.ModalUI>
      )}

      {shouldRenderUpdateStatus && (
        <CommonComponents.ModalUI
          toggle={toggleUpdateStatus}
          title="Confirm"
          open={openUpdateStatus}
          handleConfirm={handleConfirmReject}
          hiddenFooter={true}
        >
          <p>Note</p>
        </CommonComponents.ModalUI>
      )}
    </ContentLayoutManagement>
  );
};

export default ManagementOrderComp;
