import CommonIcons from "../../assets/icons";
import CommonComponents from "../../components/Commons";
import ButtonIconComp from "../../components/MapTasking/components/ButtonIcon";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseRoutes } from "../../router/RouterPage";
import { changeDataBreadcrumb } from "../../store/reducers/layoutSlice";
import ListProviderBranch from "./ListProviderBranch";
import { resetAllDrawer } from "../../store/reducers/drawerSlice";

const ProviderBranch = () => {
  //! state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //! function
  const handleNextToCreate = () => {
    navigate(`${baseRoutes.providerBranch}/new`);
  };
  //! effect
  useEffect(() => {
    dispatch(changeDataBreadcrumb([{ title: "Providers" }]));
    return () => {
      dispatch(changeDataBreadcrumb([]));

      dispatch(resetAllDrawer());
    };
  }, []);
  //! render
  return (
    <ContentLayoutManagement
      header={
        <div className="flex justify-end px-3">
          <CommonComponents.TooltipUI title="Add new" placement="left">
            <ButtonIconComp
              item={{
                description: "Add new provider",
                iconNode: (
                  <div className="text-white">
                    <CommonIcons.PlusIcon />
                  </div>
                ),
              }}
              onClick={handleNextToCreate}
              className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-none"
              style={{ background: "none" }}
            />
          </CommonComponents.TooltipUI>
        </div>
      }
    >
      <ListProviderBranch />
    </ContentLayoutManagement>
  );
};

export default ProviderBranch;
