import React from "react";

export default function MinusIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4287 7.32224H1.57157C1.493 7.32224 1.42871 7.38652 1.42871 7.46509V8.53652C1.42871 8.61509 1.493 8.67938 1.57157 8.67938H14.4287C14.5073 8.67938 14.5716 8.61509 14.5716 8.53652V7.46509C14.5716 7.38652 14.5073 7.32224 14.4287 7.32224Z"
        fill="currentColor"
      />
    </svg>
  );
}
