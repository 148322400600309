import React from "react";

export default function BasemapIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.67318 1.94666L12.6065 3.69333C13.7399 4.19333 13.7399 5.01999 12.6065 5.51999L8.67318 7.26666C8.22652 7.46666 7.49318 7.46666 7.04652 7.26666L3.11318 5.51999C1.97985 5.01999 1.97985 4.19333 3.11318 3.69333L7.04652 1.94666C7.49318 1.74666 8.22652 1.74666 8.67318 1.94666Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 7.33334C2 7.89334 2.42 8.54001 2.93333 8.76668L7.46 10.78C7.80667 10.9333 8.2 10.9333 8.54 10.78L13.0667 8.76668C13.58 8.54001 14 7.89334 14 7.33334"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 10.6667C2 11.2867 2.36667 11.8467 2.93333 12.1L7.46 14.1133C7.80667 14.2667 8.2 14.2667 8.54 14.1133L13.0667 12.1C13.6333 11.8467 14 11.2867 14 10.6667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
