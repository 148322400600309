import { Tooltip, TooltipProps } from "antd";
import React from "react";

interface IProps {
  children: React.ReactNode | string;
  title: string;
  placement?:
    | "top"
    | "left"
    | "right"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
}
const TooltipUI = (props: IProps) => {
  //! state
  const { children, title, placement = "top" } = props;
  //! function
  //! render
  return (
    <Tooltip placement={placement} arrow={false} title={title} color="#232735">
      {children}
    </Tooltip>
  );
};

export default TooltipUI;
