import CameraOutlined from "@ant-design/icons/lib/icons/CameraOutlined";
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  MenuProps,
  Spin,
  Tooltip,
} from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonIcons from "../../assets/icons";
import CartSvg from "../../assets/svg/cart.svg";
import CloudSvg from "../../assets/svg/cloud.svg";
import CloseSvg from "../../assets/svg/dismiss.svg";
import FootSvg from "../../assets/svg/foot.svg";
import PositionSvg from "../../assets/svg/position.svg";
import SortSvg from "../../assets/svg/sort.svg";
import { setLoadingGlobal } from "../../store/reducers/appCommonSlice";
import { resetLeftDrawer } from "../../store/reducers/drawerSlice";
import {
  searchAsync,
  setFlagSearch,
  setProviderId,
  setSelected,
} from "../../store/reducers/mapTaskingSlice";
import {
  resetState,
  setDataImages,
  setDataImagesProvider,
  setLoadingProvider,
} from "../../store/reducers/searchSlice";
import { RootState } from "../../store/store";
import { buttonTheme } from "../../themes/commonTheme";
import ButtonIconComp from "./components/ButtonIcon";
import { dataMoreDes, RenderMoreDesItem } from "./components/ultis";
import { onMouseEnterItem, onMouseLeaveItem, panToAndExtend } from "./ultis";
import ImageDefault from "../../assets/svg/item-iamge-default.png";

import Icon1 from "../../assets/svg/icon-1.svg";
import Icon2 from "../../assets/svg/icon-2.svg";
import Icon3 from "../../assets/svg/icon-3.svg";
import Icon4 from "../../assets/svg/icon-4.svg";
import Icon5 from "../../assets/svg/icon-5.svg";
import CommonComponents from "../Commons";
import useToggle from "../../hooks/useToggle";
import ImageInformation from "./ImageInformation";
import { displayNotification } from "../../store/reducers/notificationSlice";
import {
  addImgIntoCartAsync,
  delImgIntoCartAsync,
  setArrIdImage,
} from "../../store/reducers/cartSlice";
import { ESttausCode, ETypeImage } from "../../enums";

const items: MenuProps["items"] = [];

const DrawerListImagesSeacrhComp = () => {
  const dispatch = useDispatch();

  const [clickChangeProvider, setClickChangeProvider] =
    useState<boolean>(false);

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);

  let activePolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  let typeImage = useSelector((state: RootState) => state.mapTasking.typeImage);
  let page = useSelector((state: RootState) => state.search.page);
  let dataImages = useSelector((state: RootState) => state.search.dataImages);
  let searchState = useSelector((state: RootState) => state.search);
  let providerId = useSelector(
    (state: RootState) => state.mapTasking.providerId
  );

  let arrProviders = useSelector(
    (state: RootState) => state.mapTasking.arrProviders
  );

  let flagSearch = useSelector(
    (state: RootState) => state.mapTasking.flagSearch
  );

  let commonFilter = useSelector(
    (state: RootState) => state.mapTasking.commonFilter
  );

  let map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);
  let selected = useSelector((state: RootState) => state.mapTasking.selected);
  const { arrIdImage } = useSelector((state: RootState) => state.cartSlice);
  const { open, shouldRender, toggle } = useToggle();
  const {
    open: openConfirmRemoveCart,
    toggle: toggleConfirmRemoveCart,
    shouldRender: shouldRenderConfirmRemoveCart,
  } = useToggle();

  const [imageInfo, setImageInfo] = useState(null);
  const [imagesSelected, setImagesSelected] = useState<{
    acction?: "add" | "remove";
    title?: string;
    subTitle?: string;
    images?: any[];
  }>({});
  // @ts-ignore
  let loading = searchState[`loading_provider_${providerId}`];
  const searchAllProviders = async () => {
    dispatch(setLoadingGlobal(true));

    const searchPromises = arrProviders.map(async (provider: any) => {
      dispatch(setLoadingProvider({ id: provider.id, value: false }));
      const pay = {
        // aois: features?.map((itemM: any) => itemM.geometry),
        aois: [activePolygon?.geometry],
        date_time: [
          commonFilter?.timeFrom.valueOf(),
          commonFilter?.timeTo.valueOf(),
        ],
        cloud_cover: commonFilter?.cloudCover,
        resolution: commonFilter?.resolution,
        incidence_angle: commonFilter?.incidenceAngle,
        page_number: page,
        page_size: 50,
        provider_id: provider.id,
        image_type: typeImage,
      };
      try {
        // @ts-ignore
        const resSearch = await dispatch(searchAsync(pay)).unwrap();

        dispatch(
          setDataImagesProvider({
            id: provider.id,
            data: resSearch?.data?.data?.features,
            page: resSearch?.data?.data?.total_record ? page + 1 : page,
            nextLink: !resSearch?.data?.data?.total_record
              ? resSearch?.data?.data?.links?.filter(
                  (itemF: any) => itemF?.rel === "next"
                )?.[0]?.href
              : undefined,
            firstLink: !resSearch?.data?.data?.total_record
              ? resSearch?.data?.data?.links?.filter(
                  (itemF: any) => itemF?.rel === "first"
                )?.[0]?.href
              : undefined,
            total:
              resSearch?.data?.data?.total_record ||
              resSearch?.data?.data?.features?.length,
          })
        );

        if (provider.id === providerId) {
          dispatch(setDataImages(resSearch?.data?.data?.features));
        }

        return {
          id: provider.id,
          total:
            resSearch?.data?.data?.total_record ||
            resSearch?.data?.data?.features?.length,
          listImages: resSearch?.data?.data?.features || [],
          [`loading_${provider.id}`]: false,
        };
      } catch (error) {
        dispatch(setLoadingProvider({ id: provider.id, value: true }));
        return {
          id: provider.id,
          total: 0,
          listImages: [],
          [`loading_${provider.id}`]: false,
        };
      }
    });
    const results = await Promise.all(searchPromises);
    dispatch(setFlagSearch(false));
    dispatch(setLoadingGlobal(false));
  };

  const search = async (props?: any) => {
    dispatch(setLoadingGlobal(true));

    let pay = {
      // aois: features?.map((itemM: any) => itemM.geometry),
      aois: [activePolygon?.geometry],
      date_time: [
        commonFilter?.timeFrom.valueOf(),
        commonFilter?.timeTo.valueOf(),
      ],
      cloud_cover: commonFilter?.cloudCover,
      resolution: commonFilter?.resolution,
      incidence_angle: commonFilter?.incidenceAngle,
      // @ts-ignore
      page_number: searchState[`page_${providerId}`],
      page_size: 50,
      provider_id: providerId,
      image_type: typeImage,
    };

    try {
      // @ts-ignore
      let resSearch = await dispatch(searchAsync(pay)).unwrap();

      dispatch(
        setDataImagesProvider({
          id: providerId,
          data: dataImages.concat(resSearch?.data?.data?.features),
          // @ts-ignore
          page: searchState[`page_${providerId}`] + 1,
          nextLink: undefined,
          firstLink: undefined,
          total: resSearch?.data?.data?.total_record,
        })
      );

      dispatch(
        setDataImages(dataImages.concat(resSearch?.data?.data?.features ?? []))
      );

      dispatch(setLoadingGlobal(false));
    } catch (error) {
      dispatch(setLoadingGlobal(false));
    }
  };
  const handleInfoImage = (image: any) => {
    setImageInfo(image);
    toggle();
  };
  const handleAddCart = async (itemImage: string[], e?: any) => {
    dispatch(setLoadingGlobal(true));
    dispatch(setLoadingProvider({ id: providerId, value: false }));
    // setSearchingImages(true);
    e?.stopPropagation();
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      addImgIntoCartAsync({
        image_ids: itemImage,
      })
    ).unwrap();

    if (res?.status === ESttausCode.OK || res?.status === ESttausCode.CREATED) {
      dispatch(
        displayNotification({
          type: "success",
          content: "Add image to cart successfully",
        })
      );

      let arr = arrIdImage;

      let result = arr.concat(itemImage);

      dispatch(setArrIdImage(result));
      setSelectedImages([]);
      setSelectAll(false);
    } else {
      dispatch(
        displayNotification({
          type: "error",
          content: res?.response?.data?.message ?? "Add image to cart fail",
        })
      );
    }

    dispatch(setLoadingGlobal(false));
    dispatch(setLoadingProvider({ id: providerId, value: true }));

    // setSearchingImages(false);
  };

  const deleteMultiImages = async (arr: any) => {
    dispatch(setLoadingGlobal(true));
    dispatch(setLoadingProvider({ id: providerId, value: false }));

    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      delImgIntoCartAsync({
        // imageIds: arr.map((itemM: any) => itemM.id),
        image_ids: arr,
      })
    ).unwrap();

    if (res?.status === ESttausCode.OK) {
      dispatch(
        displayNotification({
          type: "success",
          content: "Delete image from cart successfully",
        })
      );

      let arrIdImageClone = arrIdImage;

      let result = arrIdImageClone.filter(
        (itemFilter: any) => !arr.some((image: any) => image === itemFilter)
      );

      dispatch(setArrIdImage(result));
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      setSelectedImages([]);
      setSelectAll(false);
    } else {
      dispatch(setLoadingProvider({ id: providerId, value: true }));

      dispatch(
        displayNotification({
          type: "error",
          content: "Delete image from cart faild",
        })
      );
    }

    dispatch(setLoadingGlobal(false));
  };
  const renderHeaderDrawer = () => {
    let elementsMatch = selectedImages?.filter((value) =>
      arrIdImage?.includes(value)
    );

    const handleActionCart = () => {
      if (elementsMatch.length === selectedImages.length) {
        setImagesSelected({
          title: "Remove images selected",
          subTitle: "Are you sure you want to remove all images selected ?",
          images: selectedImages,
          acction: "remove",
        });
        toggleConfirmRemoveCart();

        return;
      } else {
        let imageAdd: any = [];
        selectedImages.forEach((element) => {
          if (!elementsMatch.includes(element)) {
            imageAdd.push(element);
          }
        });
        setImagesSelected({
          title: "Add images selected",
          subTitle: "Are you sure you want to add all images selected ?",
          images: imageAdd,
          acction: "add",
        });
        toggleConfirmRemoveCart();
      }
    };
    return (
      <div className="flex justify-between items-center py-1">
        <div className="flex items-center gap-3 text-sm font-normal text-common-white">
          <div className="scale-125">
            <CameraOutlined size={16} />
          </div>

          <div className="flex items-center gap-3">
            <Checkbox
              checked={selectAll}
              onChange={onSelectAll}
              indeterminate={
                selectedImages.length > 0 &&
                selectedImages.length < dataImages.length
              }
              className="text-sm text-common-white font-normal w-fit"
            >
              All
            </Checkbox>
          </div>
        </div>

        <div className="flex items-center gap-2">
          {/* <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="btn-action-item !py-2 !px-[10px] !rounded hover:!bg-[#272D3C] !bg-[#272D3C]"
          >
            <Button
              icon={<CommonIcons.ArrowDownIcon />}
              iconPosition="end"
              className={`${buttonTheme.className.standard} !text-xs !h-7 `}
            >
              <img className="!w-4" src={SortSvg} />
            </Button>
          </Dropdown> */}
          <ButtonIconComp
            item={{
              description: "View all on map",

              icon: FootSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            disabled={selectedImages?.length === 0}
            onClick={showAllInMap}
          />

          <ButtonIconComp
            item={{
              description:
                elementsMatch.length === selectedImages.length
                  ? "Delete all from cart"
                  : "Add all to cart",
              icon: CartSvg,
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
            onClick={(e: any) => {
              handleActionCart();
            }}
            disabled={selectedImages?.length <= 0}
          />

          <ButtonIconComp
            item={{
              description: "Close",
              icon: CloseSvg,
            }}
            onClick={() => {
              dispatch(resetState());
              dispatch(resetLeftDrawer());
            }}
            className="!h-7 !w-[30px] !min-w-[30px] !rounded !bg-[#272D3C]"
          />
        </div>
      </div>
    );
  };

  const onShowInMap = (e: any, item: any) => {
    dispatch(setLoadingGlobal(true));
    e?.stopPropagation();
    let selectedCopy: Array<any> =
      selected && selected.length ? selected.slice() : [];
    let index = selectedCopy?.findIndex((x) => x.id === item?.id);

    if (index < 0) selectedCopy.push(item);
    else selectedCopy.splice(index, 1);

    dispatch(setSelected(selectedCopy));
    dispatch(setLoadingGlobal(false));
  };

  const renderActionItem = (item?: any) => {
    let providerName = arrProviders
      ?.filter((itemF: any) => itemF?.id === providerId)?.[0]
      ?.name.toLowerCase();
    let imageInCard = arrIdImage?.find(
      (itemImage: any) => itemImage === item?.properties[`${providerName}:id`]
    );
    const arrAction = [
      {
        id: 1,
        description: "Show on map",
        icon: FootSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          onShowInMap(e, item);
        },
      },
      {
        id: 2,
        description: imageInCard ? "Delete from cart" : "Add to cart",
        icon: CartSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          // deleteMultiImages([item?.properties?.[`${providerName}:id`]])
          if (imageInCard) {
            toggleConfirmRemoveCart();
            setImagesSelected({
              title: "Remove image",
              subTitle: "Are you sure you want to remove this image ?",
              images: [item?.properties?.[`${providerName}:id`]],
              acction: "remove",
            });
          } else {
            toggleConfirmRemoveCart();
            setImagesSelected({
              title: "Add image",
              subTitle: "Are you sure you want to add this image ?",
              images: [item?.properties?.[`${providerName}:id`]],
              acction: "add",
            });
            // handleAddCart(e, [item?.properties?.[`${providerName}:id`]]);
          }
        },
      },
      {
        id: 3,
        description: "Pan to",
        icon: PositionSvg,
        onClick: (e: any) => {
          e?.stopPropagation();
          panToAndExtend(e, item, map);
        },
      },
    ];

    return (
      <div className="flex gap-2">
        {arrAction?.map((itemM: any, ind: any) => {
          let classImage = "";
          switch (itemM?.id) {
            case 1:
              classImage = selected.map((x: any) => x?.id)?.includes(item?.id)
                ? " btn-action-active"
                : "";
              break;

            case 2:
              classImage = !!imageInCard ? " btn-action-active" : "";
              break;
            default:
              break;
          }
          return (
            <ButtonIconComp
              key={ind}
              item={{ description: itemM?.description, icon: itemM?.icon }}
              onClick={(e: any) => itemM?.onClick(e)}
              className={classImage}
            />
          );
        })}
      </div>
    );
  };

  const onSelectAll = (e: CheckboxChangeEvent) => {
    const value = e.target.checked;
    const providerActive = arrProviders?.find(
      (item: any) => item?.id === providerId
    );
    const _imgs: any[] = [];
    setSelectAll(value);

    if (value) {
      _imgs.push(
        ...dataImages?.map((img: any) => {
          return `${
            img?.properties?.[`${providerActive?.name.toLowerCase()}:id`]
          }`;
        })
      );
    }

    setSelectedImages(_imgs);
  };

  const onSelectImage = (e: any, value: any, item: any) => {
    const providerActive = arrProviders?.find(
      (item: any) => item?.id === providerId
    );
    const _imgs = JSON.parse(JSON.stringify(selectedImages)) as string[];

    if (!item.id) {
      return;
    }

    if (
      _imgs.includes(
        `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
      )
    ) {
      setSelectedImages(
        _imgs.filter((img) => {
          return (
            img !==
            `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
          );
        })
      );
      return;
    }

    _imgs.push(
      `${item?.properties?.[`${providerActive?.name.toLowerCase()}:id`]}`
    );

    setSelectedImages(_imgs);
  };
  const renderListImage = () => {
    return dataImages?.map((item: any, ind: any) => {
      let urlImage = item?.links?.filter(
        (item: any) => item?.rel === "thumbnail"
      )?.[0]?.href;

      let providerName = arrProviders
        ?.filter((itemF: any) => itemF?.id === providerId)?.[0]
        ?.name.toLowerCase();

      return (
        <div
          key={ind}
          className="div-item relative"
          onMouseLeave={() => onMouseLeaveItem(item, map, sourceId)}
          onMouseEnter={() => onMouseEnterItem(item, map, sourceId)}
        >
          <img
            src={urlImage}
            loading="lazy"
            onError={(e: any) => {
              e.target.src = ImageDefault;
            }}
            className="!max-h-[310px]"
            // onLoad={(e: any) => {
            //   e.target.src = urlImage;
            // }}
          />
          <Checkbox
            className="text-sm text-common-white font-normal w-fit absolute top-1 left-[5px] z-10"
            checked={selectedImages.includes(
              `${item?.properties?.[`${providerName}:id`]}`
            )}
            onClick={(e: any) => e?.stopPropagation()}
            onChange={(e: any) => {
              onSelectImage(e, e.target.value, item);
            }}
          />
          <div
            className="div-des-item relative"
            onClick={(e: any) => {
              handleInfoImage(item);
            }}
          >
            <span className="text-title-item">{item?.id}</span>
            <div className="flex justify-between items-center text-des-item mt-2">
              <span>
                {moment(item?.properties.datetime).format(
                  "HH:mm:ss DD/MM/YYYY"
                )}
              </span>

              {item?.properties["eo:cloud_cover"] && (
                <span className="flex sub-des">
                  {item?.properties["eo:cloud_cover"] &&
                    (item?.properties["eo:cloud_cover"].toFixed(1) ?? 0)}{" "}
                  % <img src={CloudSvg} className="ml-1" />
                </span>
              )}
            </div>
            <div className="div-des-item-more mt-2 mb-3">
              {RenderMoreDesItem({
                data: [
                  typeImage !== ETypeImage.SAR
                    ? {
                        icon: CloudSvg,
                        val: item?.properties["eo:cloud_cover"]
                          ? item?.properties["eo:cloud_cover"]?.toFixed(1)
                          : 0,
                        label: "eo:cloud_cover",
                      }
                    : null,
                  {
                    icon: Icon1,
                    val: item?.properties?.platform,
                    label: "Platform",
                  },
                  {
                    icon: Icon2,
                    val: item?.properties?.gsd,
                    label: "gsd",
                  },
                  {
                    icon: Icon3,
                    val: item?.properties["view:off_nadir"].toFixed(1),
                    label: "view:off_nadir",
                  },
                  {
                    icon: Icon4,
                    val: item?.properties["view:sun_azimuth"].toFixed(1),
                    label: "view:sun_azimuth",
                  },
                  {
                    icon: Icon5,
                    val: item?.properties["sar:polarizations"],
                    label: "sar:polarizations",
                  },
                ],
              })}
            </div>

            <div className="div-action-item absolute bottom-4">
              {renderActionItem(item)}
            </div>
          </div>
        </div>
      );
    });
  };
  const handleChangeProvider = (item: any) => {
    //  @ts-ignore
    if (searchState[`loading_provider_${item.id}`]) {
      const targetDiv: any = document.getElementById("resultList");
      if (targetDiv) targetDiv.scrollIntoView({ behavior: "smooth" });
      dispatch(setLoadingProvider({ id: item.id, value: false }));
      dispatch(setProviderId(item?.id));
      setClickChangeProvider(true);

      dispatch(
        //  @ts-ignore
        setDataImages(searchState[`data_images_${item.id}`])
      );

      dispatch(setLoadingProvider({ id: item.id, value: true }));
      dispatch(setSelected([]));
      setClickChangeProvider(false);
      return;
    }
  };

  const renderProvider = () => {
    let a = arrProviders?.map((item: any, ind: any) => {
      let url = item?.metadatas?.filter(
        (itemF: any) => itemF?.key === "LOGO"
      )?.[0]?.value;

      return (
        <Tooltip title={item?.name}>
          <Spin
            style={{
              //  @ts-ignore
              cursor: searchState[`loading_provider_${item.id}`]
                ? "default"
                : "no-drop",
            }}
            //  @ts-ignore
            spinning={!searchState[`loading_provider_${item.id}`]}
          >
            <div
              key={ind}
              className={
                "h-[68px] w-[68px] min-h-[68px] div-one-center !justify-center !max-h-[68px] " +
                (providerId === item?.id ? "active-provider-item" : "")
              }
              style={{
                //  @ts-ignore
                cursor: searchState[`loading_provider_${item.id}`]
                  ? "pointer"
                  : "no-drop",
              }}
              onClick={() => {
                handleChangeProvider(item);
              }}
            >
              <div
                className={"div-provider-item relative"}
                style={{
                  background: `url(${url})`,
                  backgroundSize: "cover",
                  //  @ts-ignore
                  cursor: searchState[`loading_provider_${item.id}`]
                    ? "pointer"
                    : "no-drop",
                }}
              >
                {(item?.total > 0 ||
                  // @ts-ignore
                  searchState[`total_images_${item.id}`] > 0) && (
                  <div
                    className="div-total absolute bottom-0 div-one-center"
                    style={{
                      //  @ts-ignore
                      cursor: searchState[`loading_provider_${item.id}`]
                        ? "pointer"
                        : "no-drop",
                    }}
                  >
                    {/* @ts-ignore */}
                    {searchState[`total_images_${item.id}`] ?? item?.total}
                  </div>
                )}
              </div>
            </div>
          </Spin>
        </Tooltip>
      );
    });

    return a;
  };

  const showAllInMap = () => {
    const arr = dataImages?.filter((itemF: any) => {
      let id: any;

      for (const key in itemF?.properties) {
        if (key.endsWith(":id")) {
          id = itemF?.properties[key];
          break;
        }
      }

      return selectedImages?.includes(id);
    });

    dispatch(setSelected(arr));
  };

  const getDataInmagsByNextLink = async (link: any) => {
    if (!link) return;

    try {
      dispatch(setLoadingGlobal(true));

      const res = await axios.get(link);

      const newArr = [...dataImages, ...(res?.data?.data?.features || [])];
      const links = res?.data?.data?.links;
      dispatch(setDataImages(newArr));

      dispatch(
        setDataImagesProvider({
          id: providerId,
          data: newArr,
          page: 1,
          nextLink: links?.find((item: any) => item?.rel === "next")?.href,
          firstLink: links?.find((item: any) => item?.rel === "first")?.href,
          total: newArr?.length,
        })
      );
    } catch (error) {
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      console.error("Error fetching images:", error);
    } finally {
      dispatch(setLoadingProvider({ id: providerId, value: true }));
      dispatch(setLoadingGlobal(false));
    }
  };

  const handleScroll = (e: any) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    // Kiểm tra nếu đã cuộn xuống cuối danh sách

    if (clickChangeProvider) return;
    if (scrollTop + clientHeight >= scrollHeight && scrollTop > 0) {
      // @ts-ignore
      let nextLink = searchState[`next_link_${providerId}`];
      // @ts-ignore
      let firstLink = searchState[`first_link_${providerId}`];

      if (nextLink && nextLink !== "" && nextLink !== firstLink) {
        dispatch(setLoadingGlobal(true));
        dispatch(setLoadingProvider({ id: providerId, value: false }));
        getDataInmagsByNextLink(nextLink);
      } else {
        // @ts-ignore
        let total = searchState[`total_images_${providerId}`];

        if (total > dataImages.length) {
          dispatch(setLoadingProvider({ id: providerId, value: false }));
          dispatch(setLoadingGlobal(true));
          search();
        }
      }
    }
  };

  useEffect(() => {
    if (flagSearch) {
      searchAllProviders();
    }
  }, [flagSearch]);

  useEffect(() => {
    setSelectedImages([]);
    setSelectAll(false);
    dispatch(setSelected([]));
  }, [typeImage, providerId]);

  return (
    <div className="h-full p-3 pt-0 overflow-hidden !w-[460px]">
      <div className="w-full sticky top-0 z-10 bg-common-ink100 py-3 h-full">
        {renderHeaderDrawer()}

        <Divider className="bg-common-line my-2" />

        <div
          className="flex gap-2 mt-4 justify-between"
          style={{ height: `calc(100% - 36px)` }}
        >
          <div
            className="overflow-auto relative h-full pr-2 !overflow-x-hidden div-show"
            onScroll={handleScroll}
            style={{
              overflowY: !loading ? "hidden" : "unset",
            }}
          >
            <div id="resultList" />

            <Spin className="div-spin h-full" spinning={!loading}>
              <div className="relative div-list-image h-full gap-y-4">
                {dataImages?.length === 0 && loading ? (
                  <div className="py-5 text-center text-common-white">
                    No search results found!
                  </div>
                ) : null}
                {dataImages?.length > 0 && renderListImage()}
              </div>
            </Spin>
          </div>
          <div className="div-list-provider !justify-start div-one-center parent">
            <div className="child gap-3">{renderProvider()}</div>
          </div>
        </div>
      </div>

      {shouldRender && (
        <CommonComponents.DrawerUI open={open} placement="right" width={460}>
          <ImageInformation hidden={false} item={imageInfo} onClose={toggle} />
        </CommonComponents.DrawerUI>
      )}

      {shouldRenderConfirmRemoveCart && (
        <CommonComponents.ModalUI
          open={openConfirmRemoveCart}
          toggle={toggleConfirmRemoveCart}
          handleConfirm={() => {
            if (imagesSelected && imagesSelected.acction === "remove") {
              deleteMultiImages(imagesSelected.images);
              toggleConfirmRemoveCart();
            } else {
              handleAddCart(imagesSelected.images as any);
              toggleConfirmRemoveCart();
            }
          }}
          title={imagesSelected.title}
        >
          <div className="text-common-white">{imagesSelected.subTitle}</div>
        </CommonComponents.ModalUI>
      )}
    </div>
  );
};

export default DrawerListImagesSeacrhComp;
