import React from "react";
import { Navigate } from "react-router-dom";
import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";
import { baseRoutes } from "../router/RouterPage";
import { ERoleAccessControl } from "../enums";
import Page404 from "../components/404Page";

interface IProps {
  children?: React.ReactNode;
}
const RoleAccessControlProvider = (props: IProps) => {
  //! state
  const { children } = props;
  const roleAdmin = CheckRoleAccessControlAdmin();

  //!function
  if (!roleAdmin?.includes(ERoleAccessControl.admin)) {
    return <Page404 />;
  }

  //! render
  return <>{children}</>;
};

export default RoleAccessControlProvider;
