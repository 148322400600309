import React from "react";

export default function StorageIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00107 4.21046C11.3143 4.21046 14.0002 3.37985 14.0002 2.35523C14.0002 1.33062 11.3143 0.5 8.00107 0.5C4.68785 0.5 2.00195 1.33062 2.00195 2.35523C2.00195 3.37985 4.68785 4.21046 8.00107 4.21046Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 3.52706V5.94079C13.9999 6.96557 11.3143 7.7966 8.00079 7.7966C4.68732 7.7966 2 6.96557 2 5.94079V3.47629C2.05905 4.48531 4.7213 5.29767 7.99745 5.29767C11.2123 5.29767 13.8361 4.51508 13.9893 3.5329L13.9999 3.52706Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 7.07064V9.48437C13.9999 10.5092 11.3143 11.3402 8.00079 11.3402C4.68732 11.3402 2 10.5092 2 9.48437V7.01987C2.04902 8.0359 4.71629 8.85292 8.00079 8.85292C11.2246 8.85292 13.854 8.06624 13.9938 7.07998L13.9999 7.07064Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 10.5663V13.6442C13.9999 14.669 11.3143 15.5 8.00079 15.5C4.68732 15.5 2 14.669 2 13.6442V10.5156C2 10.5156 2 10.5185 2.00334 10.5196C2.09749 11.5188 4.74581 12.32 7.99912 12.32C11.1873 12.32 13.7927 11.5509 13.9865 10.5798L13.9999 10.5663Z"
        fill="currentColor"
      />
    </svg>
  );
}
