const SettingIcon = () => {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4 6.14667C12.1934 6.14667 11.7 5.29333 12.3 4.24667C12.6467 3.64 12.44 2.86667 11.8334 2.52L10.68 1.86C10.1534 1.54666 9.47337 1.73333 9.16004 2.26L9.08671 2.38666C8.48671 3.43333 7.50004 3.43333 6.89337 2.38666L6.82004 2.26C6.52004 1.73333 5.84004 1.54666 5.31337 1.86L4.16004 2.52C3.55337 2.86667 3.34671 3.64667 3.69337 4.25333C4.30004 5.29333 3.80671 6.14667 2.60004 6.14667C1.90671 6.14667 1.33337 6.71333 1.33337 7.41333V8.58667C1.33337 9.28 1.90004 9.85333 2.60004 9.85333C3.80671 9.85333 4.30004 10.7067 3.69337 11.7533C3.34671 12.36 3.55337 13.1333 4.16004 13.48L5.31337 14.14C5.84004 14.4533 6.52004 14.2667 6.83337 13.74L6.90671 13.6133C7.50671 12.5667 8.49337 12.5667 9.10004 13.6133L9.17337 13.74C9.48671 14.2667 10.1667 14.4533 10.6934 14.14L11.8467 13.48C12.4534 13.1333 12.66 12.3533 12.3134 11.7533C11.7067 10.7067 12.2 9.85333 13.4067 9.85333C14.1 9.85333 14.6734 9.28666 14.6734 8.58667V7.41333C14.6667 6.72 14.1 6.14667 13.4 6.14667ZM8.00004 10.1667C6.80671 10.1667 5.83337 9.19333 5.83337 8C5.83337 6.80666 6.80671 5.83333 8.00004 5.83333C9.19337 5.83333 10.1667 6.80666 10.1667 8C10.1667 9.19333 9.19337 10.1667 8.00004 10.1667Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default SettingIcon;
