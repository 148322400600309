import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { IResponse, IResponsePage } from "../../../types/common";
import { IOrderTaskingUser } from "../interfaces/IOrderTaskingUser.interface";
import orderTaskingService from "../orderTasking.service";
import { useDispatch } from "react-redux";
import { setTotalPage } from "../../../store/reducers/appCommonSlice";

const useGetOrderTaskingUser = (enabled = true, filter: any) => {
  const dispatch = useDispatch();
  const [data, setData] =
    useState<IResponse<IResponsePage<IOrderTaskingUser>>>();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>("");

  const callApi = useCallback(() => {
    return orderTaskingService.GetOrderTasking(filter);
  }, []);

  const transformResponse = useCallback(
    (response: AxiosResponse<IResponse<IResponsePage<IOrderTaskingUser>>>) => {
      if (response) {
        setData(response?.data);
        dispatch(setTotalPage(response?.data?.data?.total_page));
      }
    },
    []
  );

  const refetch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await callApi();
      transformResponse(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    (async () => {
      try {
        setLoading(true);
        const response = await callApi();
        transformResponse(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [enabled]);

  return { data, error, isLoading, refetch };
};

export default useGetOrderTaskingUser;
