import axios from "axios";
import { apis } from "../constants/apis";
import httpService from "./httpService";

export async function addImgIntoCart(payload: any) {
  return httpService.post(`${apis.cart.addImage}`, payload);
}

export async function delImgIntoCart(payload: any) {
  return httpService.post(`${apis.cart.delImage}`, payload);
}

export async function getImgs(payload: any) {
  return httpService.get(`${apis.cart.getImage}`, payload);
}

export async function getProductBundleByItemType(payload: any) {
  return httpService.get(
    `${apis.cart.getProductBunbleByItemType}/${payload?.itemType}`
  );
}
