import React from "react";
import { Button, ButtonProps } from "antd";
import { buttonTheme } from "../../../themes/commonTheme";

interface IProps extends ButtonProps {
  commonType?: "default" | "standard" | "subtle";
}

export default function ButtonUI({
  commonType = "default",
  children,
  className,
  ...res
}: IProps) {
  let _className = "";
  switch (commonType) {
    case "default":
      _className = buttonTheme.className.default;
      break;

    case "standard":
      _className = buttonTheme.className.standard;
      break;

    case "subtle":
      _className = buttonTheme.className.subtle;
      break;

    default:
      break;
  }

  return (
    <Button className={`${_className} ${className}`} {...res}>
      {children}
    </Button>
  );
}
