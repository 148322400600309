import DmsCoordinates from "dms-conversion";
import { CoordinateDisplayType } from "../enums";
import { MouseCoordinate } from "../types/map";

/**
 * Parses a Degrees Minutes Seconds string into a Decimal Degrees number.
 * @param {string} dmsStr A string containing a coordinate in either DMS or DD format.
 * @return {Number} If dmsStr is a valid coordinate string, the value in decimal degrees will be returned. Otherwise NaN will be returned.
 */
export const parseDms = (dmsStr: string) => {
  const dmsRe = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′ʹ:]?\s?(?:(\d+(?:\.\d+)?)["″ʺ]?)?)?\s?([NSEW])?/i;
  let output = NaN;
  const dmsMatch = dmsRe.exec(dmsStr);
  if (dmsMatch) {
    const degrees = Number(dmsMatch[1]);
    const minutes = typeof dmsMatch[2] !== "undefined" ? Number(dmsMatch[2]) / 60 : 0;
    const seconds = typeof dmsMatch[3] !== "undefined" ? Number(dmsMatch[3]) / 3600 : 0;
    const hemisphere = dmsMatch[4] || null;
    if (hemisphere !== null && /[SW]/i.test(hemisphere)) {
      output = -Math.abs(degrees) - minutes - seconds;
    } else {
      output = degrees + minutes + seconds;
    }
  }
  return output;
};

export const convertCoordinate: (fromType: CoordinateDisplayType, toType: CoordinateDisplayType, coordinate: MouseCoordinate) => MouseCoordinate = (
  fromType: CoordinateDisplayType,
  toType: CoordinateDisplayType,
  coordinate: MouseCoordinate
) => {
  if (fromType === CoordinateDisplayType.DD && toType === CoordinateDisplayType.DMS && coordinate) {
    const long = coordinate.lng;
    const lat = coordinate.lat;

    const dmsCoords = new DmsCoordinates(Number(lat), Number(long));
    return { lng: dmsCoords.longitude.toString(), lat: dmsCoords.latitude.toString() };
  }

  if (fromType === CoordinateDisplayType.DMS && toType === CoordinateDisplayType.DD && coordinate) {
    const dmsStrings = [coordinate.lat, coordinate.lng];
    const dmsCoords = dmsStrings.map((dmsStr) => parseDms(dmsStr ?? ""));

    return { lng: dmsCoords[0].toString(), lat: dmsCoords[1].toString() };
  }

  return { lng: coordinate.lng ?? "0", lat: coordinate.lat ?? "0" };
};

export const getMouseCoordinateDisplayType = (type_element_id: string) => {
  let type: string = document.getElementById(type_element_id)?.innerText ?? "";
  return type as CoordinateDisplayType;
};

export const getCurrentMouseCoordinate = (lng_element_id: string, lat_element_id: string) => {
  let lng: string = document.getElementById(lng_element_id)?.innerText ?? "0";
  let lat: string = document.getElementById(lat_element_id)?.innerText ?? "0";

  return { lng: lng, lat: lat };
};

export const displayMouseCoordinate = (
  lng_element_id: string,
  lat_element_id: string,
  fromType: CoordinateDisplayType,
  toType: CoordinateDisplayType,
  coordinate: MouseCoordinate
) => {
  let lng_e: HTMLElement | null = document.getElementById(lng_element_id);
  let lat_e: HTMLElement | null = document.getElementById(lat_element_id);

  // convert coordinate
  const _coordinate = convertCoordinate(fromType, toType, coordinate);

  if (lng_e) {
    lng_e.innerText = _coordinate.lng ?? "";
  }

  if (lat_e) {
    lat_e.innerText = _coordinate.lat ?? "";
  }
};
