import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface INotificationState {
  type: "success" | "error" | "warning" | "info" | "loading";
  content: string;
  status: boolean;
}

const initialState: INotificationState = {
  type: "success",
  content: "",
  status: false,
};

export const notificationSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    displayNotification: (
      state,
      action: PayloadAction<{
        type: "success" | "error" | "warning" | "info" | "loading";
        content: string;
      }>
    ) => {
      state.type = action?.payload.type;
      state.content = action?.payload.content;
      state.status = !state.status;
    },
  },
});

export const { displayNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
