import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getMyProjects } from "../../services/project/projectServices";
import { getHistoryByUser } from "../../services/searchImages/searchImageService";

interface ISearchState {
  dataImages?: any;
  nextLink?: any | string;
  firstLink?: any | string;
  page?: any | number;
  isSearching: boolean;
  isFilter?: boolean;
}

const initialState: ISearchState = {
  dataImages: [],
  nextLink: undefined,
  firstLink: undefined,
  page: 1,
  isSearching: false,
  isFilter: false,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setDataImages: (state, action: PayloadAction<any>) => {
      state.dataImages = action.payload;
    },
    setNextLink: (state, action: any) => {
      state.nextLink = action.payload;
    },
    setFirstLink: (state, action: any) => {
      state.firstLink = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    setIsFilter: (state, action: PayloadAction<boolean>) => {
      state.isFilter = action.payload;
    },
    setLoadingProvider: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state[`loading_provider_${action.payload.id}`] = action.payload.value;
    },

    setDataImagesProvider: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state[`loading_provider_${action.payload.id}`] = true;
      // @ts-ignore
      state[`data_images_${action.payload.id}`] = action.payload.data;
      // @ts-ignore
      state[`total_images_${action.payload.id}`] = action.payload.total;
      // @ts-ignore
      state[`page_${action.payload.id}`] = action.payload.page;
      // @ts-ignore
      state[`next_link_${action.payload.id}`] = action.payload.nextLink;
      // @ts-ignore
      state[`first_link_${action.payload.id}`] = action.payload.firstLink;
    },

    setTotalImagesProvider: (state, action: PayloadAction<any>) => {
      // @ts-ignore
      state[`total_images_${action.payload.id}`] = action.payload.data;
    },

    resetProviderData: (state) => {
      for (const key in state) {
        if (
          key.startsWith("loading_provider_") ||
          key.startsWith("data_images_") ||
          key.startsWith("total_images_")
        ) {
          // @ts-ignore
          state[key] = undefined;
        }
      }
    },

    activeLoadingProvider: (state) => {
      for (const key in state) {
        if (key.startsWith("loading_provider_")) {
          // @ts-ignore
          state[key] = false;
        }
      }
    },

    resetState: (state) => {
      state.page = 1;
      state.firstLink = undefined;
      state.nextLink = undefined;
      state.dataImages = [];
      state.isSearching = false;
      state.isFilter = false;

      for (const key in state) {
        if (
          key.startsWith("loading_provider_") ||
          key.startsWith("data_images_") ||
          key.startsWith("total_images_")
        ) {
          // @ts-ignore
          state[key] = undefined;
        }
      }
    },
  },
});

export const getHistoryByUserAsync = createAsyncThunk(
  "search/getHistoryByUser",
  async (payload: any) => {
    try {
      const response = await getHistoryByUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const {
  setDataImages,
  setNextLink,
  setFirstLink,
  setPage,
  resetState,
  setIsSearching,
  setIsFilter,
  setLoadingProvider,
  setDataImagesProvider,
  setTotalImagesProvider,
  activeLoadingProvider,
  resetProviderData,
} = searchSlice.actions;

export default searchSlice.reducer;
