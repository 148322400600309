import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useToggle = (initialValue?: boolean) => {
  const [open, setOpen] = useState(initialValue ?? false);

  const toggle = () => {
    setOpen(!open);
  };
  const shouldRender = open;
  return {
    open,
    toggle,
    shouldRender,
  };
};

export default useToggle;
