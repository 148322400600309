import { Button, Divider, Dropdown, MenuProps } from "antd";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonIcons from "../../assets/icons";
import { CoordinateDisplayType, MouseCoordinateElementIds } from "../../enums";
import {
  displayMouseCoordinate,
  getCurrentMouseCoordinate,
} from "../../helpers/map";
import { changeLeftDrawerType } from "../../store/reducers/drawerSlice";
import { ChangeActionActiveFooter } from "../../store/reducers/statusAction";
import { RootState } from "../../store/store";
import { buttonTheme } from "../../themes/commonTheme";
import { LeftDrawerType } from "../../types/common";
import CommonComponents from "../Commons";
import PolygonSvg from "../../assets/svg/edit-polygon.svg";

export type TActionActiveFooter = "Setting" | "Polygon";
interface IProps {
  coordinateDisplayType: CoordinateDisplayType;
  setCoordinateDisplayType: (type: CoordinateDisplayType) => void;
  onShowPolygonTemplateList: () => void;
  onShowSetting?: () => void;
  setButtonActive: any;
  buttonActive: string | null;
}

export default function Footer({
  onShowPolygonTemplateList,
  coordinateDisplayType,
  setCoordinateDisplayType,
  setButtonActive,
  buttonActive,
  onShowSetting,
}: IProps) {
  //! state
  const {
    showDrawer,
    showBottomDrawer,
    leftDrawerType,
    flagShowInfoImageInCart,
    bottomDrawerType,
  } = useSelector((state: RootState) => state.drawerSlice);

  const actionActiveFooter = useSelector(
    (state: RootState) => state.statusAction.actionActiveFooter
  );

  const dispatch = useDispatch();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: CoordinateDisplayType.DD,
      onClick: () => onChangeCoordinateDisplayType(CoordinateDisplayType.DD),
    },
    {
      key: "2",
      label: CoordinateDisplayType.DMS,
      onClick: () => onChangeCoordinateDisplayType(CoordinateDisplayType.DMS),
    },
  ];

  //! function
  const onChangeLeftDrawerType = (type: LeftDrawerType) => {
    if (leftDrawerType === type) {
      // setLeftDrawerType(undefined);
      dispatch(changeLeftDrawerType(""));
      return;
    }

    // setLeftDrawerType(type);
    dispatch(changeLeftDrawerType(type));
  };
  const onChangeCoordinateDisplayType = (type: CoordinateDisplayType) => {
    if (coordinateDisplayType === type) {
      return;
    }
    setCoordinateDisplayType(type);

    displayMouseCoordinate(
      MouseCoordinateElementIds.LNG_ID,
      MouseCoordinateElementIds.LAT_ID,
      coordinateDisplayType,
      type,
      getCurrentMouseCoordinate(
        MouseCoordinateElementIds.LNG_ID,
        MouseCoordinateElementIds.LAT_ID
      )
    );
  };
  const CheckActionActive = useCallback(
    (actionName: TActionActiveFooter) => {
      const styleActive =
        " !bg-common-activeAction hover:!bg-common-activeAction !text-black ";
      switch (actionActiveFooter) {
        case actionName:
          return styleActive;
        default:
          return "";
      }
    },
    [actionActiveFooter]
  );
  //! render
  return (
    <div className="h-14 py-2 bg-common-ink100 flex justify-between items-center pr-2">
      <div className="flex gap-2">
        {/* <Dropdown menu={{ items: items }} trigger={["click"]}>
          <Button
            icon={<CommonIcons.ArrowDownIcon />}
            iconPosition="end"
            className={`${buttonTheme.className.standard} !text-xs !h-7 !min-w-[74px] flex justify-between`}
          >
            <span id={MouseCoordinateElementIds.TYPE_ID}>
              {coordinateDisplayType}
            </span>
          </Button>
        </Dropdown>

        <Divider
          type="vertical"
          className="m-0 border-[1px] border-white border-opacity-20 h-5 top-[4px]"
        /> */}

        <div className="flex items-center gap-2 text-xs text-common-white font-normal">
          lng: <span id={MouseCoordinateElementIds.LNG_ID}></span>
          lat: <span id={MouseCoordinateElementIds.LAT_ID}></span>
        </div>
      </div>

      <div className="flex gap-2">
        <CommonComponents.TooltipUI title="Polygon" placement="top">
          <CommonComponents.ButtonUI
            icon={<img src={PolygonSvg} />}
            commonType="standard"
            className={`!text-xs !h-7 ${CheckActionActive("Polygon")}`}
            onClick={() => {
              dispatch(ChangeActionActiveFooter("Polygon"));
              onShowPolygonTemplateList();
            }}
          />
        </CommonComponents.TooltipUI>
        <CommonComponents.TooltipUI title="Setting" placement="top">
          <CommonComponents.ButtonUI
            icon={<CommonIcons.SettingIcon />}
            commonType="standard"
            className={`!text-xs !h-7 ${CheckActionActive("Setting")}`}
            onClick={() => {
              dispatch(ChangeActionActiveFooter("Setting"));
              onChangeLeftDrawerType("Setting basemap");
            }}
          />
        </CommonComponents.TooltipUI>
      </div>
    </div>
  );
}
