import { Collapse } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import { getProductBundleByItemTypeAsync } from "../../../store/reducers/cartSlice";
import CommonComponents from "../../Commons";
import CardItemConfirmComp from "../CardItemConfirm";
import { onMouseEnterItem, onMouseLeaveItem } from "../../MapTasking/ultis";
import { RootState } from "../../../store/store";

//! step only for booking image planet

const Step3 = (props: any) => {
  console.log(props.map, "props.map");
  const [productBundles, setProductBundles] = useState<{
    [key: string]: any[];
  }>({});

  const dispatch = useDispatch();

  const roleAdmin = CheckRoleAccessControlAdmin();

  const [itemTypes, setItemTypes] = useState<any>([]);

  const [itemsCollaspes, setItemsCollaspes] = useState<any>([]);

  const fetchProductBundleByItemType = async (itemType: string) => {
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      getProductBundleByItemTypeAsync({ itemType })
    ).unwrap();
    return res?.data?.data ?? [];
  };

  useEffect(() => {
    if (props.form && itemTypes) {
      const products = Object.keys(itemTypes).reduce(
        (acc: any, itemType: any) => {
          let productBundleValue = undefined;
          props?.initialValues?.forEach((initialData: any) => {
            const item_type = initialData?.item_type;
            const product_bundle = initialData?.product_bundle;

            if (item_type === itemType) {
              productBundleValue = product_bundle;
            }
          });

          acc[itemType] = {
            productBundle: productBundleValue,
          };

          return acc;
        },
        {}
      );

      props.form.setFieldsValue({
        dataPlanet: {
          products,
        },
      });
    }
  }, [itemTypes, props.form]);

  useEffect(() => {
    const fetchAllProductBundles = async () => {
      const itemsByType = props?.dataImages.reduce((acc: any, item: any) => {
        // const itemTypeMetadata = item?.metadata?.find(
        //   (meta: any) => meta.key === "item_type"
        // );

        // const itemTypeMetadata = item?.collection;
        const itemType = item?.feature?.collection;

        if (itemType) {
          if (!acc[itemType]) {
            acc[itemType] = [];
          }
          acc[itemType].push(item);
        }

        return acc;
      }, {});
      const bundles: { [key: string]: any[] } = {};

      const itemTypes = Object.keys(itemsByType ?? {});

      for (const itemType of itemTypes) {
        const data = await fetchProductBundleByItemType(itemType);
        bundles[itemType] = data;
      }
      setProductBundles(bundles);
      setItemTypes(itemsByType);
    };
    fetchAllProductBundles();
  }, [props?.dataImages]);

  useEffect(() => {
    renderChildren();
  }, [itemTypes]);

  const renderChildren = () => {
    let updatedItems = [...itemsCollaspes];

    Object.keys(itemTypes ?? {}).forEach((itemType, ind) => {
      updatedItems = updatedItems.concat({
        key: ind,
        label: (
          <span>
            {itemType} ({itemTypes[itemType]?.length} images)
          </span>
        ),
        children: (
          <div key={itemType}>
            <CommonComponents.FormFieldUI
              name={["dataPlanet", "products", itemType, "productBundle"]}
              rules={[{ required: true, message: "Please input here!" }]}
              component={
                <CommonComponents.SelectUI
                  label="Product Bundle"
                  placeholder="Product Bundle"
                  data={productBundles[itemType] || []}
                  required={true}
                  disabled={props?.readOnly}
                />
              }
            />

            {itemTypes[itemType]?.map((item: any) => (
              <CardItemConfirmComp
                item={item}
                onMouseLeaveItem={() =>
                  onMouseLeaveItem(item?.feature, props.map, props.sourceId)
                }
                onMouseEnterItem={() =>
                  onMouseEnterItem(item?.feature, props.map, props.sourceId)
                }
              />
            ))}

            <div className="hidden">
              <CommonComponents.FormFieldUI
                name={["dataPlanet", "products", itemType, "imageId"]}
                initialValue={itemTypes[itemType]?.map(
                  (itemM: any) =>
                    itemM?.feature?.properties[
                      `${itemM?.provider_name?.toLowerCase()}:id`
                    ]
                )}
                component={null}
              />
            </div>
          </div>
        ),
      });
    });

    setItemsCollaspes(updatedItems);
  };

  return (
    <div className="div-collapse-in-collapse">
      <Collapse
        bordered={false}
        expandIconPosition="end"
        items={itemsCollaspes}
        onChange={() => {}}
        activeKey={Object.keys(itemTypes)?.map((item: any, index: any) => {
          return `${index}`;
        })}
      />

      {/* <CommonComponents.FormAntdDebugs /> */}
    </div>
  );
};

export default Step3;
