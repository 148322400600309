import React from "react";

export default function ChevronLeftIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 14.002C11.5703 14.002 11.6348 13.9883 11.6934 13.9609C11.7559 13.9336 11.8086 13.8984 11.8516 13.8555C11.8984 13.8086 11.9355 13.7559 11.9629 13.6973C11.9902 13.6348 12.0039 13.5684 12.0039 13.498C12.0039 13.4238 11.9902 13.3594 11.9629 13.3047C11.9395 13.25 11.9023 13.1973 11.8516 13.1465L8.70508 10L11.8516 6.85352C11.9531 6.75195 12.0039 6.63477 12.0039 6.50195C12.0039 6.43164 11.9902 6.36719 11.9629 6.30859C11.9355 6.24609 11.8984 6.19336 11.8516 6.15039C11.8086 6.10352 11.7559 6.06641 11.6934 6.03906C11.6348 6.01172 11.5703 5.99805 11.5 5.99805C11.3633 5.99805 11.2461 6.04687 11.1484 6.14453L7.64453 9.64844C7.54688 9.74609 7.49805 9.86328 7.49805 10C7.49805 10.1367 7.54688 10.2539 7.64453 10.3516L11.1484 13.8555C11.2461 13.9531 11.3633 14.002 11.5 14.002Z"
        fill="currentColor"
        fillOpacity="0.786"
      />
    </svg>
  );
}
