import { useEffect } from "react";
import { useDispatch } from "react-redux";
import MyProjectComp from "../../components/MyProject";
import { resetLeftDrawer } from "../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";

const MyProjectPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayoutName("/manage-project"));
    dispatch(changeDataBreadcrumb([{ title: "My projects" }]));
    dispatch(resetLeftDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);

  return <MyProjectComp />;
};

export default MyProjectPage;
