import CommonIcons from "../../assets/icons";
import CommonComponents from "../Commons";
import TaskingSvg from "../../assets/svg/tasking.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  changeShowDrawer,
  resetLeftDrawer,
} from "../../store/reducers/drawerSlice";
import { RootState } from "../../store/store";

const FormOrderUserComp = () => {
  const dispatch = useDispatch();
  const selectedPolygon = useSelector(
    (state: RootState) => state.mapSlice.activeFeature
  );
  return (
    <div className="px-3 w-[460px]">
      <div className="flex items-center justify-between">
        <div className="flex gap-3">
          <div onClick={() => {}}>
            <img src={TaskingSvg} />
          </div>

          <div className="text-white text-sm">
            Order Tasking {selectedPolygon?.name}
          </div>
        </div>

        <div className="flex items-center gap-1">
          <CommonComponents.ButtonUI
            icon={<CommonIcons.DismissIcon />}
            commonType="standard"
            className="!text-xs !h-7"
            onClick={() => {
              dispatch(resetLeftDrawer());
            }}
          />
        </div>
      </div>

      <div>
        <div className="text-sm text-white">
          <p>Thanh xuan</p>
        </div>
      </div>
    </div>
  );
};

export default FormOrderUserComp;
