import { useNavigate } from "react-router-dom";
import AddNewSvg from "../../assets/svg/add-new.svg";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { baseRoutes } from "../../router/RouterPage";
import CommonComponents from "../Commons";
import ListProjectComp from "./ListProject";

const MyProjectComp = () => {
  //! state
  const navigate = useNavigate();

  //! function
  const handleNavigateAdd = () => {
    navigate(`${baseRoutes.myProject.path}/null`);
  };
  //! effect
  //! render
  return (
    <ContentLayoutManagement
      header={
        <div className="!pr-2">
          <div className="flex justify-end gap-2">
            <CommonComponents.TooltipUI title="Add new" placement="left">
              <CommonComponents.ButtonUI
                icon={<img src={AddNewSvg} />}
                commonType="standard"
                className="!text-xs !h-7 !bg-transparent"
                onClick={handleNavigateAdd}
              ></CommonComponents.ButtonUI>
            </CommonComponents.TooltipUI>
          </div>
        </div>
      }
    >
      <div className="relative w-full h-full p-6 bg-content-default">
        <ListProjectComp />
      </div>
    </ContentLayoutManagement>
  );
};

export default MyProjectComp;
