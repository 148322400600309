import { useEffect } from "react";
import { useDispatch } from "react-redux";
import HistoryUploadDataComp from "../../components/ManageData/HistoryUploadData";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  changeShowDrawer,
} from "../../store/reducers/drawerSlice";

const HistoryUploadDataPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeLayoutName("manage-data/upload-data-history"));
    dispatch(
      changeDataBreadcrumb([
        { title: "Quản lý dữ liệu" },
        {
          title: "Lịch sử upload dữ liệu",
        },
      ])
    );

    dispatch(changeLeftDrawerType(""));
    dispatch(changeShowDrawer(false));

    dispatch(changeShowBottomDrawer(false));
    dispatch(changeBottomDrawerType(""));
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
      dispatch(changeLeftDrawerType(""));
      dispatch(changeShowDrawer(false));
    };
  }, []);
  return <HistoryUploadDataComp />;
};

export default HistoryUploadDataPage;
