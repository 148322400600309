import MyDataSvg from "../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import CardManagementComp from "../Commons/CardManagement";

import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import InfoSvg from "../../assets/svg/ManageData/info.svg";
import DeleteSvg from "../../assets/svg/delete.svg";
import EditSvg from "../../assets/svg/edit.svg";
import useGetProjects from "../../services/project/hooks/useGetProjects";
import LoadingUI from "../Commons/Loading";
import NoData from "../NoData";

import { useNavigate } from "react-router-dom";
import { ESttausCode } from "../../enums";
import { ConvertTimeStamp, getErrorMsg } from "../../helpers/common";
import useToggle from "../../hooks/useToggle";
import { baseRoutes } from "../../router/RouterPage";
import projectServices from "../../services/project/projectServices";
import { displayNotification } from "../../store/reducers/notificationSlice";
import CommonComponents from "../Commons";

export interface IFilterProject {
  page: number;
  size: number;
}
const ListProjectComp = () => {
  //! state
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [idClick, setIdClick] = useState<null | number>(null);
  const [filter, setFilter] = useState<IFilterProject>({
    page: 0,
    size: 15,
  });
  const {
    open: openConfirmDelete,
    shouldRender: shouldRenderConfirmDelete,
    toggle: toggleConfirmDelete,
  } = useToggle();
  const {
    data: dataProjects,
    isLoading: loadingProject,
    refetch: refetchProjects,
  } = useGetProjects(filter);

  //! function
  const ParseDataOrder = useCallback(() => {
    if (
      dataProjects?.data?.content_page &&
      dataProjects?.data?.content_page?.length < 1
    ) {
      return [];
    }
    return dataProjects?.data?.content_page?.map((item) => {
      return {
        id: item.id,
        imgSrc: MyDataSvg,
        title: item.name,
        status: item?.status,
        des:
          item.createdAt &&
          `${ConvertTimeStamp(String(item.createdAt))} ${
            item.description ? `| ${item.description}` : ""
          }`,
      };
    });
  }, [dataProjects]);
  const handleInfo = (id: number) => {
    navigate(`${baseRoutes.myProject.path}/${id}`);
  };

  const handleConfirmDelete = async () => {
    try {
      const result = await projectServices.DeletProject(Number(idClick));
      if (result.status === ESttausCode.OK) {
        dispatch(
          displayNotification({
            type: "success",
            content: "Successfully",
          })
        );
        toggleConfirmDelete();
        refetchProjects();
      }
    } catch (error) {
      dispatch(
        displayNotification({
          type: "error",
          content: getErrorMsg(error),
        })
      );
    }
  };
  const handleEdit = (id: number) => {
    navigate(`${baseRoutes.myProject.path}/${id}?rule=edit`);
  };
  //! effect
  //! render
  const renderProjects = useCallback(() => {
    if (loadingProject) {
      return <LoadingUI />;
    }
    if (dataProjects?.data?.content_page?.length === 0) {
      return <NoData />;
    }
    return ParseDataOrder()?.map((item, index) => {
      return (
        <CardManagementComp
          key={index}
          data={item}
          onClick={() => {
            // handleInfo(item.id);
          }}
          actions={[
            <CommonComponents.TooltipUI title="Delete">
              <img
                alt="Delete"
                src={DeleteSvg}
                onClick={() => {
                  setIdClick(item.id);
                  toggleConfirmDelete();
                }}
              />
            </CommonComponents.TooltipUI>,

            <CommonComponents.TooltipUI title="Update">
              <img
                alt="EditSvg"
                src={EditSvg}
                onClick={() => {
                  handleEdit(item.id);
                }}
              />
            </CommonComponents.TooltipUI>,

            <CommonComponents.TooltipUI title="Infomation">
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  handleInfo(item.id);
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
        />
      );
    });
  }, [loadingProject]);
  return (
    <>
      {renderProjects()}

      {shouldRenderConfirmDelete && (
        <CommonComponents.ModalUI
          toggle={toggleConfirmDelete}
          title="Confirm"
          open={openConfirmDelete}
          handleConfirm={handleConfirmDelete}
        >
          <p>Are you sure want delete this project ?</p>
        </CommonComponents.ModalUI>
      )}
    </>
  );
};

export default ListProjectComp;
