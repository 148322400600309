import { Divider, Form } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import Step1 from "./Step1";
import StepTaskingOneAlats from "./StepTaskingOneAlats";

const BookingImage = (props: any) => {
  //! state
  const [form] = Form.useForm();

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  useEffect(() => {
    if (props.formRef) {
      props.formRef.current = form; // Gán form vào ref
    }
  }, [form, props.formRef]);

  return (
    <Form
      form={form}
      initialValues={props?.initialValues}
      layout="vertical"
      onFinish={async (values) => {
        props?.handleSubmitBookingImage(values);
      }}
    >
      <div
        className={
          "text-sm text-white mt-3 h-full " +
          (showOrderTasking ? " flex justify-between " : "")
        }
      >
        <Step1
          form={form}
          dataPriority={props?.dataPriority ?? []}
          valuesForm={props?.valuesForm}
          datProject={props?.datProject ?? []}
        />

        {showOrderTasking && (
          <>
            <Divider
              type="vertical"
              style={{ background: "#353B4F", margin: 0, height: "100%" }}
            />
            <StepTaskingOneAlats form={form} />
          </>
        )}
      </div>
    </Form>
  );
};

export default BookingImage;
