import React from "react";
import BreadcrumbComp from "../Breadcrumb";
import PagingComp from "../Paging";

const FooterManagementComp = () => {
  return (
    <div className="h-[56px] footer-management">
      <BreadcrumbComp />

      <PagingComp />
    </div>
  );
};

export default FooterManagementComp;
