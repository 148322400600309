import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IManageDataState {
  showDrawerInfomages: boolean;
}

const initialState: IManageDataState = {
  showDrawerInfomages: false,
};

export const manageDataSlice = createSlice({
  name: "manageData",
  initialState,
  reducers: {
    changeShowDrawerInfoImages: (state, action: PayloadAction<boolean>) => {
      state.showDrawerInfomages = action.payload;
    },
  },
});

export const { changeShowDrawerInfoImages } = manageDataSlice.actions;

export default manageDataSlice.reducer;
