import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useCallback } from "react";
import { configKey } from "../../../constants";
import { capitalizeFirstLetter } from "../../../helpers/common";

export const renderInfoOrder = (props: any) => {
  const renderOptions = () => {
    return props?.options?.map((item: any, index: number) => {
      return (
        <p key={index} hidden={!item?.value} className="flex justify-between">
          <span className="text-des">{capitalizeFirstLetter(item?.key)}:</span>
          <span className="text-right">
            {item?.value.replace(/^"|"$/g, "")}
          </span>
        </p>
      );
    });
  };

  return (
    <div className="text-white">
      <p className="flex justify-between">
        <span className="text-des">Order Name:</span>
        <span className="text-right">{props?.name}</span>
      </p>

      {props?.sensor && (
        <p className="flex justify-between">
          <span className="text-des">Sensor:</span>
          <span className="text-right">{props?.sensor}</span>
        </p>
      )}

      {props?.plan && (
        <p className="flex justify-between">
          <span className="text-des text-right">Plan:</span>{" "}
          <span>{props?.plan}</span>
        </p>
      )}

      <p className="flex justify-between">
        <span className="text-des">Project:</span>
        <span className="text-right">
          {" "}
          {props?.project?.name ?? props?.project?.id}
        </span>
      </p>
      {props?.start_date_request && (
        <p className="flex justify-between">
          <span className="text-des">Start request:</span>
          <span className="text-right"> {props?.start_date_request}</span>
        </p>
      )}

      {props?.end_date_request && (
        <p className="flex justify-between">
          <span className="text-des">End request:</span>
          <span className="text-right"> {props?.end_date_request}</span>
        </p>
      )}

      {renderOptions()}
    </div>
  );
};

export const RenderHeader = (props: any) => {
  const globalConfig = useSelector(
    (state: RootState) => state.appCommonSlice.globalConfig
  );

  let TagColor = useCallback(() => {
    return globalConfig?.options.filter(
      (item) => item.key === configKey.COLOR_BRANCH
    );
  }, [globalConfig]);
  const colorTagBranch = TagColor()?.find(
    (item: any) => item?.name === props?.data?.provider?.name
  );
  return (
    <div className="flex">
      <p style={{ fontSize: 16, fontWeight: 600 }}>
        Status:{" "}
        <span
          style={{
            color: props?.data?.current_status?.description
              ? props?.data?.current_status?.description
              : props?.data?.currentStatus?.description
              ? props?.data?.currentStatus?.description
              : "white",
          }}
        >
          {props?.data?.current_status?.value ??
            props?.data?.currentStatus?.value}
        </span>
      </p>
      {props?.data?.priority?.value && (
        <>
          <span className="text-des mx-2">|</span>
          <span style={{ color: props?.data?.priority?.description }}>
            {props?.data?.priority?.value}
          </span>
        </>
      )}

      {props?.data?.provider?.name && (
        <>
          <span className="text-des mx-2">|</span>
          <div
            className={` rounded-sm h-[20px]  px-1 py-[2px] flex flex-col justify-center items-center`}
            style={{
              color: "white",
              fontSize: "12px",
              background: colorTagBranch?.value ?? "#004E72",
            }}
          >
            {capitalizeFirstLetter(props?.data?.provider?.name)}
          </div>
        </>
      )}
    </div>
  );
};
