import React from "react";

export default function CameraIcon() {
  return (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21041 2.08689C4.21041 1.31163 4.83889 0.683151 5.61416 0.683151H8.42165C9.19691 0.683151 9.82539 1.31163 9.82539 2.08689L12.0365 2.08689C13.141 2.08689 14.0365 2.98232 14.0365 4.08689V11.3168C14.0365 12.4214 13.141 13.3168 12.0365 13.3168H1.99902C0.894452 13.3168 -0.000976562 12.4214 -0.000976562 11.3168V4.08689C-0.000976562 2.98232 0.894454 2.08689 1.99902 2.08689L4.21041 2.08689ZM12.6319 3.49063H1.40196V11.9131H12.6319V3.49063ZM7.01769 10.5094C8.56823 10.5094 9.82518 9.2524 9.82518 7.70187C9.82518 6.15134 8.56823 4.89438 7.01769 4.89438C5.46716 4.89438 4.21021 6.15134 4.21021 7.70187C4.21021 9.2524 5.46716 10.5094 7.01769 10.5094ZM7.01764 9.10474C6.24262 9.10474 5.61433 8.47646 5.61433 7.70143C5.61433 6.92641 6.24262 6.29812 7.01764 6.29812C7.79267 6.29812 8.42095 6.92641 8.42095 7.70143C8.42095 8.47646 7.79267 9.10474 7.01764 9.10474ZM11.2284 4.19251C10.8408 4.19251 10.5265 4.50675 10.5265 4.89438C10.5265 5.28202 10.8408 5.59625 11.2284 5.59625C11.616 5.59625 11.9303 5.28202 11.9303 4.89438C11.9303 4.50675 11.616 4.19251 11.2284 4.19251Z"
        fill="currentColor"
      />
      <mask id="mask0_25_14033" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="-1" y="0" width="16" height="14">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21041 2.08689C4.21041 1.31163 4.83889 0.683151 5.61416 0.683151H8.42165C9.19691 0.683151 9.82539 1.31163 9.82539 2.08689L12.0365 2.08689C13.141 2.08689 14.0365 2.98232 14.0365 4.08689V11.3168C14.0365 12.4214 13.141 13.3168 12.0365 13.3168H1.99902C0.894452 13.3168 -0.000976562 12.4214 -0.000976562 11.3168V4.08689C-0.000976562 2.98232 0.894454 2.08689 1.99902 2.08689L4.21041 2.08689ZM12.6319 3.49063H1.40196V11.9131H12.6319V3.49063ZM7.01769 10.5094C8.56823 10.5094 9.82518 9.2524 9.82518 7.70187C9.82518 6.15134 8.56823 4.89438 7.01769 4.89438C5.46716 4.89438 4.21021 6.15134 4.21021 7.70187C4.21021 9.2524 5.46716 10.5094 7.01769 10.5094ZM7.01764 9.10474C6.24262 9.10474 5.61433 8.47646 5.61433 7.70143C5.61433 6.92641 6.24262 6.29812 7.01764 6.29812C7.79267 6.29812 8.42095 6.92641 8.42095 7.70143C8.42095 8.47646 7.79267 9.10474 7.01764 9.10474ZM11.2284 4.19251C10.8408 4.19251 10.5265 4.50675 10.5265 4.89438C10.5265 5.28202 10.8408 5.59625 11.2284 5.59625C11.616 5.59625 11.9303 5.28202 11.9303 4.89438C11.9303 4.50675 11.616 4.19251 11.2284 4.19251Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_25_14033)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 14H14V0H0V14Z" fill="currentColor" />
      </g>
    </svg>
  );
}
