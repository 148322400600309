import { apis } from "../../constants/apis";
import httpService from "../httpService";

class OrderTaskingService {
  GetOrderTasking(payload: any) {
    return httpService.get(apis.orderTasking.orderUser, {
      params: payload,
    });
  }

  GetOrderTaskingAdmin(payload: any) {
    return httpService.get(apis.orderTasking.orderAdmin, {
      params: payload,
    });
  }
}

export default new OrderTaskingService();
