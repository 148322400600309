import ButtonUI from "./Button";
import CheckboxUI from "./CheckboxUI";
import DatePickerUI from "./DatePicker";
import DrawerUI from "./Drawer";
import DropdownUI from "./Dropdown";
import FormAntdDebugs from "./FormAntdDebugs";
import FormFieldUI from "./FormField";
import InfoItemUI from "./InfoItem";
import InputUI from "./Input";
import ModalUI from "./Modal";
import RadioUI from "./Radio";
import SearchResultItemUI from "./SearchResultItem";
import SelectUI from "./Select";
import StepUI from "./Step";
import TextAreaUI from "./TextArea";
import TooltipUI from "./Tooltip";
import UploadItemCardUI from "./UploadItemCard";

const CommonComponents = {
  ButtonUI,
  DatePickerUI,
  DrawerUI,
  DropdownUI,
  InfoItemUI,
  InputUI,
  SearchResultItemUI,
  SelectUI,
  TextAreaUI,
  UploadItemCardUI,
  TooltipUI: TooltipUI,
  RadioUI: RadioUI,
  FormAntdDebugs: FormAntdDebugs,
  FormFieldUI: FormFieldUI,
  StepUI: StepUI,
  ModalUI: ModalUI,
  CheckboxUI: CheckboxUI,
};

export default CommonComponents;
