import React from "react";

export default function UploadPolygonIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.96 5.93332C13.36 6.13999 14.34 7.37332 14.34 10.0733V10.16C14.34 13.14 13.1467 14.3333 10.1667 14.3333H5.82665C2.84665 14.3333 1.65332 13.14 1.65332 10.16V10.0733C1.65332 7.39332 2.61999 6.15999 4.97999 5.93999"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 10V2.41333" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.2333 3.89999L7.99994 1.66666L5.7666 3.89999" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
