import React, { useState } from "react";
import bbox from "@turf/bbox";
import area from "@turf/area";
import length from "@turf/length";
import { decimalToDMS } from "./ultis";
import CommonComponents from "../Commons";
import { Select } from "antd";

const PolygonInfo = (props: any) => {
  console.log(props, "props PolygonInfo");
  const [format, setFormat] = useState("lat, lng degrees");

  if (!props?.selectedPolygon?.id) {
    return <p>No polygon data available.</p>;
  }

  const outArea = area(props?.selectedPolygon);
  const perimeter = length(props?.selectedPolygon, { units: "kilometers" });

  let output;
  if (outArea > 10000) {
    output = Math.round((outArea / 1000000) * 100) / 100 + " km²";
  } else {
    output = Math.round(outArea * 100) / 100 + " m²";
  }

  const handleFormatChange = (value: string) => {
    setFormat(value);
  };

  const convertCoordinates = (item: any) => {
    if (format === "lat, lng degrees") {
      return `${item?.[0]}, ${item?.[1]}`; // Định dạng thập phân
    } else {
      const latitudeDMS = decimalToDMS(item?.[1], "N", "S");
      const longitudeDMS = decimalToDMS(item?.[0], "E", "W");
      return `${longitudeDMS}, ${latitudeDMS}`; // Định dạng độ phút giây
    }
  };

  return (
    <div className="text-white">
      <div className="flex gap-2">
        <p>Format</p>
        <Select
          className="mb-2"
          defaultValue="lat, lng degrees"
          onChange={handleFormatChange}
          style={{ width: 200 }}
          options={[
            { value: "lat, lng degrees", label: "lat, lng degrees" },
            { value: "lat, lng minutes", label: "lat, lng minutes" },
          ]}
        />
      </div>
      {props?.selectedPolygon?.geometry?.coordinates?.[0]?.map(
        (item: any, index: any) => {
          const val = convertCoordinates(item);
          return (
            <div key={index}>
              <p className="mb-1 text-des">Coordinate {index + 1}</p>
              <CommonComponents.InputUI className="mb-2" value={val} />
            </div>
          );
        }
      )}
      <b>Area</b>
      <p>{output}</p>
      <b className="mt-2">Perimeter</b>
      <p>{Math.round(perimeter * 100) / 100} km</p> {/* Hiển thị chu vi */}
    </div>
  );
};

export default PolygonInfo;
