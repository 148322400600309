import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  approveArchiveOrder,
  confirmOrderTaskingAdmin,
  createOrder,
  createTaskingOrder,
  getArchiveOrderById,
  getDetailOrderTaskingByAdmin,
  getDetailOrderTaskingByUer,
  getMetakeys,
  getOrderByIdAdmin,
  getOrderByIdUser,
  getPriority,
  getProduction,
  getProductTypeId,
  getSegmentsByIdOrder,
  getTaskingSchema,
  updateStatusTaskingOrder,
} from "../../services/orderServices";

interface IOrderState {
  showDrawerListImages: boolean;
  showOrderTasking?: boolean;
  valuesForm?: any;
  orderedProvider?: any;
  polygonOrderTasking?: any;
  providerIdOrder?: any;
}

const initialState: IOrderState = {
  showDrawerListImages: false,
  showOrderTasking: false,
  valuesForm: {},
  orderedProvider: [],
  polygonOrderTasking: {},
  providerIdOrder: [],
};

export const getProductTypeIdAsync = createAsyncThunk(
  "order/getProductTypeId",
  async (payload: any) => {
    try {
      const response = await getProductTypeId(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);
export const approveArchiveOrderAsync = createAsyncThunk(
  "order/approveArchiveOrder",
  async (payload: any) => {
    try {
      const response = await approveArchiveOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getDetailOrderTaskingByUerAsync = createAsyncThunk(
  "order/getDetailOrderTaskingByUer",
  async (payload: any) => {
    try {
      const response = await getDetailOrderTaskingByUer(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getDetailOrderTaskingByAdminAsync = createAsyncThunk(
  "order/getDetailOrderTaskingByAdmin",
  async (payload: any) => {
    try {
      const response = await getDetailOrderTaskingByAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getOrderByIdUserAsync = createAsyncThunk(
  "order/getOrderByIdUser",
  async (payload: any) => {
    try {
      const response = await getOrderByIdUser(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getOrderByIdAdminAsync = createAsyncThunk(
  "order/getOrderByIdAdmin",
  async (payload: any) => {
    try {
      const response = await getOrderByIdAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getSegmentsByIdOrderAsync = createAsyncThunk(
  "order/getSegmentsByIdOrder",
  async (payload: any) => {
    try {
      const response = await getSegmentsByIdOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const confirmOrderTaskingAdminAsync = createAsyncThunk(
  "order/confirmOrderTaskingAdmin",
  async (payload) => {
    try {
      const response = await confirmOrderTaskingAdmin(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getProductionAsync = createAsyncThunk(
  "order/getProduction",
  async () => {
    try {
      const response = await getProduction();
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);
export const createOrderAsync = createAsyncThunk(
  "order/createOrder",
  async (payload: any) => {
    try {
      const response = await createOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const createTaskingOrderAsync = createAsyncThunk(
  "order/createTaskingOrder",
  async (payload: any) => {
    try {
      const response = await createTaskingOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getArchiveOrderByIdAsync = createAsyncThunk(
  "order/getArchiveOrderById",
  async (id) => {
    try {
      const response = await getArchiveOrderById(id);
      return response;
    } catch (e) {
      console.log(e, "error");

      return {};
    }
  }
);

export const getPriorityAsync = createAsyncThunk(
  "exploredataset/getPriority",
  async () => {
    try {
      const response = await getPriority();
      return response;
    } catch (e) {
      console.log(e, "error");

      return {};
    }
  }
);

export const getMetakeysAsync = createAsyncThunk(
  "order/getMetakeys",
  async (payload: any) => {
    try {
      const response = await getMetakeys(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const updateStatusTaskingOrderAsync = createAsyncThunk(
  "order/updateStatusTaskingOrder",
  async (payload: any) => {
    try {
      const response = await updateStatusTaskingOrder(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const getTaskingSchemaAsync = createAsyncThunk(
  "order/getTaskingSchema",
  async (payload: any) => {
    try {
      const response = await getTaskingSchema(payload);
      return response;
    } catch (e: any) {
      console.log(e, "error");

      return {
        message: e?.response?.data?.message,
      };
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    changeShowDrawerListImages: (state, action: PayloadAction<boolean>) => {
      state.showDrawerListImages = action.payload;
    },

    changeShowOrderTasking: (state, action: PayloadAction<boolean>) => {
      state.showOrderTasking = action.payload;
    },
    setValuesForm: (state, action: any) => {
      state.valuesForm = action.payload;
    },

    setOrderedProvider: (state, action: any) => {
      state.orderedProvider = action.payload;
    },
    setPolygonOrderTasking: (state, action: any) => {
      state.polygonOrderTasking = action.payload;
    },
    setProviderIdOrder: (state, action: any) => {
      state.providerIdOrder = action.payload;
    },
  },
});

export const {
  changeShowDrawerListImages,
  changeShowOrderTasking,
  setValuesForm,
  setOrderedProvider,
  setPolygonOrderTasking,
  setProviderIdOrder,
} = orderSlice.actions;

export default orderSlice.reducer;
