import React from "react";

export default function MenuIcon() {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.4375 1.625C0.31901 1.625 0.216471 1.58171 0.129883 1.49512C0.0432943 1.40853 0 1.30599 0 1.1875C0 1.06901 0.0432943 0.966471 0.129883 0.879883C0.216471 0.793294 0.31901 0.75 0.4375 0.75H13.5625C13.681 0.75 13.7835 0.793294 13.8701 0.879883C13.9567 0.966471 14 1.06901 14 1.1875C14 1.30599 13.9567 1.40853 13.8701 1.49512C13.7835 1.58171 13.681 1.625 13.5625 1.625H0.4375ZM0.4375 6C0.31901 6 0.216471 5.95671 0.129883 5.87012C0.0432943 5.78353 0 5.68099 0 5.5625C0 5.44401 0.0432943 5.34147 0.129883 5.25488C0.216471 5.16829 0.31901 5.125 0.4375 5.125H13.5625C13.681 5.125 13.7835 5.16829 13.8701 5.25488C13.9567 5.34147 14 5.44401 14 5.5625C14 5.68099 13.9567 5.78353 13.8701 5.87012C13.7835 5.95671 13.681 6 13.5625 6H0.4375ZM0.4375 10.375C0.31901 10.375 0.216471 10.3317 0.129883 10.2451C0.0432943 10.1585 0 10.056 0 9.9375C0 9.81901 0.0432943 9.71647 0.129883 9.62988C0.216471 9.54329 0.31901 9.5 0.4375 9.5H13.5625C13.681 9.5 13.7835 9.54329 13.8701 9.62988C13.9567 9.71647 14 9.81901 14 9.9375C14 10.056 13.9567 10.1585 13.8701 10.2451C13.7835 10.3317 13.681 10.375 13.5625 10.375H0.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
