import MapboxDraw from "@mapbox/mapbox-gl-draw";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CoordinateDisplayType, MouseCoordinateElementIds } from "../../enums";
import {
  displayMouseCoordinate,
  getMouseCoordinateDisplayType,
} from "../../helpers/map";
import DrawRectangle from "../../helpers/mapbox-gl-draw-rectangle-mode";
import {
  addFeature,
  changeActiveFeature,
  changeMaplibreGL,
  setDraw as setDrawStore,
  setDrawType,
  setFlagEditPolygon,
  setSelected,
  setSourceData,
  updateFeature,
} from "../../store/reducers/mapTaskingSlice";
import { RootState } from "../../store/store";
import {
  centroid,
  changeColor,
  initCartLayer,
  initHoverLayer,
  initItemsCollectionLayer,
  initTextlayer,
  resetDrawMode,
  updateFeaturesText,
} from "../Map/ultis";

import { v4 as uuidv4 } from "uuid";
import {
  changeLeftDrawerType,
  changeShowDrawer,
  resetAllDrawer,
} from "../../store/reducers/drawerSlice";
import { getDistance } from "./components/ultis";

const maxZoomLevel = 12;

export default function MapTaskingPlugin(props: any) {
  const dispatch = useDispatch();
  const mapContainer = useRef<any>();

  const flagEditPolygon = useSelector(
    (state: RootState) => state.mapTasking.flagEditPolygon
  );
  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );

  console.log(selectedPolygon, "selectedPolygon");

  const { activeFeature, sourceData, selected, maplibreGL } = useSelector(
    (state: RootState) => state.mapTasking
  );

  let sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  let featuresDraw: any = useSelector(
    (state: RootState) => state.mapTasking.features
  );

  const [map, setMap] = useState<any>();
  const [draw, setDraw] = useState<MapboxDraw>();

  const baseMapStore = useSelector(
    (state: RootState) => state.mapSlice.baseMapSelected
  );

  const { leftDrawerType } = useSelector(
    (state: RootState) => state.drawerSlice
  );

  useEffect(() => {
    dispatch(resetAllDrawer());
    return () => {
      dispatch(resetAllDrawer());
      dispatch(changeMaplibreGL(undefined));
    };
  }, []);
  useEffect(() => {
    if (map) {
      map.remove();
      setMap(undefined);
      setDraw(undefined);

      dispatch(changeMaplibreGL(undefined));
      dispatch(setDrawStore(undefined));
    }
  }, [baseMapStore]);

  useEffect(() => {
    if (!map && baseMapStore) {
      setTimeout(() => {
        initMap();
      }, 100);
    }
  }, [map, baseMapStore]);

  useEffect(() => {
    if (maplibreGL) {
      const thresholdInPixels = 2 * maxZoomLevel;
      const corners = activeFeature?.geometry?.coordinates[0];

      const getPixelDistance = (map: any, lngLat1: any, lngLat2: any) => {
        const p1 = map.project(lngLat1);
        const p2 = map.project(lngLat2);
        return Math.sqrt((p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2);
      };

      const getCursorTypeForCorner = (corner: any, center: any) => {
        const [lng, lat] = corner;
        const [centerLng, centerLat] = center;

        if (lat > centerLat && lng > centerLng) return "nesw-resize";
        if (lat > centerLat && lng < centerLng) return "nwse-resize";
        if (lat < centerLat && lng > centerLng) return "nwse-resize";
        if (lat < centerLat && lng < centerLng) return "nesw-resize";

        return "default";
      };

      const onMouseMove = (e: any) => {
        const mouseLngLat = [e.lngLat.lng, e.lngLat.lat];
        const canvas = maplibreGL.getCanvas();
        let cursor = "default";

        const center = corners
          .reduce(
            (acc: any, [lng, lat]: any) => [acc[0] + lng, acc[1] + lat],
            [0, 0]
          )
          .map((value: any) => value / corners.length);

        for (const corner of corners) {
          const distance = getPixelDistance(maplibreGL, mouseLngLat, corner);
          if (distance < thresholdInPixels) {
            cursor = getCursorTypeForCorner(corner, center);
            break;
          }
        }
        canvas.style.cursor = cursor;
      };

      maplibreGL.on("mousemove", onMouseMove);

      return () => {
        maplibreGL.off("mousemove", onMouseMove);
      };
    }
  }, [maplibreGL, activeFeature]);

  useEffect(() => {
    if (draw !== undefined && maplibreGL === undefined && map) {
      dispatch(changeMaplibreGL(map));
    }
  }, [draw]);

  useEffect(() => {
    drawItems(selected);
  }, [selected]);

  useEffect(() => {
    dispatch(setSelected([]));
    dispatch(setSourceData({ type: "FeatureCollection", features: [] }));
  }, [props?.type]);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      if (map && Object.keys(map).length && map.getSource(sourceId)) {
        // @ts-ignore
        map.getSource(sourceId).setData(sourceData);

        if (sourceData?.features?.length === 0) {
          map
            .getSource(sourceId + "_hover")
            .setData({ type: "FeatureCollection", features: [] });
        }
      }
    }, 200);
  }, [sourceData, props?.type, map]);

  useEffect(() => {
    if (
      activeFeature &&
      Object.keys(activeFeature).length &&
      draw &&
      activeFeature?.id &&
      !props?.showOnConfirm
    ) {
      // Kiểm tra nếu không có đối tượng nào trong draw
      if (draw.getAll()?.features?.length === 0 && featuresDraw?.length) {
        let a: any = {
          type: "FeatureCollection",
          features: featuresDraw,
        };
        draw.add(a); // Thêm lại tất cả các đối tượng từ Redux
      }

      if (draw) {
        draw.changeMode("simple_select", { featureIds: [] });
      }

      // Kiểm tra nếu activeFeature tồn tại trong draw
      const activeFeatureFromDraw = draw.get(activeFeature?.id);
      if (activeFeatureFromDraw) {
        // Thay đổi màu cho feature được chọn
        changeColor([activeFeatureFromDraw], "#FFFFFF33", "#EF6C00", draw);

        // Lọc các feature khác và thay đổi màu sắc
        let feat = featuresDraw?.filter(
          (item: any) => item?.id !== activeFeature?.id
        );
        changeColor(feat, "#FFFFFF33", "#000000", draw); // ko duoc chon
      }

      if (props?.type === "map-tasking") {
        dispatch(changeShowDrawer(true));
        dispatch(changeLeftDrawerType("Info AOI"));
      }
    }

    setTimeout(() => {
      updateFeaturesText(map, featuresDraw);
    }, 100);
  }, [featuresDraw, activeFeature, draw, map]);

  useEffect(() => {
    if (
      props?.showOnConfirm &&
      props?.activePolygon &&
      Object.keys(props?.activePolygon).length > 0
    ) {
      drawPolygon(props?.activePolygon);
    } else {
      if (map && map.getSource("mapbox-gl-draw-text-source")) {
        map
          .getSource("mapbox-gl-draw-text-source")
          .setData({ type: "FeatureCollection", features: [] });
      }
    }
  }, [props?.activePolygon, map]);

  const drawPolygon = async (polygon: any) => {
    let dataF: any = { type: "FeatureCollection", features: [] }; // copy object
    let centerPoint: any;

    dataF.features = [
      {
        id: uuidv4(),
        type: "Feature",
        geometry: polygon,
        properties: {},
        name: "Polygon 01",
      },
    ];

    centerPoint = centroid(polygon);

    setTimeout(() => {
      // @ts-ignore
      if (map && Object?.keys(map)?.length && map.getSource(sourceId)) {
        map.getSource(sourceId).setData(dataF);

        updateFeaturesText(map, dataF?.features);
        // @ts-ignore

        if (centerPoint) {
          // @ts-ignore
          map.panTo(centerPoint);
        }
      }
    }, 200);
  };

  useEffect(() => {
    if (activeFeature && map) {
      const coordinates = activeFeature?.geometry?.coordinates[0];
      const bounds = coordinates.reduce(
        (acc: any, coord: any) => {
          acc[0][0] = Math.min(acc[0][0], coord[0]);
          acc[0][1] = Math.min(acc[0][1], coord[1]);
          acc[1][0] = Math.max(acc[1][0], coord[0]);
          acc[1][1] = Math.max(acc[1][1], coord[1]);
          return acc;
        },
        [
          [Infinity, Infinity],
          [-Infinity, -Infinity],
        ]
      );

      // const maxZoomLevel = 12;
      const padding = 20;
      map.fitBounds(bounds, {
        padding: padding,
        maxZoom: maxZoomLevel,
      });
    }
  }, [map]);

  const initMap = () => {
    let hoveredStateId: any = null;
    if (map) {
      return; // Nếu map đã tồn tại, không cần khởi tạo lại map
    }

    const _map: any = new maplibregl.Map({
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            tiles: [String(baseMapStore?.url)],
            tileSize: 256,
          },
        },
        glyphs: "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
        layers: [
          {
            id: "Google Satellite",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [110, 12],
      zoom: 4,
      attributionControl: false,
    });

    _map.setRenderWorldCopies(false);

    setTimeout(() => {
      // init items collection layer
      initItemsCollectionLayer(_map, sourceId, sourceData);

      initHoverLayer(_map, sourceId, sourceData);

      initTextlayer(_map, sourceId);

      // initCartLayer(_map, sourceId, sourceData);
    }, 50);

    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
    // @ts-ignore
    MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

    const draw = new MapboxDraw({
      userProperties: true,
      displayControlsDefault: false,
      controls: {
        polygon: false,
        point: false,
        trash: false,
      },
      modes: {
        ...MapboxDraw.modes,
        draw_rectangle: DrawRectangle,
        // simple_select: { ...MapboxDraw.modes.simple_select, onDrag() {} }, // mo cai nay thi ko edit duoc polygon
        // direct_select: { ...MapboxDraw.modes.direct_select, onDrag() {} },
      },
      boxSelect: true,

      styles: [
        {
          id: "gl-draw-polygon-color-picker",
          type: "fill",
          filter: [
            "all",
            ["==", "$type", "Polygon"],
            ["has", "user_portColor"],
          ],
          paint: {
            "fill-color": ["get", "user_portColor"],
            "fill-opacity": 0.9,
          },
        },
        {
          id: "gl-draw-polygon-stroke-inactive",
          type: "line",
          filter: [
            "all",
            ["==", "active", "false"],
            ["==", "$type", "Polygon"],
            ["!=", "mode", "static"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": ["get", "user_lineColor"],
            "line-width": 2,
          },
        },
        {
          // style for line when drawing
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "active", "true"], ["==", "$type", "Polygon"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fbb03b",
            "line-dasharray": [0.2, 2],
            "line-width": 2,
          },
        },
        {
          // style for color outline point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 5,
            "circle-color": "#fff",
          },
        },
        {
          // style for color fill point when drawing polygon
          id: "gl-draw-polygon-and-line-vertex-inactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
          ],
          paint: {
            "circle-radius": 3,
            "circle-color": "#ff0000",
          },
        },
      ],
    });

    _map.addControl(draw, "top-left");

    setTimeout(() => {
      _map.on("draw.create", updateArea);
      _map.on("draw.delete", (e: any) => updateArea(e, draw));
      _map.on("draw.update", updateArea); // nos owr trong init map
    }, 100);

    _map.on("styledata", function () {});

    _map.on("load", () => {});

    _map.on("mousemove", (e: any) => {
      const _currentCoordinateDisplayType = getMouseCoordinateDisplayType(
        MouseCoordinateElementIds.TYPE_ID
      );
      displayMouseCoordinate(
        MouseCoordinateElementIds.LNG_ID,
        MouseCoordinateElementIds.LAT_ID,
        CoordinateDisplayType.DD,
        _currentCoordinateDisplayType,
        {
          lng: e?.lngLat?.lng ?? 0,
          lat: e?.lngLat?.lat ?? 0,
        }
      );
    });

    _map.on("moveend", (e: any) => {});

    // When the user moves their mouse over the items collection layer, we'll update the
    // feature state for the feature under the mouse.
    _map.on("mousemove", sourceId + "_fill_hover", (e: any) => {
      if (e.features.length > 0) {
        if (hoveredStateId) {
          _map.setFeatureState(
            { source: sourceId, id: hoveredStateId },
            { hover: false }
          );
        }
        hoveredStateId = e.features[0].id;
        _map.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: true }
        );
      }
    });

    // When the mouse leaves the items collection layer, update the feature state of the
    // previously hovered feature.
    _map.on("mouseleave", sourceId + "_fill_hover", () => {
      if (hoveredStateId) {
        _map.setFeatureState(
          { source: sourceId, id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
    });

    _map.on("mousemove", sourceId, (e: any) => {
      console.log(e.point, "e.point");
    });
    // Change the cursor to a pointer when the mouse is over the items collection layer.
    _map.on("mouseenter", sourceId + "_fill_hover", () => {
      _map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    _map.on("mouseleave", sourceId + "_fill_hover", () => {
      _map.getCanvas().style.cursor = "";
    });

    setDraw(draw);
    setMap(_map);

    dispatch(changeMaplibreGL(map));
    dispatch(setDrawStore(draw));
  };

  const updateArea = (e: any, draw?: any) => {
    if (maplibreGL === undefined) {
      dispatch(changeMaplibreGL(e.target));
    }
    switch (e["type"]) {
      case "draw.create":
        e.target.getSource(sourceId).setData({
          type: "FeatureCollection",
          features: [],
        });

        e.target
          .getSource(sourceId + "_hover")
          .setData({ type: "FeatureCollection", features: [] });

        dispatch(
          setSourceData({
            type: "FeatureCollection",
            features: [],
          })
        );

        dispatch(setSelected([]));

        dispatch(
          addFeature({
            ...e["features"][0],
          })
        );

        dispatch(
          changeActiveFeature({
            ...e["features"][0],
          })
        );

        resetDrawMode(e.target, draw);

        dispatch(setDrawType(undefined));

        break;
      case "draw.update":
        dispatch(updateFeature(e["features"][0]));

        // dispatch(setFlagEditPolygon(false));
        break;
      case "draw.delete":
        break;
    }
  };

  const drawItems = async (items: Array<any>) => {
    let data = { ...sourceData }; // copy object
    let centerPoint: any;

    if (items?.length > (data?.features?.length ?? 0)) {
      for (let i = 0; i < items?.length; i++) {
        if (
          items[i] &&
          data?.features?.findIndex(
            (x: any) => x?.properties?.id === items[i]?.id
          ) < 0
        ) {
          let properties = items[i]?.properties
            ? { ...items[i]?.properties }
            : {};
          properties.id = properties?.id ? properties?.id : items[i]?.id;

          properties.collection = items[i]?.collection ?? "";
          data.features = [
            ...data?.features,
            {
              id: data?.features.length + 1,
              type: items[i]?.type ? items[i]?.type : "Feature",
              geometry: items[i]?.geometry,
              properties,
            },
          ];

          centerPoint = centroid(items[i]?.geometry);
        }
      }
    } else {
      if (data?.features) {
        data.features = data?.features.filter((x: any) =>
          items?.map((y: any) => y?.id).includes(x?.properties?.id)
        );
      }
    }

    dispatch(setSourceData(data));

    setTimeout(() => {
      // @ts-ignore
      if (map && Object?.keys(map)?.length && map?.getSource(sourceId)) {
        // @ts-ignore
        // map.getSource(sourceId).setData(data);
        if (centerPoint) {
          // @ts-ignore
          map.panTo(centerPoint);
        }
      }
    }, 200);
  };

  useEffect(() => {
    if (leftDrawerType) {
      // setShowRightDrawer(true);
      dispatch(changeShowDrawer(true));
      return;
    }

    // setShowRightDrawer(false);
    dispatch(changeShowDrawer(false));
  }, [leftDrawerType]);

  // useEffect(() => {
  //   if (map && draw) {
  //     // const onSelectionChange = (e: any) => {
  //     //   const selectedFeatures = e.features;
  //     //   if (selectedFeatures.length > 0) {
  //     //     const selectedFeature = selectedFeatures[0];
  //     //     const featureId =
  //     //       selectedFeature?.properties.id ?? selectedFeature?.id;
  //     //     if (featureId === selectedPolygon?.id && flagEditPolygon) {
  //     //       draw.changeMode("direct_select", { featureId });
  //     //       dispatch(setFlagEditPolygon(true));
  //     //     } else {
  //     //       draw.changeMode("simple_select", { featureIds: [] });
  //     //       dispatch(setFlagEditPolygon(false));
  //     //     }
  //     //   }
  //     // };
  //     // const onModeChange = (e: any) => {
  //     //   const mode = e.mode;
  //     //   if (mode === "direct_select") {
  //     //     const selectedFeature = draw.getSelected().features[0];
  //     //     const featureId = selectedFeature?.id;
  //     //     if (featureId !== selectedPolygon?.id || !flagEditPolygon) {
  //     //       draw.changeMode("simple_select");
  //     //       dispatch(setFlagEditPolygon(false));
  //     //     }
  //     //   }
  //     // };
  //     // map.on("draw.selectionchange", onSelectionChange);
  //     // map.on("draw.modechange", onModeChange);
  //     // return () => {
  //     //   map.off("draw.selectionchange", onSelectionChange);
  //     //   map.off("draw.modechange", onModeChange);
  //     // };
  //   }
  // }, [selectedPolygon, map, draw, flagEditPolygon]);

  console.log(activeFeature, "activeFeature");
  return (
    <div
      key={baseMapStore?.url}
      ref={mapContainer}
      className="!w-full h-full"
    />
  );
}
