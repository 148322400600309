import { Button } from "antd";
import BackSvg from "../../../assets/svg/back.svg";
import FilterSvg from "../../../assets/svg/filter.svg";
import NextSvg from "../../../assets/svg/next.svg";
import SortSvg from "../../../assets/svg/sort.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { setPageNumber } from "../../../store/reducers/appCommonSlice";

const PagingComp = () => {
  const dispatch = useDispatch();
  const showPaging = useSelector(
    (state: RootState) => state.appCommonSlice.showPaging
  );

  const showFilter = useSelector(
    (state: RootState) => state.appCommonSlice.showFilter
  );

  const showSort = useSelector(
    (state: RootState) => state.appCommonSlice.showSort
  );

  const totalPage = useSelector(
    (state: RootState) => state.appCommonSlice.totalPage
  );

  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );
  return (
    <div>
      {showPaging && (
        <>
          <Button
            className="btn-action-icon"
            icon={<img src={BackSvg} />}
            onClick={() => {
              dispatch(setPageNumber(pageNumber - 1));
            }}
            disabled={pageNumber === 0}
          />
          <span className="m-2">
            {pageNumber + 1} / {totalPage}
          </span>
          <Button
            className="btn-action-icon mr-2"
            icon={<img src={NextSvg} />}
            disabled={pageNumber + 1 === totalPage}
            onClick={() => {
              dispatch(setPageNumber(pageNumber + 1));
            }}
          />
        </>
      )}

      {showFilter && (
        <Button
          className="btn-action-icon mr-2"
          icon={<img src={FilterSvg} />}
        />
      )}

      {showSort && (
        <Button className="btn-action-icon" icon={<img src={SortSvg} />} />
      )}
    </div>
  );
};

export default PagingComp;
