import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSegmentsByIdOrderAsync } from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import CommonComponents from "../../Commons";

const Step1 = (props: any) => {
  const selectedPolygon = useSelector(
    (state: RootState) => state.mapTasking.activeFeature
  );
  const dispatch = useDispatch();

  const [valueSensor, setValueSensor] = useState("Pléiades");
  const [dataSegments, setDataSegments] = useState<any>([]);
  const [heightSelect, setHeightSelect] = useState<any>("32px");

  const [valStartDate, setValueStartData] = useState<any>(undefined);
  const [valEndDate, setValueEndData] = useState<any>(undefined);
  const [valuePlan, setValuePlan] = useState("");

  const valuesForm = useSelector(
    (state: RootState) => state.orderSlice.valuesForm
  );

  const showOrderTasking = useSelector(
    (state: RootState) => state.orderSlice.showOrderTasking
  );

  const getSegments = async (pay: any) => {
    // @ts-ignore
    let res = await dispatch(getSegmentsByIdOrderAsync(pay)).unwrap();

    setDataSegments(
      res?.data?.data?.progCapacities?.[0]?.progTypes?.[0]?.segments ?? []
    );
  };

  useEffect(() => {
    setValuePlan(valuesForm?.plan);
    setValueSensor(valuesForm?.sensor ?? "Pléiades");
    setValueEndData(valuesForm?.end_date_request);
    setValueStartData(valuesForm?.start_date_request);

    setHeightSelect(valuesForm?.production?.acquisition ? "100px" : "32px");
  }, [valuesForm]);

  useEffect(() => {
    if (valStartDate || valEndDate) {
      getSegments({
        progTypeNames: valuePlan,
        missions: valueSensor,
        acquisitionDate: {
          startDate: valStartDate,
          endDate: valEndDate,
        },
        optical: {
          acquisitionMode: "MONO",
          maxCloudCover: 25,
          maxIncidenceAngle: 30,
        },
        aoi: selectedPolygon?.geometry,
      });
    }
  }, [valStartDate, valuePlan]);

  return (
    <div className={showOrderTasking ? " w-[40%]" : ""}>
      <CommonComponents.FormFieldUI
        name="name"
        component={
          <CommonComponents.InputUI
            label="Order name"
            placeholder="Order name"
            required
          />
        }
        rules={[{ required: true, message: "Please input here!" }]}
      />

      <div className="grid grid-cols-2 gap-2">
        <CommonComponents.FormFieldUI
          name={"priority_id"}
          component={
            <CommonComponents.SelectUI
              label="Priority"
              placeholder="Priority"
              required
              data={props?.dataPriority || []}
              nameValue="id"
            />
          }
          rules={[{ required: true, message: "Please input here!" }]}
        />

        <CommonComponents.FormFieldUI
          name={"project_id"}
          component={
            <CommonComponents.SelectUI
              label="Project"
              placeholder="Select project"
              data={props?.datProject || []}
              nameValue="id"
            />
          }
        />
      </div>

      <CommonComponents.FormFieldUI
        name="description"
        component={
          <CommonComponents.TextAreaUI
            label="Description"
            placeholder="Description"
          />
        }
      />
    </div>
  );
};

export default Step1;
