import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import InfoSvg from "../../../assets/svg/info.svg";
import MyDataSvg from "../../../assets/svg/ManageData/icon-card-preview-my-data.svg";
import { ERoleAccessControl } from "../../../enums";
import { CheckRoleAccessControlAdmin } from "../../../helpers/roleAccessControl";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import useGetOrderTaskingAdmin from "../../../services/orderTasking/hooks/useGetorderTaskingAdmin";
import useGetOrderTaskingUser from "../../../services/orderTasking/hooks/useGetorderTaskingUser";
import { resetAllDrawer } from "../../../store/reducers/drawerSlice";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../../store/reducers/layoutSlice";
import { displayNotification } from "../../../store/reducers/notificationSlice";
import {
  getMetakeysAsync,
  updateStatusTaskingOrderAsync,
} from "../../../store/reducers/orderSlice";
import { RootState } from "../../../store/store";
import CommonComponents from "../../Commons";
import CardManagementComp from "../../Commons/CardManagement";
import LoadingUI from "../../Commons/Loading";
import NoData from "../../NoData";

const ManagementTasking = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const pageSize = useSelector(
    (state: RootState) => state.appCommonSlice.pageSize
  );
  const pageNumber = useSelector(
    (state: RootState) => state.appCommonSlice.pageNumber
  );

  const roleAdmin = CheckRoleAccessControlAdmin();
  const [dataUsing, setDataUsing] = useState<any>();

  const [arrStatus, setArrStatus] = useState<any>([]);

  //! state
  const getStatus = async () => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      getMetakeysAsync({ page: 0, size: 1000 })
    ).unwrap();

    setArrStatus(
      res?.data?.data?.content_page?.filter(
        (item: any) => item?.key === "ORDER_STATUS"
      )
    );
  };

  const {
    data: dataOrderTaskingUser,
    isLoading: loadingOrderTaskingUser,
    refetch: refetchOrderTaskingUser,
  } = useGetOrderTaskingUser(false, {
    page: pageNumber,
    size: pageSize,
    type_order: "tasking",
  });

  const {
    data: dataOrderTaskingAdmin,
    isLoading: loadingOrderAdmin,
    refetch: refetchOrderTaskingAdmin,
  } = useGetOrderTaskingAdmin(false, {
    page: pageNumber,
    size: pageSize,
    type_order: "tasking",
  });
  //! function
  const ParseDataOrder = useCallback(() => {
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return [];
    }
    return dataUsing?.content_page?.map((item: any) => {
      return {
        id: item.id,
        imgSrc: MyDataSvg,
        title: item.name,
        status: item.currentStatus ?? item?.current_status,
        des: {
          created_at: item?.created_at,
          description: item?.description,
          priority: item?.priority,
        },
      };
    });
  }, [dataUsing]);

  useEffect(() => {
    if (roleAdmin?.includes(ERoleAccessControl.admin)) {
      refetchOrderTaskingAdmin();
      return;
    }
    if (!roleAdmin?.includes(ERoleAccessControl.admin)) {
      refetchOrderTaskingUser();
      return;
    }
  }, [roleAdmin]);

  useEffect(() => {
    dispatch(changeLayoutName("/order/management-tasking"));
    dispatch(changeDataBreadcrumb([{ title: "Tasking Orders" }]));

    dispatch(resetAllDrawer());

    getStatus();

    return () => {
      dispatch(changeLayoutName(""));
      dispatch(resetAllDrawer());
    };
  }, []);

  useEffect(() => {
    if (dataOrderTaskingAdmin || dataOrderTaskingUser) {
      setDataUsing(dataOrderTaskingAdmin?.data ?? dataOrderTaskingUser?.data);
    }
  }, [loadingOrderTaskingUser, loadingOrderAdmin]);

  const handleUpdateStatus = async (id: any, statusId: any) => {
    // @ts-ignore
    let res = await dispatch(
      // @ts-ignore
      updateStatusTaskingOrderAsync({ id: id, statusId: statusId, note: "" })
    ).unwrap();

    if (res?.status === 200 || res?.status === 201) {
      dispatch(
        displayNotification({
          type: "success",
          content: "Update status successfully",
        })
      );

      if (roleAdmin?.includes(ERoleAccessControl.admin)) {
        refetchOrderTaskingAdmin();
        return;
      }
      if (!roleAdmin?.includes(ERoleAccessControl.admin)) {
        refetchOrderTaskingUser();
        return;
      }
    } else {
      dispatch(
        displayNotification({
          type: "error",
          content: "Update status fail",
        })
      );
    }
  };

  const renderItemOrder = useCallback(() => {
    if (loadingOrderTaskingUser || loadingOrderAdmin) {
      return <LoadingUI />;
    }
    if (dataUsing?.content_page && dataUsing?.content_page?.length < 1) {
      return <NoData />;
    }
    return ParseDataOrder()?.map((item: any, index: any) => {
      const time = moment(item?.created_at).format("DD/MM/YYYY");

      const dataItem = {
        ...item,
        des: (
          <div className="flex gap-1">
            {item?.des?.created_at && <span>{time}</span>}
            {item?.des?.description && (
              <span className="before:content-['|']">
                {` ${item?.des?.description}`}
              </span>
            )}
            {item?.des?.priority && (
              <div className="before:content-['|']">
                <span
                  style={{
                    color: item?.des?.priority?.description,
                  }}
                >
                  {` ${item?.des?.priority.value}`}
                </span>
              </div>
            )}
          </div>
        ),
      };

      return (
        <CardManagementComp
          key={index}
          data={dataItem}
          onClick={() => navigate(`${item?.id}`)}
          actions={[
            // roleAdmin?.includes(ERoleAccessControl.admin) &&
            //   item?.status?.name !== EOrderStatus.ORDER_CONFIRMED &&
            //   item?.status?.name !== EOrderStatus.ORDER_CANCELED &&
            //   item?.status?.name !== EOrderStatus.ORDER_REJECTED && (
            //     <CommonComponents.TooltipUI title="Approve">
            //       <img
            //         onClick={(e) => {
            //           e.stopPropagation();
            //           navigate(`/order/management-tasking/${item.id}/update`, {
            //             state: {
            //               item: item,
            //             },
            //           });
            //         }}
            //         src={SettingSvg}
            //       />
            //     </CommonComponents.TooltipUI>
            //   ),

            // orderStatus && (
            //   <CommonComponents.TooltipUI
            //     title={
            //       roleAdmin?.includes(ERoleAccessControl.admin)
            //         ? "Reject"
            //         : "Cancel"
            //     }
            //   >
            //     <img
            //       className={`${orderStatus ? "" : "hidden"}`}
            //       onClick={(e) => {
            //         e.stopPropagation();
            //         handleUpdateStatus(
            //           item?.id,
            //           roleAdmin?.includes(ERoleAccessControl.admin)
            //             ? arrStatus?.filter(
            //                 (itemF: any) =>
            //                   itemF?.name === EOrderStatus.ORDER_REJECTED
            //               )?.[0]?.id
            //             : arrStatus?.filter(
            //                 (itemF: any) =>
            //                   itemF?.name === EOrderStatus.ORDER_CANCELED
            //               )?.[0]?.id
            //         );
            //         // toggleConfirmReject();
            //         // setIdReject(Number(item.id));
            //       }}
            //       src={RejectSvg}
            //     />
            //   </CommonComponents.TooltipUI>
            // ),

            <CommonComponents.TooltipUI title="Infomation">
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  navigate(`./${item?.id}`);
                }}
              />
            </CommonComponents.TooltipUI>,
          ]}
        />
      );
    });
  }, [dataUsing]);

  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {renderItemOrder()}
      </div>
    </ContentLayoutManagement>
  );
};

export default ManagementTasking;
