import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addImgIntoCart,
  delImgIntoCart,
  getImgs,
  getProductBundleByItemType,
} from "../../services/cartServices";

interface ICartState {
  arrIdImage: any;
  selectedImages?: any;

  selectedImagesConfirm?: any;
}

const initialState: ICartState = {
  arrIdImage: [],
  selectedImages: [],
  selectedImagesConfirm: [],
};

export const addImgIntoCartAsync = createAsyncThunk(
  "cart/add",
  async (payload) => {
    try {
      const response = await addImgIntoCart(payload);
      return response;
    } catch (e) {
      return e;
    }
  }
);

export const delImgIntoCartAsync = createAsyncThunk(
  "cart/del",
  async (payload) => {
    try {
      const response = await delImgIntoCart(payload);
      return response;
    } catch (e) {
      return {};
    }
  }
);

export const getImgsAsync = createAsyncThunk("cart/get", async () => {
  try {
    const response = await getImgs({});

    return response;
  } catch (e) {
    console.log(e, "error");

    return {};
  }
});

export const getProductBundleByItemTypeAsync = createAsyncThunk(
  "cart/getProductBundleByItemType",
  async (payload) => {
    try {
      const response = await getProductBundleByItemType(payload);

      return response;
    } catch (e) {
      console.log(e, "error");

      return {};
    }
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setArrIdImage: (state, action) => {
      state.arrIdImage = action?.payload;
    },

    setSelectedImages: (state, action) => {
      state.selectedImages = action?.payload; // mang cac id trong properties providerName:id
    },

    setSelectedImagesConfirm: (state, action) => {
      state.selectedImagesConfirm = action?.payload;
    },
  },
});

export const { setArrIdImage, setSelectedImages, setSelectedImagesConfirm } =
  cartSlice.actions;

export default cartSlice.reducer;
