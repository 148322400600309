import React, { useEffect, useState } from "react";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import Header from "../../components/MapTasking/Header";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Footer from "../../components/Map/Footer";
import { DrawType } from "../../enums";
import {
  setCoordinateDisplayType,
  setDrawType,
  clearFeatures,
  setSelected,
  changeMaplibreGL,
} from "../../store/reducers/mapTaskingSlice";
import { resetDrawMode } from "../../components/Map/ultis";
import { LeftDrawerType } from "../../types/common";
import {
  changeBottomDrawerType,
  changeLeftDrawerType,
  changeShowBottomDrawer,
  resetAllDrawer,
} from "../../store/reducers/drawerSlice";
import MapTaskingPlugin from "../../components/MapTasking/Map";
import { resetState } from "../../store/reducers/searchSlice";

const ExploreTaskingPage = () => {
  const dispatch = useDispatch();
  const [buttonActive, setButtonActive] = useState<null | string>(null);
  const drawType = useSelector((state: RootState) => state.mapTasking.drawType);

  const leftDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.leftDrawerType
  );

  const bottomDrawerType = useSelector(
    (state: RootState) => state.drawerSlice.bottomDrawerType
  );

  const clickFilter = useSelector(
    (state: RootState) => state.mapTasking.clickFilter
  );

  const map = useSelector((state: RootState) => state.mapTasking.maplibreGL);
  const draw = useSelector((state: RootState) => state.mapTasking.draw);
  const sourceId = useSelector((state: RootState) => state.mapTasking.sourceId);

  const coordinateDisplayType = useSelector(
    (state: RootState) => state.mapTasking.coordinateDisplayType
  );

  const isSearching = useSelector(
    (state: RootState) => state.search.isSearching
  );

  const onChangeLeftDrawerType = (type: LeftDrawerType) => {
    if (leftDrawerType === type && !isSearching) {
      dispatch(changeLeftDrawerType(""));
      return;
    } else if (leftDrawerType === type && isSearching) {
      dispatch(changeLeftDrawerType("Search results"));
      return;
    }
    dispatch(changeLeftDrawerType(type));
  };

  const onChangeDrawType = (type: DrawType) => {
    if (drawType === type) {
      return;
    }

    if (type === DrawType.UPLOAD && leftDrawerType !== "Upload") {
      onChangeLeftDrawerType("Upload");
    }

    if (type === DrawType.NONE && leftDrawerType === "Upload") {
      onChangeLeftDrawerType("Upload");
    }

    let mode = "";
    switch (type) {
      case DrawType.RECTANGLE:
        mode = "draw_rectangle";
        break;

      case DrawType.POLYGON:
        mode = "draw_polygon";
        break;

      default:
        dispatch(setDrawType(undefined));
        // setDrawType(undefined);
        break;
    }

    if (!mode) {
      resetDrawMode(map, draw);
      return;
    }

    map.getCanvas().style.cursor = "crosshair";

    draw?.changeMode(mode);

    dispatch(setDrawType(type));
  };

  const onChangeBottomDrawerType = (type: any) => {
    if (bottomDrawerType === type) {
      dispatch(changeShowBottomDrawer(false));
      dispatch(changeBottomDrawerType(""));
      return;
    }
    dispatch(changeShowBottomDrawer(true));
    dispatch(changeBottomDrawerType(type));
  };

  useEffect(() => {
    dispatch(resetAllDrawer());
    onChangeLeftDrawerType("Filter");

    return () => {
      dispatch(resetAllDrawer());
      dispatch(resetState());
      dispatch(clearFeatures());
      dispatch(setSelected([]));
    };
  }, []);

  return (
    <ContentLayoutManagement
      pageName="Map"
      zIndex={10}
      header={
        <Header
          drawType={drawType}
          onChangeDrawType={onChangeDrawType}
          onShowFilter={() => {
            onChangeLeftDrawerType("Filter");
          }}
          clickFilter={clickFilter}
        />
      }
      footer={
        <Footer
          buttonActive={buttonActive}
          setButtonActive={setButtonActive}
          onShowPolygonTemplateList={() =>
            onChangeBottomDrawerType("Polygon template list tasking")
          }
          coordinateDisplayType={coordinateDisplayType}
          setCoordinateDisplayType={setCoordinateDisplayType}
          // onShowBaseMap={() => onChangeBottomDrawerType("Basemap list")}
        />
      }
    >
      <div className="relative w-full h-full">
        <MapTaskingPlugin showOnConfirm={false} type="map-archive" />
      </div>
    </ContentLayoutManagement>
  );
};

export default ExploreTaskingPage;
