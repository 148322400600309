import React from "react";

export default function QueryIcon() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.36719 26.9093V3.44118C5.36719 2.61275 6.03876 1.94118 6.86719 1.94118H18.3639C18.7877 1.94118 19.1918 2.12049 19.4762 2.43477L24.4091 7.88661C24.6587 8.16241 24.7969 8.52108 24.7969 8.89302V26.9093C24.7969 27.7377 24.1253 28.4093 23.2969 28.4093H6.86719C6.03876 28.4093 5.36719 27.7377 5.36719 26.9093Z"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M18.209 7.28848V1.25906L24.7972 8.78848H19.709C18.8806 8.78848 18.209 8.1169 18.209 7.28848Z" fill="white" />
      <path
        d="M22.4997 31.4167C26.8719 31.4167 30.4163 27.8723 30.4163 23.5C30.4163 19.1277 26.8719 15.5833 22.4997 15.5833C18.1274 15.5833 14.583 19.1277 14.583 23.5C14.583 27.8723 18.1274 31.4167 22.4997 31.4167Z"
        fill="white"
      />
      <path d="M22.5 26.2708V21.5208" stroke="#2C3243" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.125 23.1042L22.5 20.7292L24.875 23.1042" stroke="#2C3243" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
