import cartSlice from "./reducers/cartSlice";
import orderSlice from "./reducers/orderSlice";
import { configureStore } from "@reduxjs/toolkit";
import appCommonSlice from "./reducers/appCommonSlice";
import layoutSlice from "./reducers/layoutSlice";
import drawerSlice from "./reducers/drawerSlice";
import manageDataSlice from "./reducers/manageDataSlice";
import mapSlice from "./reducers/mapSlice";
import notificationSlice from "./reducers/notificationSlice";
import mapTaskingSlice from "./reducers/mapTaskingSlice";
import statusAction from "./reducers/statusAction";
import searchSlice from "./reducers/searchSlice";

export const store = configureStore({
  reducer: {
    appCommonSlice: appCommonSlice,
    layoutSlice: layoutSlice,
    drawerSlice: drawerSlice,
    orderSlice: orderSlice,
    manageDataSlice: manageDataSlice,
    mapSlice: mapSlice,
    cartSlice: cartSlice,
    notificationSlice: notificationSlice,
    mapTasking: mapTaskingSlice,
    statusAction: statusAction,
    search: searchSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
