import axios from "axios";
import { apis } from "../constants/apis";
import httpService from "./httpService";

export async function search(payload: any) {
  return httpService.post(`${apis.searchImage.search}`, payload);
}

export async function getProvider(payload?: any) {
  // return axios.get(
  //   "http://10.10.20.77:8980/api/v1/providers?page=0&size=10&sort=desc"
  // );

  return httpService.get(`/api/v1/providers?page=0&size=10&sort=desc`);
}
