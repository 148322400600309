import React from "react";

export default function CartOutlinedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.921 9.33346H5.84236L4.75102 5.33346H12.921L10.921 9.33346ZM14.055 4.63212C13.8103 4.23612 13.3863 4.00012 12.921 4.00012H4.38767L3.97634 2.49146C3.89701 2.20146 3.63367 2.00012 3.33301 2.00012H1.99967C1.63101 2.00012 1.33301 2.29879 1.33301 2.66679C1.33301 3.03479 1.63101 3.33346 1.99967 3.33346H2.82367L4.68967 10.1755C4.76901 10.4655 5.03234 10.6668 5.33301 10.6668H11.333C11.5857 10.6668 11.8163 10.5241 11.9297 10.2981L14.1137 5.92946C14.3223 5.51279 14.2997 5.02812 14.055 4.63212ZM4.99979 12.0001C4.44779 12.0001 3.99979 12.4475 3.99979 13.0001C3.99979 13.5528 4.44779 14.0001 4.99979 14.0001C5.55179 14.0001 5.99979 13.5528 5.99979 13.0001C5.99979 12.4475 5.55179 12.0001 4.99979 12.0001ZM10.6665 13.0001C10.6665 12.4475 11.1145 12.0001 11.6665 12.0001C12.2185 12.0001 12.6665 12.4475 12.6665 13.0001C12.6665 13.5528 12.2185 14.0001 11.6665 14.0001C11.1145 14.0001 10.6665 13.5528 10.6665 13.0001Z"
        fill="currentColor"
      />
      <mask id="mask0_25_10374" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="1" y="2" width="14" height="13">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.921 9.33346H5.84236L4.75102 5.33346H12.921L10.921 9.33346ZM14.055 4.63212C13.8103 4.23612 13.3863 4.00012 12.921 4.00012H4.38767L3.97634 2.49146C3.89701 2.20146 3.63367 2.00012 3.33301 2.00012H1.99967C1.63101 2.00012 1.33301 2.29879 1.33301 2.66679C1.33301 3.03479 1.63101 3.33346 1.99967 3.33346H2.82367L4.68967 10.1755C4.76901 10.4655 5.03234 10.6668 5.33301 10.6668H11.333C11.5857 10.6668 11.8163 10.5241 11.9297 10.2981L14.1137 5.92946C14.3223 5.51279 14.2997 5.02812 14.055 4.63212ZM4.99979 12.0001C4.44779 12.0001 3.99979 12.4475 3.99979 13.0001C3.99979 13.5528 4.44779 14.0001 4.99979 14.0001C5.55179 14.0001 5.99979 13.5528 5.99979 13.0001C5.99979 12.4475 5.55179 12.0001 4.99979 12.0001ZM10.6665 13.0001C10.6665 12.4475 11.1145 12.0001 11.6665 12.0001C12.2185 12.0001 12.6665 12.4475 12.6665 13.0001C12.6665 13.5528 12.2185 14.0001 11.6665 14.0001C11.1145 14.0001 10.6665 13.5528 10.6665 13.0001Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_25_10374)">
        <rect y="0.00012207" width="16" height="16" fill="currentColor" />
      </g>
    </svg>
  );
}
