import React from "react";

export default function ChevronUpIcon() {
  return (
    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00195 4.5C8.00195 4.57031 7.98828 4.63477 7.96094 4.69336C7.93359 4.75586 7.89844 4.80859 7.85547 4.85156C7.80859 4.89844 7.75586 4.93555 7.69727 4.96289C7.63477 4.99023 7.56836 5.00391 7.49805 5.00391C7.42383 5.00391 7.35937 4.99023 7.30469 4.96289C7.25 4.93945 7.19727 4.90234 7.14648 4.85156L4 1.70508L0.853515 4.85156C0.751953 4.95313 0.634765 5.00391 0.501953 5.00391C0.43164 5.00391 0.367187 4.99024 0.308593 4.96289C0.246093 4.93555 0.193359 4.89844 0.15039 4.85156C0.103515 4.80859 0.0664059 4.75586 0.0390621 4.69336C0.0117184 4.63477 -0.00195351 4.57031 -0.00195352 4.5C-0.00195353 4.36328 0.0468746 4.24609 0.144531 4.14844L3.64844 0.644532C3.74609 0.546876 3.86328 0.498047 4 0.498047C4.13672 0.498047 4.25391 0.546876 4.35156 0.644532L7.85547 4.14844C7.95312 4.24609 8.00195 4.36328 8.00195 4.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
