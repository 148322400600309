import { useEffect } from "react";
import NotFound404 from "../../assets/svg/404.svg";
import ContentLayoutManagement from "../../layouts/ContentLayoutManagement";
import { useDispatch } from "react-redux";
import {
  changeDataBreadcrumb,
  changeLayoutName,
} from "../../store/reducers/layoutSlice";
import { resetAllDrawer } from "../../store/reducers/drawerSlice";

const Page404 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeLayoutName("/404"));
    dispatch(changeDataBreadcrumb([]));
    dispatch(resetAllDrawer());
    return () => {
      dispatch(changeLayoutName(""));
      dispatch(changeDataBreadcrumb([]));
    };
  }, []);
  return (
    <ContentLayoutManagement>
      <div className="flex justify-center top-1/2 translate-y-1/2">
        <img src={NotFound404} alt="img404" />
      </div>
    </ContentLayoutManagement>
  );
};

export default Page404;
