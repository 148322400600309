import { Radio, Slider } from "antd";
import { useDispatch } from "react-redux";
import CommonComponents from "../../Commons";
import React from "react";

const FormProvider = (props: any) => {
  const dispatch = useDispatch();

  const renderWithType = (item: any, nameForm?: any) => {
    const [key, value] = item;
    console.log(item, key, value, "item, key, value>>>>>>");
    if (key !== "required") {
      if (
        (value?.type === "string" || value?.type === "number") &&
        (value as any)?.enum?.length > 0
      ) {
        return (
          <CommonComponents.FormFieldUI
            name={nameForm?.concat([key])}
            component={
              <CommonComponents.SelectUI
                label={value?.label}
                placeholder={key}
                // required
                data={value?.enum?.map((itemM: any) => ({
                  label: itemM,
                  value: itemM,
                }))}
              />
            }
            // rules={[{ required: true, message: "Please input here!" }]}
          />
        );
      }
      if (value?.type === "string") {
        return (
          <CommonComponents.FormFieldUI
            name={nameForm?.concat([key])}
            component={
              <CommonComponents.InputUI
                label={value?.label}
                placeholder={key}
                // required
              />
            }
            // rules={[{ required: true, message: "Please input here!" }]}
          />
        );
      }
      if (value?.type === "object") {
        return (
          <React.Fragment key={key}>
            {/* <h3>{value?.label}</h3> */}
            {Object.entries(value.properties || {}).map((child) =>
              renderWithType(child, nameForm?.concat([key]))
            )}
          </React.Fragment>
        );
      }
      if (value?.type === "array" && value.items) {
        return (
          <React.Fragment key={key}>
            {renderWithType([key, value.items], nameForm?.concat([key]))}
          </React.Fragment>
        );
      }

      if (value?.type === "number" && value?.minimum && value?.maximum) {
        return (
          <>
            {/* <h3>{value?.label}</h3> */}
            <CommonComponents.FormFieldUI
              label={value?.label}
              name={nameForm?.concat([key])}
              component={
                <Slider range max={value?.maximum} min={value?.minimum} />
              }
              // rules={[{ required: true, message: "Please input here!" }]}
            />
          </>
        );
      }

      if (value?.type === "boolean") {
        return (
          <>
            {/* <h3>{value?.label}</h3> */}
            <CommonComponents.FormFieldUI
              label={value?.label}
              name={nameForm?.concat([key])}
              component={
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              }
              // rules={[{ required: true, message: "Please input here!" }]}
            />
          </>
        );
      }
    }
  };

  const renderField = () => {
    const schema =
      props?.dataSchema?.ui_schema?.properties?.order_details?.items;

    if (
      schema &&
      schema?.properties?.request_order?.properties?.properties?.properties
    ) {
      return Object.entries(
        schema?.properties?.request_order?.properties?.properties?.properties
      ).map((field) => renderWithType(field, ["properties"]));
    }

    return <div>No schema found</div>;
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-2 gap-7">
        {props?.dataSchema && renderField()}
      </div>
      {/* <CommonComponents.FormAntdDebugs /> */}
    </div>
  );
};

export default FormProvider;
