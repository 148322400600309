import { ERoleAccessControl } from "../enums";
import { CheckRoleAccessControlAdmin } from "../helpers/roleAccessControl";
import Map from "../pages/maptasking/Explore";
import ProviderBranch from "../pages/providerBranch";

const SwitchLayout = () => {
  const roleAdmin = CheckRoleAccessControlAdmin();
  if (roleAdmin?.includes(ERoleAccessControl.admin)) return <ProviderBranch />;

  return (
    <div>
      <Map />
    </div>
  );
};

export default SwitchLayout;
