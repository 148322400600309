import React from "react";

export default function SimpleSelectIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 12.4476V0.503045C3 0.322096 3.22116 0.234168 3.3454 0.36572L11.1068 8.58371C11.2368 8.72135 11.1206 8.9458 10.9331 8.91902L8 8.5L9.91717 12.3343C9.96391 12.4278 9.9308 12.5415 9.84119 12.5953L7.6852 13.8889C7.5853 13.9488 7.45551 13.911 7.40341 13.8068L5.5 10L3.35364 12.5756C3.23389 12.7193 3 12.6346 3 12.4476Z"
        fill="currentColor"
      />
    </svg>
  );
}
