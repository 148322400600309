import React from "react";
import ContentLayout from "../../../layouts/ContentLayout";
import CardManagementComp from "../../Commons/CardManagement";
import MyDataSvg from "../../../assets/svg/ManageData/icon-card-preview-my-data.svg";

import InfoSvg from "../../../assets/svg/ManageData/info.svg";
import ShareSvg from "../../../assets/svg/ManageData/share.svg";
import DownloadSvg from "../../../assets/svg/ManageData/download.svg";
import { useDispatch } from "react-redux";
import { changeShowDrawer } from "../../../store/reducers/drawerSlice";
import ContentLayoutManagement from "../../../layouts/ContentLayoutManagement";
import { changeShowDrawerInfoImages } from "../../../store/reducers/manageDataSlice";

const MyDataComp = () => {
  const dispatch = useDispatch();
  const arrData = [
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT7_201904090301070_FR1_FR1_SV1_SV1_E105N10_01790",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT7_201904090301070_FR1_FR1_SV1_SV1_E105N10_01790",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT7_201904090301070_FR1_FR1_SV1_SV1_E105N10_01790",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT7_201904090301070_FR1_FR1_SV1_SV1_E105N10_01790",
      des: "24/12/2023 17:24",
    },
    {
      imgSrc: MyDataSvg,
      title: "DS_SPOT7_201904090301070_FR1_FR1_SV1_SV1_E105N10_01790",
      des: "24/12/2023 17:24",
    },
  ];
  return (
    <ContentLayoutManagement>
      <div className="relative w-full h-full p-6 bg-content-default">
        {arrData?.map((item: any, ind: number) => (
          <CardManagementComp
            key={ind}
            data={item}
            actions={[
              <img
                alt="example"
                src={InfoSvg}
                onClick={() => {
                  dispatch(changeShowDrawer(true));
                  dispatch(changeShowDrawerInfoImages(true));
                }}
              />,
              <img alt="example" src={ShareSvg} />,
              <img alt="example" src={DownloadSvg} />,
            ]}
          />
        ))}
      </div>
    </ContentLayoutManagement>
  );
};

export default MyDataComp;
