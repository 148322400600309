import React from "react";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import NextSvg from "../../../assets/svg/next.svg";
import { Link } from "react-router-dom";

const BreadcrumbComp = () => {
  const { dataBreadcrumb } = useSelector(
    (state: RootState) => state.layoutSlice
  );

  function itemRender(currentRoute: any, params: any, items: any, paths: any) {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;

    return isLast ? (
      <span className="text-white">{currentRoute.title}</span>
    ) : (
      <Link className="!text-white" to={`/${paths.join("/")}`}>
        {currentRoute.title}
      </Link>
    );
  }
  return (
    <Breadcrumb
      className="breadcrumb-cus"
      separator={<img src={NextSvg} />}
      items={dataBreadcrumb}
      itemRender={itemRender}
    />
  );
};

export default BreadcrumbComp;
