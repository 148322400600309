import React, { useEffect, useState } from "react";
import CommonIcons from "../../../assets/icons";

interface IProps {
  numberStep: number;
  current: number;
  spacingline?: number;
  changeStep?: any;
}
const StepUI = (props: IProps) => {
  //! state
  const { numberStep, current, changeStep, spacingline } = props;

  const [totalStep, setTotalStep] = useState(4);

  useEffect(() => {
    setTotalStep(numberStep);
  }, [numberStep]);
  //! function
  const renderStep = () => {
    return Array.from({ length: totalStep }, (_, index) => index + 1).map(
      (item, index) => {
        const activeStep = current === item;
        const roundedColor = activeStep
          ? "border-common-primary500"
          : "border-common-textDefault1";
        const stepPass =
          item < current ? "bg-common-primary500 border-none" : "";
        const textActive = activeStep || stepPass ? "" : "opacity-50";

        return (
          <React.Fragment key={item}>
            <div
              className="size-5 text-center cursor-pointer"
              onClick={() => {
                changeStep && changeStep(item);
              }}
              key={item}
            >
              <div
                className={`size-5 rounded-full flex justify-center items-center border-[3px]  ${roundedColor} ${stepPass}`}
              >
                {stepPass && <CommonIcons.CheckIcon />}
              </div>
              <div className={`${textActive}`}>{item}</div>
            </div>

            {index + 1 !== totalStep && (
              <div
                className={`size-1.5] w-16 border-[1.5px] border-common-textDefault1 ${
                  item < current ? "!border-common-primary500" : ""
                }`}
              ></div>
            )}
          </React.Fragment>
        );
      }
    );
  };
  //! render
  return <div className="flex items-center">{renderStep()}</div>;
};

export default StepUI;
