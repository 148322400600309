import React from "react";

export default function GeojsonIcon() {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.7002 30.5588V3.44116C5.7002 2.61273 6.37177 1.94116 7.2002 1.94116H20.0158C20.449 1.94116 20.861 2.12842 21.1459 2.45476L26.6585 8.77016C26.897 9.04342 27.0284 9.39384 27.0284 9.75656V30.5588C27.0284 31.3872 26.3568 32.0588 25.5284 32.0588H7.2002C6.37177 32.0588 5.7002 31.3872 5.7002 30.5588Z"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M16.6826 7.06067V8.41547H17.8604H19.0408L18.9925 8.27328C18.641 7.27797 17.9972 6.37657 17.3506 5.97683C17.1762 5.86952 16.7792 5.70588 16.6933 5.70588C16.688 5.70588 16.6826 6.31486 16.6826 7.06067Z"
        fill="white"
      />
      <path
        d="M15.8323 5.78368C15.1616 6.02512 14.5392 6.67972 14.0832 7.61869C13.9383 7.91916 13.8176 8.20353 13.772 8.35376L13.7559 8.41547H14.8692H15.9852V7.07408C15.9852 6.33632 15.9826 5.7327 15.9799 5.7327C15.9745 5.73539 15.9101 5.75685 15.8323 5.78368Z"
        fill="white"
      />
      <path
        d="M14.1471 6.0251C13.0687 6.43557 12.076 7.21357 11.3866 8.18741L11.2363 8.40203L12.1136 8.41008C12.5965 8.41276 13.007 8.41008 13.023 8.40471C13.0391 8.39935 13.0633 8.35911 13.0713 8.31618C13.0821 8.27594 13.1465 8.10156 13.2108 7.93255C13.4711 7.26454 13.8681 6.57775 14.2518 6.12705C14.351 6.01437 14.4261 5.92048 14.4235 5.92048C14.4181 5.92316 14.2947 5.96877 14.1471 6.0251Z"
        fill="white"
      />
      <path
        d="M18.5895 6.19414C18.9732 6.64484 19.3702 7.36114 19.6251 8.05866L19.7538 8.41547H20.5935C21.2589 8.41547 21.4306 8.40742 21.4145 8.38059C21.4011 8.36449 21.334 8.26255 21.2589 8.1606C20.7143 7.39333 19.9041 6.69045 19.0939 6.28267C18.8658 6.16463 18.4876 6.00098 18.45 6.00098C18.4366 6.00098 18.501 6.08951 18.5895 6.19414Z"
        fill="white"
      />
      <path
        d="M10.791 9.23906C10.5469 9.79171 10.3698 10.4892 10.3108 11.1438L10.292 11.3397H11.4214H12.5509V11.2645C12.5509 10.8192 12.7065 9.6683 12.8353 9.15589L12.8513 9.08614H11.856H10.8607L10.791 9.23906Z"
        fill="white"
      />
      <path
        d="M13.5194 9.1854C13.4658 9.38661 13.3799 9.83195 13.3289 10.1727C13.2699 10.5858 13.2109 11.2753 13.235 11.3128C13.2458 11.3289 13.7448 11.3397 14.6193 11.3397H15.9849V10.2129V9.08614H14.7642H13.5462L13.5194 9.1854Z"
        fill="white"
      />
      <path
        d="M16.6826 10.2129V11.3397H18.1179H19.5532V11.1653C19.5532 10.7629 19.4378 9.90975 19.3064 9.33295L19.25 9.08614H17.965H16.6826V10.2129Z"
        fill="white"
      />
      <path
        d="M19.9551 9.12638C19.9551 9.15053 19.9792 9.2793 20.0087 9.41612C20.1134 9.9017 20.2019 10.6207 20.2314 11.2055L20.2368 11.3262L21.3072 11.3343L22.3749 11.3397L22.3562 11.1465C22.2971 10.4865 22.1093 9.76488 21.8625 9.21223L21.8035 9.08614H20.8806C20.0704 9.08614 19.9551 9.0915 19.9551 9.12638Z"
        fill="white"
      />
      <path
        d="M10.3081 12.1633C10.3725 12.8259 10.5334 13.4805 10.7642 14.009L10.8581 14.2236L11.8399 14.2317C12.7682 14.237 12.8218 14.2344 12.8084 14.1914C12.7306 13.9339 12.6019 12.9627 12.5562 12.292L12.5375 11.9969L11.4134 11.9889L10.292 11.9835L10.3081 12.1633Z"
        fill="white"
      />
      <path
        d="M13.2326 12.3108C13.2568 12.7696 13.348 13.4671 13.4339 13.8776L13.5063 14.2236L14.7457 14.2317L15.9852 14.2371V13.1103V11.9835H14.6009H13.2139L13.2326 12.3108Z"
        fill="white"
      />
      <path
        d="M16.6826 13.1103V14.2371H17.9811H19.2795L19.3251 14.0466C19.4351 13.553 19.5424 12.7374 19.5585 12.2384L19.5666 11.997L18.1259 11.9889L16.6826 11.9835V13.1103Z"
        fill="white"
      />
      <path
        d="M20.2392 12.0426C20.2312 12.0774 20.2231 12.1901 20.2231 12.2947C20.2231 12.6676 20.1078 13.6012 19.9924 14.1512L19.9736 14.2371L20.8911 14.2317L21.8086 14.2236L21.9025 14.009C22.1333 13.4805 22.2942 12.8259 22.3586 12.1633L22.3747 11.9835H21.315H20.2553L20.2392 12.0426Z"
        fill="white"
      />
      <path
        d="M11.3461 15.0714C11.9524 15.9648 12.9262 16.775 13.8893 17.1881C14.2703 17.3544 14.2676 17.3544 14.0852 17.1157C13.6586 16.5577 13.2669 15.7823 13.0443 15.0607L12.996 14.9078H12.116H11.2334L11.3461 15.0714Z"
        fill="white"
      />
      <path
        d="M13.7041 14.9158C13.7041 14.9212 13.7578 15.0633 13.8221 15.235C14.1602 16.1284 14.6243 16.842 15.1474 17.2713C15.3835 17.4671 15.7162 17.6549 15.8852 17.6844L15.9845 17.7032V16.3055V14.9077H14.8443C14.2165 14.9077 13.7041 14.9104 13.7041 14.9158Z"
        fill="white"
      />
      <path
        d="M16.6553 16.3055V17.7059L16.8136 17.6898C17.0979 17.6656 17.4521 17.4617 17.8062 17.1237C18.281 16.6676 18.6861 15.997 19 15.1546L19.0912 14.9077H17.8732H16.6553V16.3055Z"
        fill="white"
      />
      <path
        d="M19.7837 14.9399C19.7783 14.9587 19.722 15.1197 19.6603 15.2968C19.4296 15.9513 19.1425 16.5147 18.8018 16.9842C18.6972 17.1291 18.614 17.2525 18.6194 17.2579C18.6408 17.2766 19.3732 16.8903 19.6201 16.7293C20.2881 16.2921 20.8917 15.7072 21.3209 15.0714L21.4336 14.9078H20.6127C19.9795 14.9078 19.7918 14.9158 19.7837 14.9399Z"
        fill="white"
      />
      <path d="M20.2021 7.97058V1.94116L26.7904 9.47057H21.7021C20.8737 9.47057 20.2021 8.799 20.2021 7.97058Z" fill="white" />
      <rect x="2.33301" y="19" width="28" height="10" rx="1.5" fill="white" />
      <path
        d="M25.9002 23.7755V25.5402H25.1904V22.5285H25.8688V23.0402H25.9041C25.9734 22.8716 26.0839 22.7376 26.2355 22.6383C26.3884 22.5389 26.5773 22.4893 26.8022 22.4893C27.01 22.4893 27.1911 22.5337 27.3453 22.6226C27.5009 22.7115 27.6211 22.8402 27.7061 23.0089C27.7924 23.1775 27.8348 23.3821 27.8335 23.6226V25.5402H27.1237V23.7324C27.1237 23.5311 27.0714 23.3736 26.9669 23.2598C26.8636 23.1461 26.7205 23.0892 26.5375 23.0892C26.4133 23.0892 26.3028 23.1167 26.2061 23.1716C26.1107 23.2252 26.0355 23.303 25.9806 23.4049C25.927 23.5069 25.9002 23.6304 25.9002 23.7755Z"
        fill="black"
      />
      <path
        d="M22.8679 25.599C22.5738 25.599 22.3189 25.5343 22.1032 25.4049C21.8875 25.2755 21.7202 25.0944 21.6013 24.8618C21.4836 24.6291 21.4248 24.3572 21.4248 24.0461C21.4248 23.735 21.4836 23.4624 21.6013 23.2285C21.7202 22.9945 21.8875 22.8128 22.1032 22.6834C22.3189 22.554 22.5738 22.4893 22.8679 22.4893C23.162 22.4893 23.4169 22.554 23.6326 22.6834C23.8483 22.8128 24.015 22.9945 24.1326 23.2285C24.2516 23.4624 24.311 23.735 24.311 24.0461C24.311 24.3572 24.2516 24.6291 24.1326 24.8618C24.015 25.0944 23.8483 25.2755 23.6326 25.4049C23.4169 25.5343 23.162 25.599 22.8679 25.599ZM22.8718 25.0304C23.0313 25.0304 23.1646 24.9866 23.2718 24.899C23.379 24.8101 23.4588 24.6912 23.511 24.5422C23.5646 24.3932 23.5914 24.2271 23.5914 24.0441C23.5914 23.8598 23.5646 23.6932 23.511 23.5441C23.4588 23.3938 23.379 23.2742 23.2718 23.1853C23.1646 23.0964 23.0313 23.052 22.8718 23.052C22.7084 23.052 22.5725 23.0964 22.464 23.1853C22.3568 23.2742 22.2764 23.3938 22.2228 23.5441C22.1705 23.6932 22.1444 23.8598 22.1444 24.0441C22.1444 24.2271 22.1705 24.3932 22.2228 24.5422C22.2764 24.6912 22.3568 24.8101 22.464 24.899C22.5725 24.9866 22.7084 25.0304 22.8718 25.0304Z"
        fill="black"
      />
      <path
        d="M20.6279 23.3245L19.9809 23.3951C19.9626 23.3298 19.9306 23.2683 19.8848 23.2108C19.8404 23.1533 19.7802 23.1069 19.7044 23.0716C19.6286 23.0363 19.5358 23.0187 19.426 23.0187C19.2783 23.0187 19.1541 23.0507 19.0535 23.1147C18.9541 23.1788 18.9051 23.2618 18.9064 23.3638C18.9051 23.4513 18.9371 23.5226 19.0025 23.5775C19.0691 23.6324 19.1789 23.6775 19.3319 23.7128L19.8456 23.8226C20.1306 23.884 20.3423 23.9814 20.4809 24.1147C20.6208 24.2481 20.6913 24.4226 20.6927 24.6382C20.6913 24.8278 20.6358 24.9951 20.526 25.1402C20.4175 25.284 20.2665 25.3964 20.0731 25.4774C19.8796 25.5585 19.6574 25.599 19.4064 25.599C19.0378 25.599 18.741 25.5219 18.5162 25.3676C18.2914 25.2121 18.1574 24.9958 18.1143 24.7186L18.8064 24.652C18.8378 24.7879 18.9044 24.8905 19.0064 24.9598C19.1084 25.0291 19.241 25.0637 19.4044 25.0637C19.5731 25.0637 19.7084 25.0291 19.8103 24.9598C19.9136 24.8905 19.9652 24.8049 19.9652 24.7029C19.9652 24.6167 19.9319 24.5454 19.8652 24.4892C19.7999 24.433 19.6979 24.3899 19.5593 24.3598L19.0456 24.252C18.7567 24.1918 18.543 24.0905 18.4044 23.9481C18.2659 23.8043 18.1973 23.6226 18.1986 23.403C18.1973 23.2174 18.2476 23.0566 18.3495 22.9206C18.4528 22.7834 18.596 22.6775 18.779 22.603C18.9633 22.5272 19.1757 22.4893 19.4162 22.4893C19.7691 22.4893 20.0469 22.5644 20.2495 22.7147C20.4534 22.8651 20.5796 23.0683 20.6279 23.3245Z"
        fill="black"
      />
      <path
        d="M16.5322 22.5285H17.2419V25.7127C17.2419 25.9297 17.2008 26.1088 17.1184 26.25C17.0361 26.3912 16.9171 26.4964 16.7616 26.5657C16.606 26.6349 16.4171 26.6696 16.1949 26.6696C16.1688 26.6696 16.1446 26.6689 16.1224 26.6676C16.1001 26.6676 16.0759 26.667 16.0498 26.6657V26.0872C16.0694 26.0885 16.0871 26.0892 16.1027 26.0892C16.1184 26.0905 16.1348 26.0912 16.1518 26.0912C16.2877 26.0912 16.3851 26.0578 16.4439 25.9912C16.5027 25.9258 16.5322 25.8297 16.5322 25.7029V22.5285ZM16.8851 22.101C16.7714 22.101 16.674 22.0638 16.5929 21.9893C16.5132 21.9134 16.4733 21.8226 16.4733 21.7167C16.4733 21.6095 16.5132 21.5187 16.5929 21.4442C16.674 21.3684 16.7714 21.3304 16.8851 21.3304C16.9975 21.3304 17.0936 21.3684 17.1733 21.4442C17.2544 21.5187 17.2949 21.6095 17.2949 21.7167C17.2949 21.8226 17.2544 21.9134 17.1733 21.9893C17.0936 22.0638 16.9975 22.101 16.8851 22.101Z"
        fill="black"
      />
      <path
        d="M14.2107 25.599C13.9166 25.599 13.6617 25.5343 13.446 25.4049C13.2303 25.2755 13.063 25.0944 12.944 24.8618C12.8264 24.6291 12.7676 24.3572 12.7676 24.0461C12.7676 23.735 12.8264 23.4624 12.944 23.2285C13.063 22.9945 13.2303 22.8128 13.446 22.6834C13.6617 22.554 13.9166 22.4893 14.2107 22.4893C14.5048 22.4893 14.7597 22.554 14.9754 22.6834C15.1911 22.8128 15.3577 22.9945 15.4754 23.2285C15.5943 23.4624 15.6538 23.735 15.6538 24.0461C15.6538 24.3572 15.5943 24.6291 15.4754 24.8618C15.3577 25.0944 15.1911 25.2755 14.9754 25.4049C14.7597 25.5343 14.5048 25.599 14.2107 25.599ZM14.2146 25.0304C14.3741 25.0304 14.5074 24.9866 14.6146 24.899C14.7218 24.8101 14.8015 24.6912 14.8538 24.5422C14.9074 24.3932 14.9342 24.2271 14.9342 24.0441C14.9342 23.8598 14.9074 23.6932 14.8538 23.5441C14.8015 23.3938 14.7218 23.2742 14.6146 23.1853C14.5074 23.0964 14.3741 23.052 14.2146 23.052C14.0512 23.052 13.9153 23.0964 13.8068 23.1853C13.6996 23.2742 13.6192 23.3938 13.5656 23.5441C13.5133 23.6932 13.4872 23.8598 13.4872 24.0441C13.4872 24.2271 13.5133 24.3932 13.5656 24.5422C13.6192 24.6912 13.6996 24.8101 13.8068 24.899C13.9153 24.9866 14.0512 25.0304 14.2146 25.0304Z"
        fill="black"
      />
      <path
        d="M10.6815 25.599C10.3795 25.599 10.1187 25.5363 9.89913 25.4108C9.68083 25.284 9.51286 25.1049 9.39522 24.8735C9.27757 24.6409 9.21875 24.367 9.21875 24.052C9.21875 23.7422 9.27757 23.4703 9.39522 23.2363C9.51417 23.001 9.68018 22.818 9.89325 22.6873C10.1063 22.5553 10.3566 22.4893 10.6442 22.4893C10.8298 22.4893 11.005 22.5193 11.1697 22.5795C11.3357 22.6383 11.4821 22.7298 11.6089 22.854C11.737 22.9781 11.8377 23.1363 11.9109 23.3285C11.9841 23.5193 12.0207 23.7468 12.0207 24.0108V24.2284H9.55208V23.75H11.3403C11.339 23.6141 11.3096 23.4932 11.2521 23.3873C11.1945 23.2801 11.1141 23.1958 11.0109 23.1343C10.9089 23.0729 10.79 23.0422 10.654 23.0422C10.5089 23.0422 10.3815 23.0775 10.2717 23.1481C10.1619 23.2174 10.0763 23.3089 10.0148 23.4226C9.95469 23.535 9.92397 23.6585 9.92266 23.7932V24.2108C9.92266 24.386 9.95469 24.5363 10.0187 24.6618C10.0828 24.786 10.1723 24.8814 10.2874 24.948C10.4024 25.0134 10.537 25.0461 10.6913 25.0461C10.7945 25.0461 10.888 25.0317 10.9717 25.0029C11.0553 24.9729 11.1279 24.9291 11.1893 24.8716C11.2507 24.8141 11.2971 24.7428 11.3285 24.6579L11.9913 24.7324C11.9494 24.9075 11.8697 25.0605 11.752 25.1912C11.6357 25.3206 11.4867 25.4212 11.305 25.4931C11.1233 25.5637 10.9155 25.599 10.6815 25.599Z"
        fill="black"
      />
      <path
        d="M7.62512 22.8069C7.59244 22.701 7.54735 22.6062 7.48983 22.5226C7.43362 22.4376 7.36565 22.3651 7.28591 22.3049C7.20748 22.2448 7.11729 22.1997 7.01533 22.1697C6.91337 22.1383 6.80226 22.1226 6.682 22.1226C6.46632 22.1226 6.27416 22.1768 6.10554 22.2853C5.93691 22.3938 5.80423 22.5533 5.7075 22.7638C5.61208 22.9729 5.56437 23.2278 5.56437 23.5285C5.56437 23.8317 5.61208 24.0886 5.7075 24.299C5.80293 24.5095 5.93561 24.6696 6.10554 24.7794C6.27547 24.8879 6.47285 24.9422 6.69769 24.9422C6.9016 24.9422 7.07807 24.9029 7.22709 24.8245C7.37741 24.7461 7.4931 24.635 7.57414 24.4912C7.65519 24.3461 7.69571 24.1762 7.69571 23.9814L7.86041 24.0069H6.77023V23.4383H8.39962V23.9206C8.39962 24.2644 8.32642 24.5618 8.18002 24.8128C8.03361 25.0637 7.83231 25.2572 7.5761 25.3931C7.3199 25.5278 7.02578 25.5951 6.69376 25.5951C6.32383 25.5951 5.999 25.5121 5.71927 25.3461C5.44084 25.1788 5.2232 24.9415 5.06634 24.6343C4.91078 24.3258 4.83301 23.9598 4.83301 23.5363C4.83301 23.2121 4.87876 22.9226 4.97026 22.6677C5.06307 22.4128 5.19248 22.1965 5.35849 22.0187C5.5245 21.8396 5.71927 21.7036 5.94279 21.6108C6.16632 21.5167 6.40945 21.4697 6.6722 21.4697C6.89441 21.4697 7.1016 21.5023 7.29375 21.5677C7.48591 21.6318 7.65649 21.7233 7.80551 21.8422C7.95584 21.9612 8.07936 22.1023 8.17609 22.2657C8.27282 22.4291 8.33622 22.6095 8.36629 22.8069H7.62512Z"
        fill="black"
      />
    </svg>
  );
}
